import { acceptPlatformAgreement } from "./actions/CacheAcceptPlatformAgreement/CacheAcceptPlatformAgreementActions";
import {
  getAdminUserProfile,
  updateAdminUserProfile
} from "./actions/CacheAdminUserProfile/CacheAdminUserProfileActions";
import {
  getAppData,
  getAppDataFailure,
  getAppDataSuccess
} from "./actions/CacheGetAppData/CacheGetAppDataActions";
import { getAPPType } from "./actions/CacheGetAppType/CacheGetAppTypeActions";
import { getLoggedInUser } from "./actions/CacheGetLoggedInUser/CacheGetLoggedInUserActions";

export const CacheStoreActions = {
  getAppData,
  getAppDataSuccess,
  getAppDataFailure,
  getLoggedInUser,
  getAPPType,
  getAdminUserProfile,
  updateAdminUserProfile,
  acceptPlatformAgreement
};
