import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { zip } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  ProjectNote,
  ScreeningQuestion,
  ToasterService
} from "@arbolus-technologies/api";
import {
  CustomerCompanyInfo,
  DiscoverFilterOption
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { Loader } from "@arbolus-technologies/ui/components";

import { CustomersList } from "./CustomersList/CustomersList";
import { FilterMatch } from "./FilterMatch/FilterMatch";
import { ProjectNotes } from "./ProjectNotes/ProjectNotes";
import { ScreeningAnswers } from "./ScreeningAnswers/ScreeningAnswers";

import { ExpertWorkHistory } from "@arbolus-technologies/models/common";
import styles from "./Content.module.scss";

interface ContentProps {
  expertId: string;
  searchMatchColumns: string[];
  customers: CustomerCompanyInfo[];
  industriesFilter: DiscoverFilterOption[];
  workHistories: ExpertWorkHistory[];
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const Content: React.FC<ContentProps> = ({
  expertId,
  searchMatchColumns,
  customers,
  industriesFilter,
  workHistories,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectNotes, setProjectNotes] = useState<ProjectNote[]>([]);
  const [screeningQuestions, setScreeningQuestions] = useState<
    ScreeningQuestion[]
  >([]);
  const keywordsList = useSelector(ProjectNxSelector.keywordsList());

  const getProjectNotesAndScreeningAnswers = () => {
    if (keywordsList) {
      setIsLoading(true);
      zip(
        expertService.getProjectNotes(expertId, keywordsList),
        expertService.getScreeningQuestions(expertId, keywordsList)
      ).subscribe(
        ([projectNotes, screeningQuestions]) => {
          setProjectNotes(projectNotes.items.filter((item) => !!item.note));
          setScreeningQuestions(screeningQuestions.items);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    getProjectNotesAndScreeningAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expertId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.content}>
      {keywordsList && (
        <>
          <FilterMatch
            projectNotesLength={projectNotes.length}
            screeningQuestionsLength={screeningQuestions.length}
            customersLength={customers.length}
            searchMatchColumns={searchMatchColumns}
            keywordsList={keywordsList}
            industriesFilter={industriesFilter}
            workHistories={workHistories}
          />
          <ProjectNotes
            expertId={expertId}
            keywordsList={keywordsList}
            projectNotes={projectNotes}
          />
          <ScreeningAnswers
            expertId={expertId}
            keywordsList={keywordsList}
            screeningQuestions={screeningQuestions}
          />
          <CustomersList customers={customers} expertId={expertId} />
        </>
      )}
    </div>
  );
};
