import {
  GetAdminUserProfileAction,
  UpdateAdminUserProfileAction
} from "../actions/CacheAdminUserProfile/CacheAdminUserProfileActionTypes";
import { CacheReducerState } from "../models/definitions";
import { initialState } from "./CacheInitialState";

export const handleGetAdminUserProfile = (
  state = initialState,
  { payload }: GetAdminUserProfileAction
): CacheReducerState => ({
  ...state,
  userProfile: payload.userProfile
});

export const handleUpdateAdminUserProfile = (
  state = initialState,
  { payload }: UpdateAdminUserProfileAction
): CacheReducerState => ({
  ...state,
  userProfile: {
    ...state.userProfile,
    ...payload.user
  }
});
