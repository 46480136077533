import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import {
  Angle,
  AnglesService,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  MAX_PAGE_SIZE_20,
  PaginatedResponseWithStatus,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import {
  DiscoverExpert,
  ExpertDiscoverFilters,
  FilterTimeSelectorOptions,
  KEYWORDS_SEARCH_OPTIONS
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { utilService } from "@arbolus-technologies/utils";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { useDiscoverCompanyFilter } from "./useDiscoverCompanyFilter";
import { useDiscoverCustomerFilter } from "./useDiscoverCustomersFilter";
import { useDiscoverExpertLocationFilter } from "./useDiscoverExpertLocationFilter";
import { useDiscoverIndustryFilter } from "./useDiscoverIndustryFilter";
import { useDiscoverProjectFilter } from "./useDiscoverProjectFilter";
import { useDiscoverWorkHistoryLocationFilter } from "./useDiscoverWorkHistoryLocationFilter";

interface UseDiscoverExpertsProps {
  projectId: string;
  projectService?: typeof ProjectService;
  anglesService?: typeof AnglesService;
  notificationService?: ToasterService;
}

export interface UseDiscoverExperts {
  expertListPaginated: PaginatedResponseWithStatus<DiscoverExpert> | null;
  handleSelectExpert: (expertId: string) => void;
  handleToggleSelectAllExperts: () => void;
  areAllExpertsSelected: boolean;
  selectedExpertsIds: string[];
  expertsCount: number;
  angles: Angle[];
  fetchExpertList: () => Promise<void>;
  handleSearchTerm: (search: string) => void;
  clearSelectedExperts: () => void;
  selectedCautionExperts: { id: string; name: string }[];
  handleSetIsConfirmReferralsLoading: (isLoading: boolean) => void;
  isConfirmReferralsLoading: boolean;
}

export const useDiscoverExperts = ({
  projectId,
  projectService = ProjectService,
  anglesService = AnglesService,
  notificationService = DefaultToasterService
}: UseDiscoverExpertsProps): UseDiscoverExperts => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const {
    query = "",
    workHistoryLocationState,
    industryState,
    companyState,
    companyInPastMonths,
    keywords,
    keywordsOptions,
    hideDncExperts
  }: {
    query?: string;
    workHistoryLocationState?: string;
    industryState?: string;
    companyState?: string;
    companyInPastMonths?: string;
    keywords?: string;
    customers?: string;
    keywordsOptions?: string;
    hideDncExperts?: string;
  } = parameters;

  const { updateExpertLocationInfoFromUrl } = useDiscoverExpertLocationFilter();
  const { updateWorkHistoryLocationInfoFromUrl } =
    useDiscoverWorkHistoryLocationFilter();
  const { updateCompanyInfoFromUrl } = useDiscoverCompanyFilter();
  const { updateProjectInfoFromUrl } = useDiscoverProjectFilter();
  const { updateCustomerInfoFromUrl } = useDiscoverCustomerFilter();
  const { updateIndustriesFilterFromUrl } = useDiscoverIndustryFilter();

  const [selectedExpertsIds, setSelectedExpertsIds] = useState<string[]>([]);
  const [expertsCount, setExpertsCount] = useState<number>(0);
  const [angles, setAngles] = useState<Angle[]>([]);
  const [selectedCautionExperts, setSelectedCautionExperts] = useState<
    { id: string; name: string }[]
  >([]);
  const [areAllExpertsSelected, setAreAllExpertsSelected] =
    useState<boolean>(false);
  const [isConfirmReferralsLoading, setIsConfirmReferralsLoading] =
    useState(false);

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const expertListPaginated = useSelector(
    ProjectNxSelector.getDiscoverExpertList()
  );
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());
  const searchTerm = useSelector(
    ProjectNxSelector.getDiscoverExpertSearchTerm()
  );
  const discoverFilters = useSelector(ProjectNxSelector.discoverFilters());

  const handleSetIsConfirmReferralsLoading = () => {
    setIsConfirmReferralsLoading((prev) => !prev);
  };

  const handleSelectExpert = (expertId: string) => {
    if (expertListPaginated) {
      const selectedExpert = expertListPaginated.items.find(
        (expert) => expert.id === expertId
      );

      if (selectedExpertsIds.includes(expertId)) {
        setSelectedExpertsIds(
          selectedExpertsIds.filter(
            (expertIdStored) => expertIdStored !== expertId
          )
        );

        if (
          selectedExpert?.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION
        ) {
          setSelectedCautionExperts(
            selectedCautionExperts.filter(
              (cautionExpert) => cautionExpert.id !== expertId
            )
          );
        }
      } else {
        setSelectedExpertsIds([...selectedExpertsIds, expertId]);
        if (
          selectedExpert?.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION
        ) {
          setSelectedCautionExperts([
            ...selectedCautionExperts,
            { id: expertId, name: selectedExpert.fullName }
          ]);
        }
      }
    }

    setAreAllExpertsSelected(areAllExpertsOnPageSelected());
  };

  const handleToggleSelectAllExperts = () => {
    const allSelected = areAllExpertsOnPageSelected();

    if (allSelected) {
      setSelectedExpertsIds((prevSelected) => {
        const currentPageExpertIds =
          expertListPaginated?.items.map((expert) => expert.id) ?? [];
        return prevSelected.filter((id) => !currentPageExpertIds.includes(id));
      });

      setSelectedCautionExperts((prevSelectedCaution) => {
        const currentPageCautionExperts =
          expertListPaginated?.items
            .filter(
              (expert) =>
                expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION
            )
            .map((expert) => expert.id) ?? [];
        return prevSelectedCaution.filter(
          (cautionExpert) =>
            !currentPageCautionExperts.includes(cautionExpert.id)
        );
      });

      setAreAllExpertsSelected(false);
    } else {
      setSelectedExpertsIds((prevSelected) => {
        const currentPageExpertIds =
          expertListPaginated?.items
            .filter(
              (expert) =>
                expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC
            )
            .map((expert) => expert.id) ?? [];
        return Array.from(new Set([...prevSelected, ...currentPageExpertIds]));
      });

      setSelectedCautionExperts((prevSelectedCaution) => {
        const currentPageCautionExperts =
          expertListPaginated?.items
            .filter(
              (expert) =>
                expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION
            )
            .map((expert) => ({
              id: expert.id,
              name: expert.fullName
            })) ?? [];
        return Array.from(
          new Set([...prevSelectedCaution, ...currentPageCautionExperts])
        );
      });

      setAreAllExpertsSelected(true);
    }
  };

  const areAllExpertsOnPageSelected = (): boolean => {
    if (expertListPaginated && expertListPaginated.items.length > 0) {
      const currentPageSelectableExpertIds = expertListPaginated.items
        .filter(
          (expert) => expert.doNotContactStatus !== DO_NOT_CONTACT_STATUS.DNC
        )
        .map((expert) => expert.id);

      return currentPageSelectableExpertIds.every((id) =>
        selectedExpertsIds.includes(id)
      );
    }

    return false;
  };

  useEffect(() => {
    setAreAllExpertsSelected(areAllExpertsOnPageSelected());
  }, [selectedExpertsIds, expertListPaginated?.items]);

  const clearSelectedExperts = () => {
    setSelectedExpertsIds([]);
  };

  const getProjectStats = (): void => {
    projectService.getSearchStats(projectId).subscribe(
      (searchStats) => {
        setExpertsCount(searchStats.expertsCount);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  const getAngles = (): void => {
    anglesService.getAnglesCards(projectId, 0, 200).subscribe(
      (response) => {
        setAngles(response.items);
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
      }
    );
  };

  useEffect(() => {
    getProjectStats();
    getAngles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedExpertsIds([]);
  }, [searchTerm]);

  const fetchExpertList = async () => {
    if (!isAdmin) {
      history.replace({ pathname: location.pathname });
      return;
    }

    const shouldHideDncExperts = hideDncExperts === "true";
    const companyFilter = await updateCompanyInfoFromUrl();
    const projectFilter = await updateProjectInfoFromUrl();
    const customersFilter = await updateCustomerInfoFromUrl();
    const expertLocationFilter = updateExpertLocationInfoFromUrl();
    const workHistoryLocationFilter = updateWorkHistoryLocationInfoFromUrl();
    const industryFilter = updateIndustriesFilterFromUrl();
    const keywordFilter = keywords
      ? [{ value: keywords.trim() ?? "", label: keywords.trim() ?? "" }]
      : [];

    const filters = {
      expertLocations: expertLocationFilter,
      workHistoryLocations: workHistoryLocationFilter,
      company: companyFilter,
      projects: projectFilter,
      keywords: keywordFilter,
      customers: customersFilter,
      industries: industryFilter
    };

    const keywordsSearchOptionsSelected: string[] = keywordsOptions
      ? keywordsOptions.split(",")
      : Object.values(KEYWORDS_SEARCH_OPTIONS);

    trackFromUrl(filters, keywordsSearchOptionsSelected, query);

    const discoverFilters = {
      filters,
      filtersPreSelection: filters,
      companyFilterPeriod: (companyState ??
        FilterTimeSelectorOptions.All) as FilterTimeSelectorOptions,
      companyInPastMonths: Number(companyInPastMonths ?? 0),
      workHistoryLocationFilterPeriod: (workHistoryLocationState ??
        FilterTimeSelectorOptions.All) as FilterTimeSelectorOptions,
      industryFilterPeriod: (industryState ??
        FilterTimeSelectorOptions.All) as FilterTimeSelectorOptions,
      filtersQuery: {
        expertLocations: "",
        workHistoryLocations: "",
        company: "",
        keyword: "",
        projects: "",
        customers: "",
        industry: ""
      },
      keywordsFields: keywordsSearchOptionsSelected,
      displayRecommendations: isAdmin ? !location.search : false,
      hideDncExperts: isAdmin ? shouldHideDncExperts : true,
      searchTerm: isAdmin ? query : "",
      currentPage: 1,
      pageSize: MAX_PAGE_SIZE_20,
      isLoading: true
    };

    dispatch(
      ProjectNxStoreActions.setDiscoverExpertAllFilters(discoverFilters)
    );
  };

  const trackFromUrl = (
    filters: ExpertDiscoverFilters,
    keywordsSearchOptionsSelected: string[],
    query: string
  ) => {
    if (filters.company.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchCompaniesSearch, {
        Project: projectName,
        Client: projectClientName
      });
    }
    if (filters.customers.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchCustomerSearch, {
        Customers: filters.customers.map((customer) => customer.label),
        Project: projectName,
        Client: projectClientName
      });
    }
    if (filters.expertLocations.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchCurrentLocationSearch, {
        Project: projectName,
        Client: projectClientName
      });
    }
    if (filters.workHistoryLocations.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchWorkHistorySearch, {
        Project: projectName,
        Client: projectClientName
      });
    }
    if (filters.projects.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchProjectsSearch, {
        Project: projectName,
        Client: projectClientName
      });
    }
    if (filters.keywords.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchKeywordSearch, {
        keywordsSearchOptionsSelected,
        Project: projectName,
        Client: projectClientName
      });
    }
    if (filters.industries.length > 0) {
      trackFiltered(MixPanelEventNames.InternalSearchIndustrySearch, {
        Project: projectName,
        Client: projectClientName
      });
    }
    if (query) {
      trackFiltered(MixPanelEventNames.InternalSearchSearchBar, {
        Project: projectName,
        Client: projectClientName
      });
    }
  };

  const handleRecommendations = (search: string) => {
    // handle recommendations toggle on filter basis
    const hasFilters = !!Object.keys(discoverFilters).find(
      (key) => discoverFilters[key as keyof ExpertDiscoverFilters].length > 0
    );

    dispatch(
      ProjectNxStoreActions.toggleRecommendations(
        isAdmin ? !search && !hasFilters : false
      )
    );
  };

  const handleSearchTerm = (search: string) => {
    if (isAdmin && search === query) {
      return;
    }

    handleRecommendations(search);
    dispatch(ProjectNxStoreActions.setDiscoverExpertSearchTerm(search));
    trackFiltered(MixPanelEventNames.InternalSearchSearchBar, {
      Project: projectName,
      Client: projectClientName
    });

    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (search) {
        nonEmptyParams["query"] = search;
      } else {
        delete nonEmptyParams["query"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    expertListPaginated,
    handleSelectExpert,
    handleToggleSelectAllExperts,
    areAllExpertsSelected,
    selectedExpertsIds,
    expertsCount,
    angles,
    fetchExpertList,
    handleSearchTerm,
    clearSelectedExperts,
    selectedCautionExperts,
    handleSetIsConfirmReferralsLoading,
    isConfirmReferralsLoading
  };
};
