import { Button, Flex } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertService,
  PROJECT_REFERRAL_STATE,
  ReferralDetail,
  ToasterService
} from "@arbolus-technologies/api";
import { AvailableSlot } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";
import { BAIN_CLIENT_ID } from "@arbolus-technologies/utils";

interface ButtonsWrapperProps {
  referral: ReferralDetail;
  expertId: string;
  disableEventCreation: boolean;
  children?: React.ReactNode;
  onBookEvent: (slot?: AvailableSlot) => void;
  requestSlotsForBain?: () => void;
  expertService?: typeof ExpertService;
  notificationService?: ToasterService;
}

export const ButtonsWrapper: React.FC<ButtonsWrapperProps> = ({
  referral,
  expertId,
  disableEventCreation,
  children,
  onBookEvent,
  requestSlotsForBain,
  expertService = ExpertService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralScheduling");
  const dispatch = useDispatch();

  const [
    isRequestTimeSlotsAlreadyClicked,
    setIsRequestTimeSlotsAlreadyClicked
  ] = useState(false);

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  const isBainClient = clientId === BAIN_CLIENT_ID;
  const isExpertUnapproved = referral.status !== PROJECT_REFERRAL_STATE.ACCEPT;

  const handleRequestTimeSlots = () => {
    setIsRequestTimeSlotsAlreadyClicked(true);

    if (isBainClient && isExpertUnapproved && !isAdmin && requestSlotsForBain) {
      requestSlotsForBain();
    } else {
      // This service approves the expert directly
      expertService
        .requestExpertAvailability(referral.projectId, expertId)
        .subscribe(
          () => {
            dispatch(PanelStoreActions.closePanel(PanelId.ReferralExpertPanel));
            notificationService.showSuccess(t("requestTimeSlotSuccessMessage"));
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
          }
        );
    }
  };

  const {
    availabilityRequesterUser,
    availabilityRequiredDate,
    isRequiredMoreAvailabilitySlotsAllowed,
    waitingHours
  } = referral.referralAvailability;

  const formattedDate = moment(availabilityRequiredDate).format("ll - h:mm a");
  const availabilityRequesterName = `${availabilityRequesterUser?.firstName} ${availabilityRequesterUser?.lastName}`;

  return (
    <>
      <Flex gap={16} justify="space-between">
        {children}
        <Flex gap={16} style={{ marginLeft: "auto" }}>
          <Button disabled={disableEventCreation} onClick={() => onBookEvent()}>
            {t("bookCustomTime")}
          </Button>
          <Button
            type="primary"
            onClick={handleRequestTimeSlots}
            disabled={
              disableEventCreation ||
              isRequestTimeSlotsAlreadyClicked ||
              !isRequiredMoreAvailabilitySlotsAllowed
            }
          >
            {t("requestNewTimeSlot")}
          </Button>
        </Flex>
      </Flex>
      {!isRequiredMoreAvailabilitySlotsAllowed && (
        <DangerouslySetInnerHTML
          text={t("requireAvailabilityMessage", {
            date: formattedDate,
            name: availabilityRequesterName,
            hours: waitingHours
          })}
          color={ARBOLUS_COLORS.bColorError}
        />
      )}
    </>
  );
};
