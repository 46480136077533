import i18next from "i18next";

import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import {
  COMPANIES,
  CUSTOMERS_BASE,
  EXPERT_TABLE
} from "@arbolus-technologies/routes";

import { ISideBarItems } from "./MenuItemsConfig";
import {
  accountRoute,
  accountRouteForAdmin,
  canopiesRoute,
  clientsAdRoute,
  inboxRoute,
  invoicesRoute,
  mainRoute,
  teamMembersRoute,
  transactionsRoute
} from "./MenuItemsRoutes";

export const sideBarItemsAdminEnv = (clientId: string): ISideBarItems[] => {
  const menuItems: ISideBarItems[] = [
    {
      name: i18next.t("Projects"),
      icon: "folder",
      route: mainRoute,
      position: "main"
    },
    {
      name: i18next.t("Experts"),
      icon: "group",
      route: EXPERT_TABLE,
      position: "main"
    },
    {
      name: i18next.t("newMenu:Canopy"),
      icon: "workspaces",
      route: canopiesRoute,
      position: "main"
    },
    {
      name: i18next.t("Clients"),
      icon: "business",
      route: clientsAdRoute,
      position: "main"
    },
    {
      name: i18next.t("Inbox"),
      icon: "mail",
      route: inboxRoute,
      position: "main"
    },
    {
      name: i18next.t("newMenu:customerSurvey"),
      icon: "assignment",
      route: CUSTOMERS_BASE,
      position: "main",
      featureFlag: FEATURE_FLAGS.CustomerInsights
    },
    {
      name: i18next.t("newMenu:Settings"),
      icon: "settings",
      subItemsTitle: i18next.t("newMenu:Settings"),
      position: "footer",
      subItems: [
        {
          name: i18next.t("newMenu:Profile"),
          route: accountRoute,
          icon: "person",
          position: "main"
        },
        {
          name: i18next.t("newMenu:CompanyAccount"),
          route: accountRouteForAdmin(clientId),
          icon: "manage_accounts",
          position: "main"
        },
        {
          name: i18next.t("newMenu:TeamMembers"),
          route: teamMembersRoute,
          icon: "group",
          position: "main"
        },
        {
          name: i18next.t("newMenu:Transactions"),
          route: transactionsRoute,
          icon: "paid",
          position: "main"
        },
        {
          name: i18next.t("newMenu:Invoicing"),
          route: invoicesRoute,
          icon: "receipt_long",
          position: "main"
        },
        {
          name: i18next.t("newMenu:Companies"),
          route: COMPANIES,
          icon: "apartment",
          position: "main"
        },
        {
          name: "Feature flags",
          route: "/feature-override",
          icon: "flag",
          position: "main",
          featureFlag: FEATURE_FLAGS.FeatureFlagOverride
        },
        {
          name: i18next.t("newMenu:Logout"),
          icon: "logout",
          position: "footer"
        }
      ]
    },
    {
      name: i18next.t("newMenu:Support"),
      icon: "help",
      position: "footer"
    }
  ];

  return menuItems;
};
