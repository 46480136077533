import {
  CUSTOMER_SURVEY_ANSWERS_DATE_FORMAT,
  utilService
} from "@arbolus-technologies/utils";
import { ColDef, ValueFormatterParams } from "ag-grid-community";
import i18next from "i18next";
import moment from "moment";
import { ActionRenderer } from "../../components/ActionRenderer/ActionRenderer";
import { NPSCellRenderer } from "../../components/NPSCellRenderer/NPSCellRenderer";

const spendUSDFormatter = ({ value }: ValueFormatterParams) => {
  if (!Number.isFinite(value)) return "N/A";

  return utilService.convertValueToCurrencyFormat(value, "USD");
};

const adoptionFormatter = ({ value }: ValueFormatterParams) => {
  switch (value) {
    case "MoreThan1Year":
      return i18next.t("customerSurveyAnswerTable:moreThan1Year");
    case "LessThan1Year":
      return i18next.t("customerSurveyAnswerTable:lessThan1Year");
    case "Pilot":
      return i18next.t("customerSurveyAnswerTable:pilot");
    default:
      return i18next.t("customerSurveyAnswerTable:noData");
  }
};

const dateUpdatedFormatter = ({ value }: ValueFormatterParams) =>
  moment(value).format(CUSTOMER_SURVEY_ANSWERS_DATE_FORMAT);

export const DEFAULT_COLUMNS: ColDef[] = [
  {
    field: "vendorCompanyName",
    headerName: i18next.t("customerSurveyAnswerTable:vendor"),
    flex: 1
  },
  {
    field: "expertCurrentCompanyName",
    headerName: i18next.t("customerSurveyAnswerTable:currentCompany"),
    flex: 1
  },
  {
    field: "expertName",
    headerName: i18next.t("customerSurveyAnswerTable:expert"),
    flex: 1
  },
  {
    field: "persona",
    headerName: i18next.t("customerSurveyAnswerTable:persona"),
    flex: 1
  },
  {
    field: "spend",
    headerName: i18next.t("customerSurveyAnswerTable:spend"),
    valueFormatter: spendUSDFormatter
  },
  {
    field: "nps",
    headerName: i18next.t("customerSurveyAnswerTable:nps"),
    cellRenderer: NPSCellRenderer
  },
  {
    field: "renewalIntent",
    headerName: i18next.t("customerSurveyAnswerTable:renewalIntent")
  },
  {
    field: "implementationStage",
    headerName: i18next.t("customerSurveyAnswerTable:implementationStage"),
    valueFormatter: adoptionFormatter,
    flex: 1
  },
  {
    field: "competitorsNames",
    headerName: i18next.t("customerSurveyAnswerTable:competitors"),
    flex: 1
  },
  {
    field: "modified",
    headerName: i18next.t("customerSurveyAnswerTable:updated"),
    valueFormatter: dateUpdatedFormatter
  },
  {
    field: "answerStatus",
    headerName: i18next.t("customerSurveyAnswerTable:action"),
    maxWidth: 150,
    cellRenderer: ActionRenderer
  }
];
