import React from "react";
import { useTranslation } from "react-i18next";

import {
  CompanyAndLocationFilter,
  DiscoverFilterOption
} from "@arbolus-technologies/models/project";
import { ARBOLUS_COLORS, YELLOW } from "@arbolus-technologies/theme";
import {
  EXPERT_DISCOVER_TIME_FORMAT,
  formatDate,
  highlightWords
} from "@arbolus-technologies/utils";

import { Badge, Tooltip, Typography } from "antd"; // Import Tooltip and Badge
import { DangerouslySetInnerHTML } from "../../DangerouslySetInnerHTML/DangerouslySetInnerHTML";
import styles from "./WorkHistoryItem.module.scss";

interface WorkHistoryItemProps {
  title: string | null;
  from: string;
  to: string | null;
  companyName: string | null;
  countryName: string | null;
  industryId: string | null;
  industryName: string | null;
  companyAndLocationFilter?: CompanyAndLocationFilter;
  keywordsList?: string[] | null;
  highlightWorkHistoryTitle?: boolean;
  highlightWorkHistoryCompany?: boolean;
  industriesFilter: DiscoverFilterOption[];
}

const { Text } = Typography;

export const WorkHistoryItem: React.FC<WorkHistoryItemProps> = ({
  title,
  from,
  to,
  companyName,
  countryName,
  industryId,
  industryName,
  companyAndLocationFilter,
  keywordsList,
  highlightWorkHistoryCompany = false,
  highlightWorkHistoryTitle = false,
  industriesFilter
}) => {
  const { t } = useTranslation("workHistory");
  const fromDateFormatted = formatDate(from, EXPERT_DISCOVER_TIME_FORMAT);
  const toDateFormatted = to
    ? formatDate(to, EXPERT_DISCOVER_TIME_FORMAT)
    : t("present");

  const matchingIndustry = industriesFilter.find(
    (filter) => filter.value === industryId
  );

  const isMatchingIndustry = Boolean(matchingIndustry);

  const companies = companyAndLocationFilter?.company.map(
    (company) => company.label
  );
  const locations = companyAndLocationFilter?.workHistoryLocations.map(
    (location) => location.label
  );

  const companyTags = (): string[] => {
    let companyTagsArray: string[] = [];
    if (companies) {
      companyTagsArray = [...companies];
    }
    if (highlightWorkHistoryCompany && keywordsList) {
      companyTagsArray = [...companyTagsArray, ...keywordsList];
    }
    return companyTagsArray;
  };

  const highlightedTitle = highlightWords({
    text: title && title.length > 0 ? title : t("positionNotAvailable"),
    tags: highlightWorkHistoryTitle && keywordsList ? [...keywordsList] : [],
    highlightColor: YELLOW,
    boldedWords: true
  });

  const highlightedCompany = highlightWords({
    text:
      companyName && companyName.length > 0
        ? companyName
        : t("companyNotAvailable"),
    tags: companyTags(),
    highlightColor: YELLOW,
    boldedWords: true
  });

  const highlightedLocation = highlightWords({
    text: countryName ?? t("countryNotAvailable"),
    tags: locations ? [...locations] : [],
    highlightColor: YELLOW,
    boldedWords: true
  });

  return (
    <div className={styles.workHistoryItem}>
      <div className={styles.workHistoryDetails}>
        <div>
          <DangerouslySetInnerHTML text={highlightedTitle} />
          <Text
            style={{
              marginLeft: "4px",
              marginRight: isMatchingIndustry ? "0" : "4px"
            }}
          >
            ·
          </Text>
          <Tooltip title={industryName} placement="right">
            <span>
              {isMatchingIndustry && (
                <Badge status="success" className={styles.spacing} />
              )}
              <DangerouslySetInnerHTML text={highlightedCompany} />
            </span>
          </Tooltip>
          <Text className={styles.spacing}>·</Text>
          <Text className={styles.lightText}>
            {`${fromDateFormatted} - ${toDateFormatted}`}
          </Text>
          {highlightedLocation && (
            <>
              <Text className={styles.spacing}>·</Text>
              <DangerouslySetInnerHTML
                text={highlightedLocation}
                color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
                title={countryName ?? undefined}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
