import { Alert } from "antd";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS
} from "@arbolus-technologies/models/common";

interface DncInfoBoxProps {
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  doNotContactStatusDescription?: string;
  doNotContactStatusCategory?: DNC_CATEGORY_ENUM | null;
  isAdmin: boolean;
}

export const DncInfoBox: React.FC<DncInfoBoxProps> = ({
  doNotContactStatus,
  doNotContactStatusDescription,
  doNotContactStatusCategory,
  isAdmin
}) => {
  const { t } = useTranslation("dnc");

  const isDNC = doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
  const isProceedWithCaution =
    doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION;

  const message = [];
  if (doNotContactStatusCategory) {
    message.push(i18next.t(`dncCategories:${doNotContactStatusCategory}`));
  }
  if (doNotContactStatusDescription) {
    message.push(doNotContactStatusDescription);
  }

  return (
    <>
      {isDNC && (
        <Alert
          message={t("dncTitle")}
          description={isAdmin ? message.join(" - ") : ""}
          type="error"
          showIcon
        />
      )}

      {isAdmin && isProceedWithCaution && (
        <Alert
          message={t("cautionTitle")}
          description={message.join(" - ")}
          type="warning"
          showIcon
        />
      )}
    </>
  );
};
