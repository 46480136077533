import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ARBOLUS_COLORS,
  arbolusLogo,
  christmasHat
} from "@arbolus-technologies/theme";
import { LinkWrapper } from "@arbolus-technologies/ui/components";
import { isInternalLink, useChristmas } from "@arbolus-technologies/utils";

import { ISideBarItems } from "../MenuItemsConfig";
import { useMenuItems } from "../useMenuItems";
import { FooterMenu } from "./MenuItems/FooterMenu";
import { MainMenu } from "./MenuItems/MainMenu";

import styles from "./AntDMenu.module.scss";

interface AntDMenuProps {
  isAdmin?: boolean;
  profileRoute: string;
  handleLogout: () => void;
}

export const AntDMenu: React.FC<AntDMenuProps> = ({
  isAdmin = false,
  profileRoute,
  handleLogout
}) => {
  const authUser = useSelector(CacheSelector.loggedInUser());
  const adminUser = useSelector(CacheSelector.userAdminProfile());
  const userRole = useSelector(CacheSelector.generateActiveUserRole());
  const clientId = isAdmin ? adminUser.clientId : undefined;
  const avatarUser = isAdmin ? adminUser : authUser;

  const { menuItems } = useMenuItems(isAdmin, userRole, clientId);
  const location = useLocation();
  const { isChristmasMode } = useChristmas();

  const isActive = (route: string) => {
    if ([location.pathname].includes(route as string)) {
      return ARBOLUS_COLORS.bColorBasePurple;
    }
    return undefined;
  };

  const handleOnClick = (menuItem: ISideBarItems) => {
    const isInternal = menuItem.route ? isInternalLink(menuItem.route) : true;
    if (!isInternal) {
      window.open(menuItem.route, "_blank");
    }
  };

  const mainMenuItems = menuItems.filter((item) => item.position === "main");
  const footerMenuItems = menuItems.filter(
    (item) => item.position === "footer"
  );

  return (
    <div className={styles.menuContainer}>
      <img src={arbolusLogo} alt="Arbolus Logo" className={styles.logo} />
      <MainMenu menuItems={mainMenuItems} isActive={isActive} />
      <FooterMenu
        menuItems={footerMenuItems}
        handleOnClick={handleOnClick}
        isActive={isActive}
        handleLogout={handleLogout}
      />
      <div className={styles.avatarContainer}>
        {isChristmasMode ? (
          <div className={styles.hatContainer}>
            <img
              className={styles.christmasHat}
              src={christmasHat}
              alt="christmas hat"
            />
            <LinkWrapper to={profileRoute}>
              <AntDAvatar
                mainGap={0}
                firstName={avatarUser.firstName}
                lastName={avatarUser.lastName}
                profileImageUrl={avatarUser.profileImageUrl}
                onlyAvatar
              />
            </LinkWrapper>
          </div>
        ) : (
          <LinkWrapper to={profileRoute}>
            <AntDAvatar
              mainGap={0}
              firstName={avatarUser.firstName}
              lastName={avatarUser.lastName}
              profileImageUrl={avatarUser.profileImageUrl}
              onlyAvatar
            />
          </LinkWrapper>
        )}
      </div>
    </div>
  );
};

export default AntDMenu;
