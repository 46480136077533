import { Button } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  CIQError,
  CanopyService,
  ErrorResponse
} from "@arbolus-technologies/api";
import {
  ANSWER_TYPE,
  AnswerExpert,
  CanopySelectedQuestionDetails
} from "@arbolus-technologies/models/canopy-panels";
import { DO_NOT_CONTACT_STATUS } from "@arbolus-technologies/models/common";
import { CANOPY_SINGLE_EXPERT_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  DNCTag,
  HR,
  InfiniteScrollV2,
  ProgressComponentWithText,
  PublicCompanyBadge,
  UserPortrait
} from "@arbolus-technologies/ui/components";

import { MixPanelEventNames } from "../../../Mixpanel/enums";
import { useArbolusTracking } from "../../../Mixpanel/useArbolusTracking";

import styles from "./ExpertsListForCanopyAnswers.module.scss";

export interface ExpertsListForCanopyAnswersProps {
  questionId?: string;
  selectedQuestionDetails?: CanopySelectedQuestionDetails | null;
  panelHeader: { title: string; message?: string };
  type: ANSWER_TYPE;
  onClose: () => void;
  canopyService?: typeof CanopyService;
}

export const ExpertsListForCanopyAnswers: React.FC<
  ExpertsListForCanopyAnswersProps
> = ({
  questionId,
  selectedQuestionDetails,
  panelHeader,
  type,
  onClose,
  canopyService = CanopyService
}) => {
  const { t } = useTranslation("expertsListForCanopyAnswersSlidePanel");
  const dispatch = useDispatch();
  const history = useHistory();
  const { trackClick } = useArbolusTracking();

  const [experts, setExperts] = useState<AnswerExpert[]>([]);
  const [isExpertsLoading, setIsExpertsLoading] = useState<boolean>(false);
  const [isMore, setIsMore] = useState<boolean>(false);

  const canopyData = useSelector(CanopyV2Selector.canopyData());
  const isAdmin = useSelector(CacheSelector.isAdmin());

  useEffect(() => {
    getAnswerExperts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAnswerExperts = () => {
    setIsExpertsLoading(true);

    let promise = null;
    if (
      type === ANSWER_TYPE.MULTIPLE_CHOICE &&
      selectedQuestionDetails?.choice
    ) {
      promise = canopyService.getOptionExperts(
        selectedQuestionDetails?.choice.id!,
        experts.length
      );
    } else if (type === ANSWER_TYPE.VALUE && questionId) {
      promise = canopyService.getValueAnswersExpert(
        questionId,
        selectedQuestionDetails?.choice?.id,
        experts.length
      );
    } else if (type === ANSWER_TYPE.NPS && questionId) {
      promise = canopyService.getNpsAnswersExpert(
        questionId,
        selectedQuestionDetails?.npsValue!,
        experts.length
      );
    }

    promise?.subscribe(
      ({ items, pagination }) => {
        const updatedExperts = experts.concat(items);

        setExperts(updatedExperts);
        setIsMore(
          items.length ? updatedExperts.length < pagination.count : false
        );
        setIsExpertsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsExpertsLoading(false);
      }
    );
  };

  const handleBottomReached = () => {
    if (!isMore || isExpertsLoading) {
      return;
    }

    getAnswerExperts();
  };

  const renderExpert = (expert: AnswerExpert) => {
    const {
      firstName,
      lastName,
      expertId,
      profileImageUrl,
      title,
      otherResponse,
      value,
      lastPublicCompanyExpDate
    } = expert;

    const hasValue = Object.hasOwn(expert, "value");
    const valueAnswer = hasValue ? (value ?? t("n/a")) : "";

    const isDNC = expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC;
    const isProceedWithCaution =
      expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.CAUTION;

    return (
      canopyData && (
        <div
          className={clsx(styles.expertContainer, {
            [styles.dnc]: isDNC,
            [styles.caution]: isAdmin && isProceedWithCaution
          })}
        >
          <div className={styles.userPortraitContainer}>
            <div
              className={styles.userPortrait}
              onClick={() => handleOpenExpertProfileSlidePanel(expertId)}
            >
              <UserPortrait
                user={{
                  firstName,
                  lastName,
                  id: expertId,
                  profileImageUrl: profileImageUrl ?? "",
                  title
                }}
                avatarSize="canopyCircle"
                onHoverTooltip
                icon={
                  <>
                    {lastPublicCompanyExpDate && (
                      <PublicCompanyBadge date={lastPublicCompanyExpDate} />
                    )}
                    <DNCTag
                      dncStatus={expert.doNotContactStatus}
                      isAdmin={isAdmin}
                    />
                  </>
                }
              />
            </div>
            <Button
              text={t("answers")}
              type="tertiary"
              endIcon="chevron_right"
              onClick={() =>
                history.push(
                  CANOPY_SINGLE_EXPERT_ROUTE(canopyData.id, expertId)
                )
              }
            />
          </div>
          <div className={styles.bottomContainer}>
            <p className={styles.expertValue}>{otherResponse ?? valueAnswer}</p>
            {!isAdmin && !isDNC && (
              <Button
                text={t("askMore")}
                endIcon="chevron_right"
                onClick={() => handleOpenExpertFollowUpSlidePanel(expert)}
              />
            )}
          </div>
        </div>
      )
    );
  };

  const handleOpenExpertProfileSlidePanel = (expertId: string): void => {
    onClose();
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertProfile, {
        expertId,
        projectId: canopyData?.projectId,
        canopyId: canopyData?.id
      })
    );
  };

  const handleOpenExpertFollowUpSlidePanel = (expert: AnswerExpert): void => {
    trackClick(MixPanelEventNames.CanopyQuestionsAnswersFollowup, {
      canopyId: canopyData?.id,
      expertId: expert.expertId
    });
    onClose();
    dispatch(
      PanelStoreActions.openPanel(PanelId.ExpertEngageWithMe, {
        ...expert
      })
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.titleAndProgress}>
          {type === ANSWER_TYPE.NPS && (
            <p
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(panelHeader.title)
              }}
            />
          )}
          {type !== ANSWER_TYPE.NPS && (
            <ProgressComponentWithText
              progressTitle={panelHeader.title}
              value={selectedQuestionDetails?.percentage!}
              progressText={panelHeader.message}
            />
          )}
          <HR margin={{ top: 3, bottom: 3 }} />
        </div>
        <div
          className={clsx(styles.infiniteContainer, {
            [styles.isNps]: type === ANSWER_TYPE.NPS
          })}
        >
          <InfiniteScrollV2
            onBottomReached={handleBottomReached}
            items={experts}
            renderer={renderExpert}
            customPadding={[0, 4, 0, 0]}
            customGap={[2, 0]}
            isLoading={isExpertsLoading}
          />
        </div>
      </div>
    </div>
  );
};
