import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { User } from "@arbolus-technologies/api";
import { EDIT_USER_ROUTE } from "@arbolus-technologies/routes";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Tabs, UserHeader } from "@arbolus-technologies/ui/components";

import { AdminMemberPermissionsTab } from "./TabsContent/AdminMemberPermissionsTab/AdminMemberPermissionsTab";
import { ClientsList } from "./TabsContent/ClientsList";
import { Ownership } from "./TabsContent/Ownership";

interface TeamMemberSlidePanelProps {
  user: User;
  handleAssignClient: (userId: string) => void;
  handleCloseTeamMemberSlidePanel?: () => void;
}

export const TeamMemberContent: React.FC<TeamMemberSlidePanelProps> = ({
  user,
  handleAssignClient,
  handleCloseTeamMemberSlidePanel
}) => {
  const { id } = user;
  const { t } = useTranslation("teamMemberSlidePanel");
  const history = useHistory();

  const threeDotsItems = [
    {
      title: t("assignClient"),
      icon: "add",
      iconColor: ARBOLUS_COLORS.bColorGrayIcon,
      color: "",
      onClick: () => handleAssignClient(id)
    },
    {
      title: t("editProfile"),
      icon: "edit",
      iconColor: ARBOLUS_COLORS.bColorGrayIcon,
      color: "",
      onClick: () => history.push(EDIT_USER_ROUTE(id))
    }
  ];

  const tabs = useMemo(
    () => ({
      ownerShip: {
        title: t("ownerShip"),
        component: () => (
          <Ownership
            userId={user?.id}
            handleCloseTeamMemberSlidePanel={handleCloseTeamMemberSlidePanel}
          />
        )
      },
      clients: {
        title: t("clients"),
        component: () => <ClientsList user={user} />
      },
      permissions: {
        title: t("permissions"),
        component: () => (
          <AdminMemberPermissionsTab
            user={user}
            handleCloseTeamMemberSlidePanel={handleCloseTeamMemberSlidePanel}
          />
        )
      }
    }),
    [t, user, handleCloseTeamMemberSlidePanel]
  );

  return (
    <>
      <UserHeader user={user} threeDotsItems={threeDotsItems} />
      <Tabs tabMapping={tabs} />
    </>
  );
};
