// TODO: Project Legacy that will be moved on project dashboard v2
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Angle } from "@arbolus-technologies/api";
import { ViewEditAngle } from "@arbolus-technologies/features/angles";
import { ProjectAnglesSelector } from "@arbolus-technologies/stores/project-angles-store";
import { LoaderOrComponent } from "@arbolus-technologies/ui/components";

interface ViewAngleProps {
  projectId: string;
  isAdmin: boolean;
  closePanel: () => void;
  angles: Angle[];
}

export const ViewAngle: React.FC<ViewAngleProps> = ({
  projectId,
  isAdmin,
  closePanel,
  angles
}) => {
  const { t } = useTranslation("projectAngles");
  const isLoading = useSelector(ProjectAnglesSelector.isSingleAngleLoading());

  return (
    <LoaderOrComponent isLoading={isLoading}>
      <ViewEditAngle
        title={t("manage")}
        projectId={projectId}
        handleCloseIcon={closePanel}
        isAdmin={isAdmin}
        angles={angles}
        isEdit
      />
    </LoaderOrComponent>
  );
};
