import { PaginatedResponseWithStatus } from "@arbolus-technologies/api";
import {
  DiscoverExpert,
  DiscoverFilterOption,
  DiscoverFilterType,
  ExpertDiscoverFilters,
  FilterTimeSelectorOptions
} from "@arbolus-technologies/models/project";

import { ExpertDiscover } from "../../models/definitions";
import {
  CLEAR_ALL_DISCOVER_FILTERS,
  CLEAR_DISCOVER_FILTER,
  CLEAR_KEYWORDS_SEARCH_OPTIONS,
  ClearAllDiscoverFiltersAction,
  ClearDiscoverFilterAction,
  ClearKeywordsSearchOptionsAction,
  REFETCH_PROJECT_REFERRALS_DATA,
  RefetchReferralDataAction,
  SET_ALL_DISCOVER_FILTERS,
  SET_COMPANY_FILTER_PERIOD,
  SET_COMPANY_IN_PAST_MONTHS,
  SET_DISCOVER_EXPERT_ALL_FILTERS,
  SET_DISCOVER_EXPERT_CURRENT_PAGE,
  SET_DISCOVER_EXPERT_LIST,
  SET_DISCOVER_EXPERT_LIST_LOADING,
  SET_DISCOVER_EXPERT_SEARCH_TERM,
  SET_DISCOVER_FILTER,
  SET_DISCOVER_FILTER_PRE_SELECTIONS,
  SET_FILTER_QUERY,
  SET_INDUSTRY_FILTER_PERIOD,
  SET_KEYWORDS_SEARCH_OPTIONS,
  SET_WORK_HISTORY_LOCATION_FILTER_PERIOD,
  SetAllDiscoverFiltersAction,
  SetCompanyFilterPeriodAction,
  SetCompanyInPastMonthsAction,
  SetDiscoverExpertAllFiltersAction,
  SetDiscoverExpertCurrentPageAction,
  SetDiscoverExpertListAction,
  SetDiscoverExpertListLoadingAction,
  SetDiscoverExpertSearchTermAction,
  SetDiscoverFilterAction,
  SetDiscoverFilterPreSelectionsAction,
  SetFilterQueryAction,
  SetIndustryFilterPeriodAction,
  SetKeywordsSearchOptionsAction,
  SetWorkHistoryLocationFilterPeriodAction,
  TOGGLE_DNC_EXPERTS,
  TOGGLE_RECOMMENDATIONS,
  ToggleDncExpertsAction,
  ToggleRecommendationsAction
} from "./ExpertDiscoverActionTypes";

export const setDiscoverFilter = (
  filterType: DiscoverFilterType,
  filters: DiscoverFilterOption[]
): SetDiscoverFilterAction => ({
  type: SET_DISCOVER_FILTER,
  payload: {
    filterType,
    filters
  }
});

export const setDiscoverFilterPreSelections = (
  filterType: DiscoverFilterType,
  filters: DiscoverFilterOption[]
): SetDiscoverFilterPreSelectionsAction => ({
  type: SET_DISCOVER_FILTER_PRE_SELECTIONS,
  payload: {
    filterType,
    filters
  }
});

export const setAllDiscoverFilters = (
  filters: ExpertDiscoverFilters
): SetAllDiscoverFiltersAction => ({
  type: SET_ALL_DISCOVER_FILTERS,
  payload: {
    filters
  }
});

export const clearDiscoverFilter = (
  filterType: DiscoverFilterType
): ClearDiscoverFilterAction => ({
  type: CLEAR_DISCOVER_FILTER,
  payload: {
    filterType
  }
});

export const clearAllDiscoverFilters = (
  displayRecommendations: boolean
): ClearAllDiscoverFiltersAction => ({
  type: CLEAR_ALL_DISCOVER_FILTERS,
  payload: { displayRecommendations }
});

export const setCompanyFilterPeriod = (
  companyFilterPeriod: FilterTimeSelectorOptions
): SetCompanyFilterPeriodAction => ({
  type: SET_COMPANY_FILTER_PERIOD,
  payload: {
    companyFilterPeriod
  }
});

export const setCompanyInPastMonths = (
  companyInPastMonths: number
): SetCompanyInPastMonthsAction => ({
  type: SET_COMPANY_IN_PAST_MONTHS,
  payload: {
    companyInPastMonths
  }
});

export const setWorkHistoryLocationFilterPeriod = (
  workHistoryLocationFilterPeriod: FilterTimeSelectorOptions
): SetWorkHistoryLocationFilterPeriodAction => ({
  type: SET_WORK_HISTORY_LOCATION_FILTER_PERIOD,
  payload: {
    workHistoryLocationFilterPeriod
  }
});

export const setIndustryFilterPeriod = (
  industryFilterPeriod: FilterTimeSelectorOptions
): SetIndustryFilterPeriodAction => ({
  type: SET_INDUSTRY_FILTER_PERIOD,
  payload: {
    industryFilterPeriod
  }
});

export const setKeywordsSearchOptions = (
  keywordsSearchOptions: string[]
): SetKeywordsSearchOptionsAction => ({
  type: SET_KEYWORDS_SEARCH_OPTIONS,
  payload: {
    keywordsSearchOptions
  }
});

export const clearKeywordsSearchOptions =
  (): ClearKeywordsSearchOptionsAction => ({
    type: CLEAR_KEYWORDS_SEARCH_OPTIONS,
    payload: {}
  });

export const setFilterQuery = (
  filterType: DiscoverFilterType,
  query: string
): SetFilterQueryAction => ({
  type: SET_FILTER_QUERY,
  payload: {
    filterType,
    query
  }
});

export const toggleRecommendations = (
  displayRecommendations: boolean
): ToggleRecommendationsAction => ({
  type: TOGGLE_RECOMMENDATIONS,
  payload: {
    displayRecommendations
  }
});

export const toggleDncExperts = (
  hideDncExperts: boolean
): ToggleDncExpertsAction => ({
  type: TOGGLE_DNC_EXPERTS,
  payload: {
    hideDncExperts
  }
});

export const refetchReferrals = (
  projectId: string
): RefetchReferralDataAction => ({
  type: REFETCH_PROJECT_REFERRALS_DATA,
  payload: {
    projectId
  }
});

export const setDiscoverExpertSearchTerm = (
  searchTerm: string
): SetDiscoverExpertSearchTermAction => ({
  type: SET_DISCOVER_EXPERT_SEARCH_TERM,
  payload: {
    searchTerm
  }
});

export const setDiscoverExpertList = (
  expertsList: PaginatedResponseWithStatus<DiscoverExpert> | null
): SetDiscoverExpertListAction => ({
  type: SET_DISCOVER_EXPERT_LIST,
  payload: {
    expertsList
  }
});

export const setDiscoverExpertCurrentPage = (
  currentPage: number,
  pageSize: number
): SetDiscoverExpertCurrentPageAction => ({
  type: SET_DISCOVER_EXPERT_CURRENT_PAGE,
  payload: {
    currentPage,
    pageSize
  }
});

export const setDiscoverExpertAllFilters = (
  expertDiscover: ExpertDiscover
): SetDiscoverExpertAllFiltersAction => ({
  type: SET_DISCOVER_EXPERT_ALL_FILTERS,
  payload: { expertDiscover }
});

export const setDiscoverExpertListLoading = (
  isLoading: boolean
): SetDiscoverExpertListLoadingAction => ({
  type: SET_DISCOVER_EXPERT_LIST_LOADING,
  payload: { isLoading }
});
