import { Card, Col, Divider, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import {
  ExpertDetail,
  ReferralDetail,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { AvatarExpertStatusIconInfo } from "./AvatarExpertStatusIconInfo";
import { HeaderActions } from "./HeaderActions";
import { Statuses } from "./Statuses";

interface ExpertInfoProps {
  expert: ExpertDetail;
  referral: ReferralDetail | null;
  isVerified: boolean;
  showProfileOnly: boolean;
  onAddNote: () => void;
  onReferralRemoval?: (referralId: string) => void;
  onRejectExpert: () => void;
  updateReferralState: (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ) => void;
  onMoveToAvailabilityTab: () => void;
}

export const ExpertInfo: React.FC<ExpertInfoProps> = ({
  expert,
  referral,
  isVerified,
  showProfileOnly,
  onAddNote,
  onReferralRemoval,
  onRejectExpert,
  updateReferralState,
  onMoveToAvailabilityTab
}) => {
  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const expertCountry = metadataCountries.find(
    (country) => country.threeLetterCode === expert.user.isoCountryCode
  );

  return (
    <Card>
      <Row gutter={24}>
        <Col span={18}>
          <AntDAvatar
            firstName={expert.user.firstName}
            lastName={expert.user.lastName}
            profileImageUrl={expert.user.profileImageUrl}
            size={64}
            title={
              showProfileOnly
                ? expert.user.title
                : (referral?.tagline ?? expert.user.title)
            }
            isVerified={isVerified}
            lastPublicCompanyExpDate={expert.lastPublicCompanyExpDate}
            linkedInProfile={expert.linkedinProfile}
            countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
            countryName={expertCountry?.name ?? ""}
            angle={!showProfileOnly ? referral?.angle : undefined}
            icon={
              !showProfileOnly &&
              referral && (
                <AvatarExpertStatusIconInfo referralStatus={referral.status} />
              )
            }
            mainGap={16}
          />
        </Col>
        <Col span={6}>
          {!showProfileOnly && referral && (
            <HeaderActions
              expert={expert}
              referral={referral}
              onAddNote={onAddNote}
              onReferralRemoval={onReferralRemoval}
              onRejectExpert={onRejectExpert}
              updateReferralState={updateReferralState}
              onMoveToAvailabilityTab={onMoveToAvailabilityTab}
            />
          )}
        </Col>
      </Row>
      {!showProfileOnly && referral && (
        <>
          <Divider />
          <Statuses
            applicationStatus={referral.application.subStatus}
            complianceStatus={referral.compliance.subStatus}
            referralStatus={referral.status}
            reviewStatus={referral.review.subStatus}
            fastTrack={referral.fastTrack.fastTrack}
            complianceRequired={referral.complianceRequired}
            doNotContactStatus={expert.doNotContactStatus}
            onRejectExpert={onRejectExpert}
            updateReferralState={updateReferralState}
          />
        </>
      )}
    </Card>
  );
};
