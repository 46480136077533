import {
  API_URL_V1,
  ApiPaginatedResponse,
  SimpleRestService
} from "@arbolus-technologies/api";
import {
  CustomerSurvey,
  InsightAnswerUpdateStatus,
  JobWithVendors
} from "@arbolus-technologies/models/common";

import { CUSTOMERS_API } from "../endpoints/customers";
import {
  AnswerDetails,
  CreateInsightResponse,
  GetSurveyAnswersDetailsQueryParams,
  GetSurveyAnswersResponse,
  GetSurveyTemplateResponse,
  GetSurveyTemplatesRequest,
  SurveyAnswer,
  SurveyCompanyUpdate
} from "../models/customers";
import { restService } from "../restService";

/*
 * Returns insights for every job (work history item) of this expert
 */
export function getInsightsFromExpert(expertId: string) {
  return restService.get<{ items: JobWithVendors[] }>(
    CUSTOMERS_API.EXPERT_INSIGHTS(expertId)
  );
}

/*
 * For editing the expert profile or starting the survey (send without history ID)
 */
export function createInsight(payload: {
  expertId: string;
  vendorCompanyIds: string[];
  workHistoryId?: string; // from expert profile
  surveyId?: string; // from survey
  sourceType?: string; // add companies from "add note" expert profile container
}) {
  return restService.post<CreateInsightResponse>(
    CUSTOMERS_API.INSIGHTS,
    payload
  );
}

export function deleteInsight(customerInsightId: string) {
  return restService.delete(CUSTOMERS_API.SINGLE_INSIGHT(customerInsightId));
}

export function getSurveyTemplates(params: GetSurveyTemplatesRequest) {
  return restService.get<{
    items: CustomerSurvey[];
    pagination: { count: number };
  }>(CUSTOMERS_API.SURVEYS, params);
}

export function getSurveyTemplate(surveyId: string) {
  return restService.get<GetSurveyTemplateResponse>(
    CUSTOMERS_API.SPECIFIC_SURVEY(surveyId)
  );
}

export function createSurveyTemplate(title: string) {
  return restService.post(CUSTOMERS_API.SURVEYS, {
    title
  });
}

export function updateSurveyTemplateCompanies(
  id: string,
  companies: SurveyCompanyUpdate[]
) {
  return restService.put(CUSTOMERS_API.SURVEY_COMPANIES(id), {
    companies
  });
}

export function getSurveyAnswers(surveyId: string, expertId: string) {
  return restService.get<GetSurveyAnswersResponse>(
    CUSTOMERS_API.SURVEY_ANSWERS_FOR_EXPERT(surveyId, expertId)
  );
}

export function updateSurveyCompetitors(
  surveyId: string,
  payload: { customerId: string; competitors: string[] }
) {
  return restService.put(
    CUSTOMERS_API.SURVEY_ANSWERS_COMPETITORS(surveyId),
    payload
  );
}

export function updateSurveyInsights(surveyId: string, payload: SurveyAnswer) {
  return restService.put(
    CUSTOMERS_API.SURVEY_ANSWERS_INSIGHTS(surveyId),
    payload
  );
}

export function getSurveyAnswersDetails(
  queryParams: GetSurveyAnswersDetailsQueryParams
) {
  return SimpleRestService.call_get<ApiPaginatedResponse<AnswerDetails[]>>(
    API_URL_V1 + CUSTOMERS_API.SURVEY_ANSWERS_DETAILS,
    queryParams
  );
}

export function updateInsightAnswerStatus(
  insightId: string,
  payload: { status: InsightAnswerUpdateStatus }
) {
  return SimpleRestService.call_put(
    `${API_URL_V1}${CUSTOMERS_API.INSIGHTS}/${insightId}`,
    payload
  );
}
