import React from "react";
import { useTranslation } from "react-i18next";

import {
  ApiPaginatedRequest,
  BaseSurvey,
  SurveyService
} from "@arbolus-technologies/api";

import {
  SelectController,
  TypeaheadSelectorControllerProps
} from "../SelectTypeahead/SelectController";

interface SurveySelectorProps
  extends TypeaheadSelectorControllerProps<BaseSurvey> {
  projectId: string;
}

export const SurveySelector: React.FC<SurveySelectorProps> = ({
  projectId,
  placeholderText,
  ...props
}) => {
  const { t } = useTranslation();
  const placeholder = placeholderText ?? t("common:surveySelection:findSurvey");

  const renderer = ({ title }: BaseSurvey): JSX.Element => <span>{title}</span>;

  const getItems = (pagination: ApiPaginatedRequest) =>
    SurveyService.getAllSurveysForAProject({
      projectId,
      limit: 10,
      offset: pagination.offset,
      searchTerm: pagination.searchTerm
    });

  return (
    <SelectController
      {...props}
      placeholderText={placeholder}
      promptText={t("common:surveySelection:findingSurveys")}
      searchText={t("common:surveySelection:findingSurveys")}
      paginationText={t("common:surveySelection:loadMore")}
      orderBy="surveyTitle"
      renderer={renderer}
      getLabelKey={(survey) => survey.title}
      getItems={getItems}
    />
  );
};
