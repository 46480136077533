import { OutputSelector, createSelector } from "reselect";

import {
  Canopy,
  CanopyExpertsCount,
  CanopyQuestion
} from "@arbolus-technologies/api";
import { CanopyQuestionModel } from "@arbolus-technologies/models/canopy-panels";

import { CanopyV2AppState, CanopyV2ReducerState } from "../models/definitions";

const canopyBuilderStateSelector = (
  state: CanopyV2AppState
): CanopyV2ReducerState => state.canopyV2;

// Canopy
const canopyData = (
  canopyId?: string
): OutputSelector<
  CanopyV2AppState,
  Canopy | null,
  (res: CanopyV2ReducerState) => Canopy | null
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, Canopy | null>(
    canopyBuilderStateSelector,
    (canopyReducerState) =>
      canopyId === undefined || canopyReducerState.canopyData?.id === canopyId
        ? canopyReducerState.canopyData
        : null
  );

const canopyTitle = (
  canopyId?: string
): OutputSelector<
  CanopyV2AppState,
  string,
  (res: CanopyV2ReducerState) => string
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, string>(
    canopyBuilderStateSelector,
    (canopyReducerState) =>
      canopyId === undefined || canopyReducerState.canopyData?.id === canopyId
        ? (canopyReducerState.canopyData?.title ?? "")
        : ""
  );

// Canopy Questions
const canopyQuestions = (): OutputSelector<
  CanopyV2AppState,
  CanopyQuestionModel[],
  (res: CanopyV2ReducerState) => CanopyQuestionModel[]
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, CanopyQuestionModel[]>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopyQuestions
  );

const isCanopyQuestionsLoading = (): OutputSelector<
  CanopyV2AppState,
  boolean,
  (res: CanopyV2ReducerState) => boolean
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isCanopyQuestionsLoading
  );

const isCanopyDataLoading = (): OutputSelector<
  CanopyV2AppState,
  boolean,
  (res: CanopyV2ReducerState) => boolean
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, boolean>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.isCanopyDataLoading
  );

const questionData = (): OutputSelector<
  CanopyV2AppState,
  CanopyQuestion | null,
  (res: CanopyV2ReducerState) => CanopyQuestion | null
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, CanopyQuestion | null>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.questionData
  );

const canopyProjectId = (): OutputSelector<
  CanopyV2AppState,
  string,
  (res: CanopyV2ReducerState) => string
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, string>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.canopyData?.projectId ?? ""
  );

const canopyExpertsCount = (): OutputSelector<
  CanopyV2AppState,
  CanopyExpertsCount,
  (res: CanopyV2ReducerState) => CanopyExpertsCount
> =>
  createSelector<CanopyV2AppState, CanopyV2ReducerState, CanopyExpertsCount>(
    canopyBuilderStateSelector,
    (canopyReducerState) => canopyReducerState.expertsCount
  );

export const CanopyV2Selector = {
  canopyData,
  canopyTitle,
  isCanopyDataLoading,
  questionData,
  canopyQuestions,
  isCanopyQuestionsLoading,
  canopyProjectId,
  canopyExpertsCount
};
