import { ActionCreator } from "redux";

import { ProviderIndustry, Region, TimeZone } from "@arbolus-technologies/api";
import {
  ArbolusFeatureFlag,
  ArbolusFeatureFlags
} from "@arbolus-technologies/models/common";

import { REDIRECT_STATES } from "../../../../constants/app";
import { INBOX_NOTIFICATION_CATEGORY } from "../../../../constants/notifications";
import { CIQError, ErrorResponse } from "../../../../models/api";
import {
  Country,
  Currency,
  Industry,
  UserPermissions
} from "../../../../models/meta";
import { LoggedInUser } from "../../../../models/user";
import {
  GET_APP_DATA,
  GET_APP_DATA_FAILURE,
  GET_APP_DATA_SUCCESS,
  GET_FEATURE_FLAGS,
  GET_FEATURE_FLAGS_FAILURE,
  GET_FEATURE_FLAGS_SUCCESS,
  GET_USER_PROFILE,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_SUCCESS,
  GetAppDataAction,
  GetAppDataFailureAction,
  GetAppDataSuccessAction,
  GetFeatureFlagsAction,
  GetFeatureFlagsFailureAction,
  GetFeatureFlagsSuccessAction,
  GetUserProfileAction,
  GetUserProfileFailureAction,
  GetUserProfileSuccessAction,
  INITIALIZE_FLAGS_OVERRIDE,
  InitializeFlagsOverrideAction,
  OVERRIDE_FEATURE_FLAG,
  OverrideFeatureFlagAction,
  RESET_OVERRIDE_FEATURE_FLAG,
  ResetOverrideFeatureFlagAction,
  SWITCH_SUBDOMAIN,
  SwitchSubdomainAction,
  UPDATE_USER_PROFILE,
  UPDATE_USER_PROFILE_CLIENT_NAME,
  UpdateUserProfileAction,
  UpdateUserProfileClientNameAction
} from "./AppActionTypes";

export const switchSubdomain: ActionCreator<SwitchSubdomainAction> = (
  redirectType: REDIRECT_STATES
) => ({
  type: SWITCH_SUBDOMAIN,
  payload: {
    redirectType
  }
});

export const getUserProfile: ActionCreator<GetUserProfileAction> = (
  initial = false
) => ({
  type: GET_USER_PROFILE,
  payload: {
    initial
  }
});

export const getUserProfileSuccess: ActionCreator<
  GetUserProfileSuccessAction
> = (user: LoggedInUser) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload: {
    user
  }
});

export const getUserProfileFailure: ActionCreator<
  GetUserProfileFailureAction
> = (error: ErrorResponse<CIQError>) => ({
  type: GET_USER_PROFILE_FAILURE,
  payload: {
    error
  }
});

export const getAppData: ActionCreator<GetAppDataAction> = () => ({
  type: GET_APP_DATA
});

export const getAppDataSuccess: ActionCreator<GetAppDataSuccessAction> = (
  countries: Country[],
  industriesLevel0: Industry[],
  industriesLevel2: Industry[],
  providerIndustries: ProviderIndustry[],
  timezones: TimeZone[],
  regions: Region[],
  currencies: Currency[],
  notificationCounts: Map<INBOX_NOTIFICATION_CATEGORY, number>
) => ({
  type: GET_APP_DATA_SUCCESS,
  payload: {
    countries,
    industriesLevel0,
    industriesLevel2,
    providerIndustries,
    timezones,
    regions,
    currencies,
    notificationCounts
  }
});

export const getAppDataFailure: ActionCreator<GetAppDataFailureAction> = (
  error: ErrorResponse<CIQError>
) => ({
  type: GET_APP_DATA_FAILURE,
  payload: {
    error
  }
});

export const updateUserProfile: ActionCreator<UpdateUserProfileAction> = (
  user: LoggedInUser
) => ({
  type: UPDATE_USER_PROFILE,
  payload: {
    user
  }
});

export const updateUserProfileClientName: ActionCreator<
  UpdateUserProfileClientNameAction
> = (name: string) => ({
  type: UPDATE_USER_PROFILE_CLIENT_NAME,
  payload: {
    name
  }
});

export const getFeatureFlags = (): GetFeatureFlagsAction => ({
  type: GET_FEATURE_FLAGS
});

export const getFeatureFlagsSuccess = (
  userPermissions: UserPermissions
): GetFeatureFlagsSuccessAction => ({
  type: GET_FEATURE_FLAGS_SUCCESS,
  payload: {
    userPermissions
  }
});

export const getFeatureFlagsFailure = (
  error: ErrorResponse<CIQError>
): GetFeatureFlagsFailureAction => ({
  type: GET_FEATURE_FLAGS_FAILURE,
  payload: {
    error
  }
});

export const overrideFeatureFlag = (
  name: ArbolusFeatureFlag,
  value: boolean
): OverrideFeatureFlagAction => ({
  type: OVERRIDE_FEATURE_FLAG,
  payload: {
    name,
    value
  }
});

export const resetOverrideFeatureFlag = (): ResetOverrideFeatureFlagAction => ({
  type: RESET_OVERRIDE_FEATURE_FLAG
});

export const initializeFlagsOverride = (
  flags: ArbolusFeatureFlags
): InitializeFlagsOverrideAction => ({
  type: INITIALIZE_FLAGS_OVERRIDE,
  payload: { flags }
});
