import { ColDef, GridApi } from "ag-grid-community";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ApiPaginatedResponseTranscriptions,
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  LIST_TRANSCRIPTS_ORDER_BY,
  ListTranscriptModel,
  ListTranscriptsParams,
  SORT_DIRECTION,
  ToasterService,
  TranscriptService
} from "@arbolus-technologies/api";
import {
  ArbolusGrid,
  Loader,
  floatingFilter
} from "@arbolus-technologies/ui/components";

import { Alert } from "antd";
import { AngleCellRenderer } from "../../Components/CellRenderers/AngleCellRenderer";
import { ActionsCellRenderer } from "./ActionsCellRenderer";
import { TranscriptNameRenderer } from "./TranscriptNameCellRenderer";
import { TranscriptStatusCellRenderer } from "./TranscriptStatusCellRenderer";

import styles from "./TranscriptsTable.module.scss";

function formatDate(value: string) {
  return moment(value).format("DD MMM YYYY | HH:mm");
}

const queryParams: ListTranscriptsParams = {
  limit: 0,
  offset: 0,
  sort: [
    {
      orderBy: LIST_TRANSCRIPTS_ORDER_BY.CREATED,
      orderDirection: SORT_DIRECTION.DESCENDING
    }
  ]
};

interface TranscriptsTableProps {
  onGridApiReady?: (api: GridApi<ListTranscriptModel>) => void;
  projectId: string;
  transcriptService?: typeof TranscriptService;
  notificationService?: ToasterService;
}

export const TranscriptsTable: React.FC<TranscriptsTableProps> = ({
  onGridApiReady,
  projectId,
  transcriptService = TranscriptService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("transcriptListPage");
  const [transcriptData, setTranscriptData] = useState<ListTranscriptModel[]>();
  const [expiringThisWeek, setExpiringThisWeek] = useState<number>(0);
  const [isAnyExpiringInThreeMonths, setIsAnyExpiringInThreeMonths] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const gridSetup = useCallback(
    (api: GridApi<ListTranscriptModel>) => {
      onGridApiReady?.(api);
    },
    [onGridApiReady]
  );

  useEffect(() => {
    setIsLoading(true);
    transcriptService.listTranscripts(projectId, queryParams).subscribe({
      next: (
        response: ApiPaginatedResponseTranscriptions<ListTranscriptModel>
      ) => {
        setExpiringThisWeek(response.expiringThisWeek);
        setIsAnyExpiringInThreeMonths(response.isAnyExpiringInThreeMonths);
        setTranscriptData(response.items);
      },
      complete: () => setIsLoading(false),
      error: (error: ErrorResponse<CIQError>) => {
        console.error(error);
        notificationService.showApiErrors(error);
        setIsLoading(false);
      }
    });
  }, [notificationService, projectId, transcriptService]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: false,
      resizable: true,
      suppressMovable: true,
      sortable: true,
      menuTabs: ["filterMenuTab"]
    }),
    []
  );

  const columnDefs = useMemo<ColDef[]>(() => {
    const columns: ColDef[] = [
      {
        field: "name",
        colId: LIST_TRANSCRIPTS_ORDER_BY.NAME,
        headerName: t("name"),
        flex: 1,
        minWidth: 200,
        sortable: false,
        filter: "agTextColumnFilter",
        filterParams: {
          maxNumConditions: 1,
          filterOptions: ["contains"]
        },
        filterValueGetter: ({ data }) => data.name,
        cellClass: styles.expertCellRenderer,
        cellRenderer: TranscriptNameRenderer,
        cellRendererParams: {
          projectId
        },
        ...floatingFilter
      },
      {
        field: "expiresIn",
        colId: LIST_TRANSCRIPTS_ORDER_BY.STATUS,
        headerName: t("status"),
        maxWidth: 120,
        sortable: false,
        cellClass: styles.statusCellRenderer,
        cellRenderer: TranscriptStatusCellRenderer,
        hide: !isAnyExpiringInThreeMonths,
        filterValueGetter: ({ data }) => data.expiresIn <= 7,
        filter: "agSetColumnFilter",
        filterParams: {
          valueFormatter: () => t("expiringSoon"),
          suppressMiniFilter: true,
          suppressSelectAll: true,
          defaultToNothingSelected: true,
          values: [true]
        }
      },
      {
        field: "created",
        colId: LIST_TRANSCRIPTS_ORDER_BY.CREATED,
        headerName: t("callDate"),
        minWidth: 100,
        maxWidth: 170,
        cellClass: styles.dateCellRenderer,
        suppressHeaderMenuButton: true,
        valueGetter: ({ data }) => data.created,
        valueFormatter: ({ value }) => formatDate(value)
      },
      {
        field: "durationInSeconds",
        colId: LIST_TRANSCRIPTS_ORDER_BY.DURATION,
        headerName: t("duration"),
        minWidth: 100,
        maxWidth: 150,
        cellClass: styles.dateCellRenderer,
        suppressHeaderMenuButton: true,
        valueGetter: ({ data }) =>
          moment.utc(data.durationSeconds * 1000).format("H:mm:ss")
      },
      {
        field: LIST_TRANSCRIPTS_ORDER_BY.ANGLE_TITLE,
        flex: 1,
        headerName: t("angleName"),
        minWidth: 100,
        sortable: false,
        cellClass: styles.angleCellRenderer,
        cellRenderer: AngleCellRenderer,
        filter: "agSetColumnFilter",
        filterValueGetter: ({ data }) => data.angle?.title,
        ...floatingFilter
      },
      {
        field: "action",
        headerName: t("action"),
        width: 78,
        maxWidth: 78,
        minWidth: 78,
        cellClass: styles.actionCellRenderer,
        resizable: false,
        suppressHeaderMenuButton: true,
        sortable: false,
        cellRenderer: ActionsCellRenderer,
        cellRendererParams: {
          projectId
        }
      }
    ];

    return columns;
  }, [projectId, isAnyExpiringInThreeMonths, t]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="h-100">
      {expiringThisWeek > 0 && (
        <>
          <Alert
            message={t("xTranscriptsToBeExpireTitle", {
              count: expiringThisWeek
            })}
            description={t("xTranscriptsToBeExpireDescription")}
            type="warning"
            showIcon
          />
          <br />
        </>
      )}
      <ArbolusGrid
        setup={gridSetup}
        rowData={transcriptData}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowSelection="multiple"
        suppressRowClickSelection
        skipHeaderOnAutoSize
        suppressContextMenu
        suppressMenuHide
        pagination
        paginationAutoPageSize
      />
    </div>
  );
};
