import React from "react";
import { useFormContext } from "react-hook-form";

import { AntDIcon } from "@arbolus-technologies/antDComponents";

import {
  SurveyQuestion,
  SurveyQuestionType
} from "../../models/CustomerSurveys";
import { FIELD_NAMES } from "../../modules/SurveySteps/GiveDetailsStep/CompanyQuestionsForm/expertSurveyQuestions";
import { MoneyTextInput } from "./questionComponents/MoneyTextInput/MoneyTextInput";
import { ScoreSelect } from "./questionComponents/ScoreSelect/ScoreSelect";
import { SingleChoiceRadio } from "./questionComponents/SingleChoiceRadio";

import styles from "./Question.module.scss";

const { SPEND } = FIELD_NAMES;

const QUESTION_TO_COMPONENT = {
  [SurveyQuestionType.SingleChoiceRadio]: SingleChoiceRadio,
  [SurveyQuestionType.MoneyTextInput]: MoneyTextInput,
  [SurveyQuestionType.ScoreSelect]: ScoreSelect
};

export function Question({ question }: { question: SurveyQuestion }) {
  const QuestionBody = QUESTION_TO_COMPONENT[question.type];
  const {
    formState: { errors }
  } = useFormContext();
  const showMessageError =
    errors[question.fieldName]?.message && question.fieldName !== SPEND;

  return (
    <div className={styles.question}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{question.title}</span>
        {showMessageError && (
          <div className={styles.errorWrapper}>
            <AntDIcon name="info" fontSize="12px" color="red" />
            <span className={styles.errorMessage}>
              {errors[question.fieldName]?.message}
            </span>
          </div>
        )}
      </div>
      <QuestionBody question={question} />
    </div>
  );
}
