import arbolusLogoWithBackground from "./lib/assets/arbolus-logo-with-background.svg";
import arbolusLogo from "./lib/assets/arbolus-logo.svg";
import bolt from "./lib/assets/bolt.svg";
import businessIcon from "./lib/assets/business.svg";
import eyes from "./lib/assets/eyes.svg";
import fiveSlotsIcon from "./lib/assets/fiveSlotsIcon.svg";
import fourSlotsIcon from "./lib/assets/fourSlotsIcon.svg";
import handshake from "./lib/assets/handshake.svg";
import linkedin from "./lib/assets/linkedin.svg";
import logoNameBlue from "./lib/assets/logo-name-blue.svg";
import magnifyingGlass from "./lib/assets/magnifyingGlass.svg";
import micAntd from "./lib/assets/mic-antd.svg";
import micOffAntd from "./lib/assets/mic-off-antd.svg";
import oneSlotIcon from "./lib/assets/oneSlotIcon.svg";
import rocket from "./lib/assets/rocket.svg";
import statusExpertCandidate from "./lib/assets/status-expert-candidate.svg";
import statusExpertShortList from "./lib/assets/status-expert-shortlist.svg";
import thinkingFace from "./lib/assets/thinking-face.svg";
import threeSlotsIcon from "./lib/assets/threeSlotsIcon.svg";
import twoSlotsIcon from "./lib/assets/twoSlotsIcon.svg";
import zeroSlotsIcon from "./lib/assets/zeroSlotsIcon.svg";
import christmasHat from "./lib/images/christmasHat.png";
import fastestEurope from "./lib/images/fastestEurope.png";
import fastestUK from "./lib/images/fastestUK.png";
import hourglassIcon from "./lib/images/hourglass.png";
import instagramLogo from "./lib/images/instagramLogo.png";
import linkedInLogo from "./lib/images/linkedInLogo.png";
import surveyLandingQualified from "./lib/images/surveyLandingQualified.png";
import surveyLandingTrustpilot from "./lib/images/surveyLandingTrustpilot.png";
import trustpilotArbolus from "./lib/images/trustpilotArbolus.png";

export * from "./lib/colors/colorConstants";
export * from "./lib/elevation";

export {
  arbolusLogo,
  arbolusLogoWithBackground,
  bolt,
  businessIcon,
  eyes,
  fastestEurope,
  fastestUK,
  fiveSlotsIcon,
  fourSlotsIcon,
  handshake,
  hourglassIcon,
  instagramLogo,
  linkedInLogo,
  linkedin,
  logoNameBlue,
  magnifyingGlass,
  micAntd,
  micOffAntd,
  oneSlotIcon,
  rocket,
  statusExpertCandidate,
  statusExpertShortList,
  surveyLandingQualified,
  surveyLandingTrustpilot,
  thinkingFace,
  threeSlotsIcon,
  trustpilotArbolus,
  twoSlotsIcon,
  zeroSlotsIcon,
  christmasHat
};
