import React from "react";
import { useSelector } from "react-redux";

import { OpenExpertProfileParams } from "@arbolus-technologies/features/projects";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { useTranslation } from "react-i18next";
import { SlidePanel } from "../SlidePanel/SlidePanel";
import { ReferralsTableExpertProfile } from "./components/ReferralsTableExpertProfile/ReferralsTableExpertProfile";

interface ReferralsTableExpertProfileSidePanelProps {
  showProfileOnly?: boolean;
  onReferralRemoval?: (referralId: string) => void;
}

export const ReferralsTableExpertProfileSidePanel: React.FC<
  ReferralsTableExpertProfileSidePanelProps
> = ({ showProfileOnly = false, onReferralRemoval }) => {
  const { t } = useTranslation("referralsTableExpertProfileSidePanel");

  const panelData = useSelector(
    PanelSelector.panelData<OpenExpertProfileParams | undefined>(
      PanelId.ReferralExpertPanel
    )
  );

  return (
    <SlidePanel
      panelId={PanelId.ReferralExpertPanel}
      width={SIDE_PANEL_SIZE._720}
      title={t("profile")}
      noPadding
    >
      <ReferralsTableExpertProfile
        expertId={panelData?.expertId!}
        projectId={panelData?.projectId!}
        referralId={panelData?.referralId!}
        onReferralRemoval={onReferralRemoval}
        showProfileOnly={showProfileOnly}
      />
    </SlidePanel>
  );
};
