import { Button } from "arbolus-ui-components";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";

import {
  CIQError,
  CanopyService,
  DefaultToasterService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  MixpanelPages,
  PageTracker,
  useArbolusTracking,
  useTimeTracking
} from "@arbolus-technologies/features/common";
import { CanopyParamUrlTypes } from "@arbolus-technologies/models/canopy";
import {
  CanopySummary as CanopySummaryI,
  CanopySummaryStatus
} from "@arbolus-technologies/models/canopy-panels";
import { CANOPY_MOBILE_MENU_ROUTE } from "@arbolus-technologies/routes";
import { CanopyV2Selector } from "@arbolus-technologies/stores/canopy-V2";
import { Header } from "@arbolus-technologies/ui/canopy-panels";
import {
  AISummaryWarning,
  BuildingSummaryCreatingCanopy,
  Loader,
  PrinterWrapper,
  SomethingWentWrongWarning
} from "@arbolus-technologies/ui/components";

import { EmptySummary } from "./EmptySummary/EmptySummary";
import { GeneratedSummary } from "./GeneratedSummary/GeneratedSummary";

import styles from "./CanopySummary.module.scss";

const { COMPLETED, IN_PROGRESS, FAILED } = CanopySummaryStatus;
const checkIsEmail = "isEmail";

interface CanopySummaryProps {
  notificationService?: ToasterService;
}

export const CanopySummary: React.FC<CanopySummaryProps> = ({
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("canopySummary");
  const history = useHistory();
  const { trackUnMount, trackClick } = useArbolusTracking();
  const { startTrackingTime, endTrackingTime } = useTimeTracking(
    MixPanelEventNames.CanopySummaryTimeSpent
  );
  const { canopyId } = useParams<CanopyParamUrlTypes>();
  const contentToPrint = useRef(null);

  const [canopySummary, setCanopySummary] = useState<CanopySummaryI>();
  const [openAll, setOpenAll] = useState<boolean>(true);
  const [printPDF, setPrintPDF] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [generatingSummary, setGeneratingSummary] = useState<boolean>(false);

  const canopyData = useSelector(CanopyV2Selector.canopyData(canopyId));

  const handlePrint = useReactToPrint({
    content: () => contentToPrint?.current,
    onAfterPrint: () => setPrintPDF(false)
  });

  const generateSummary = () => {
    setGeneratingSummary(true);
    setIsLoading(true);
    if (canopyData?.id) {
      CanopyService.postCanopySummary(canopyData?.id).subscribe(
        () => {
          notificationService.showSuccess(t("summaryGenerated"));
          setIsLoading(false);
        },
        ({ message }: ErrorResponse<CIQError>) => {
          notificationService.showError(message);
          setIsLoading(false);
          setGeneratingSummary(false);
        }
      );
    }
  };

  useEffect(() => {
    startTrackingTime();

    return () => {
      endTrackingTime({ canopyId, canopyName: canopyData?.title });
      trackUnMount(MixPanelEventNames.CanopySummary, { canopyId });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canopyData?.title,
    canopyId,
    startTrackingTime,
    endTrackingTime,
    trackUnMount
  ]);

  useEffect(() => {
    if (openAll && printPDF) {
      setTimeout(() => {
        handlePrint();
      }, 1000);
    }
  }, [openAll, printPDF, handlePrint]);

  useEffect(() => {
    setIsLoading(true);

    if (canopyData?.id) {
      CanopyService.getCanopySummary(canopyData?.id).subscribe(
        (response: CanopySummaryI) => {
          setCanopySummary({ ...response });
          setIsLoading(false);
        },
        ({ message }: ErrorResponse<CIQError>) => {
          notificationService.showError(message);
          setIsLoading(false);
        }
      );
    }
  }, [canopyData, notificationService]);

  const hasLessThan2Experts = canopySummary?.numExpertsInCanopyAnswers! < 2;
  const hasSummaryError = canopySummary?.canopySummaryStatus === FAILED;
  const isSummaryGenerating =
    canopySummary?.canopySummaryStatus === IN_PROGRESS;
  const isSummaryCompleted = canopySummary?.canopySummaryStatus === COMPLETED;

  const showGeneratingWarning = isSummaryGenerating || generatingSummary;
  const isEmptySummary = canopySummary?.canopyAnswersSummaries.length === 0;
  const standardSummaryMessage =
    isEmptySummary && !hasLessThan2Experts && !showGeneratingWarning;
  const showReGenerateWarningMessage =
    canopySummary?.isSummaryRequestAllowed &&
    isSummaryCompleted &&
    !generatingSummary;

  const pathname = window.location.href;
  const comingFromEmail = pathname.includes(checkIsEmail);

  return (
    <PageTracker
      page={
        comingFromEmail
          ? MixpanelPages.CanopySummaryFromEmail
          : MixpanelPages.CanopySummary
      }
    >
      <PrinterWrapper ref={contentToPrint}>
        <Header
          customPadding={"42px"}
          title={t("summary")}
          backButton={
            isMobile && canopyData
              ? {
                  onClick: () =>
                    history.push(CANOPY_MOBILE_MENU_ROUTE(canopyData.id)),
                  title: t("back")
                }
              : undefined
          }
          actionButton={
            <div className={styles.hideButtonFromPrint}>
              <Button
                onClick={generateSummary}
                text={t("regenerate")}
                disabled={
                  !canopySummary?.isSummaryRequestAllowed || generatingSummary
                }
                endIcon="refresh"
                type="tertiary"
              />
              {isSummaryCompleted && (
                <Button
                  text={printPDF ? t("preparing") : t("pdfText")}
                  onClick={() => {
                    setOpenAll(true);
                    setPrintPDF(true);
                    trackClick(MixPanelEventNames.CanopySummaryPDF, {
                      canopyId
                    });
                  }}
                  endIcon="download"
                  disabled={printPDF}
                />
              )}
            </div>
          }
        />
        <div className={styles.container}>
          {showReGenerateWarningMessage && (
            <div className={styles.reGenerateWarningContainer}>
              <span className={styles.text}>
                {t("reGenerateWarningMessage")}
              </span>
            </div>
          )}
          {showGeneratingWarning && <BuildingSummaryCreatingCanopy />}
          {(standardSummaryMessage || hasLessThan2Experts) && (
            <AISummaryWarning />
          )}
          {hasSummaryError && !showGeneratingWarning && (
            <SomethingWentWrongWarning />
          )}
          {isEmptySummary && <EmptySummary />}
          {canopyData &&
            canopySummary &&
            !isEmptySummary &&
            !showGeneratingWarning &&
            !hasSummaryError && (
              <GeneratedSummary
                canopyTitle={canopyData?.title}
                summaryData={canopySummary}
                openAll={openAll}
                printPDF={printPDF}
                setIsOpenAll={setOpenAll}
                isGeneratingSummary={generatingSummary}
              />
            )}
          {isLoading && <Loader />}
        </div>
      </PrinterWrapper>
    </PageTracker>
  );
};
