import { ArbolusGrid } from "@arbolus-technologies/ui/components";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import React, { useCallback, useMemo } from "react";
import { SurveyAnswersDataSource } from "../../pages/CustomerSurveysPage/CompanyDataTable/SurveyAnswersDataSource";
import { DEFAULT_COLUMNS } from "./CustomerSurveyAnswerTableColumnDefs";

interface CustomerSurveyAnswerTableProps {
  surveyId: string;
}
export const CustomerSurveyAnswerTable: React.FC<
  CustomerSurveyAnswerTableProps
> = ({ surveyId }) => {
  const dataSource = useMemo(
    () => new SurveyAnswersDataSource(surveyId),
    [surveyId]
  );

  const onGridApiReady = useCallback(
    (event: GridReadyEvent) => {
      const { api } = event;
      api.setGridOption("serverSideDatasource", dataSource);
      api.sizeColumnsToFit();
    },
    [dataSource]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: false,
      resizable: true,
      sortable: false
    }),
    []
  );

  return (
    <ArbolusGrid
      rowModelType="serverSide"
      getRowId={({ data }) => data.id}
      defaultColDef={defaultColDef}
      columnDefs={DEFAULT_COLUMNS}
      onGridReady={onGridApiReady}
      readOnlyEdit
      suppressRowClickSelection
      skipHeaderOnAutoSize
      suppressContextMenu
      suppressMenuHide
      pagination
      paginationAutoPageSize
    />
  );
};
