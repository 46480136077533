import { ICellRendererParams } from "ag-grid-community";
import { Flex } from "antd";
import React from "react";

import { AntDAngleTag } from "@arbolus-technologies/antDComponents";
import { AngleModel } from "@arbolus-technologies/models/common";

interface IAngleData {
  angle: AngleModel;
}

export const AngleCellRenderer: React.FC<
  ICellRendererParams<IAngleData, never, never>
> = ({ data }) =>
  data?.angle ? (
    <Flex align="center" style={{ height: "100%" }}>
      <AntDAngleTag angle={data.angle} />
    </Flex>
  ) : null;
