import React from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

import {
  DO_NOT_CONTACT_STATUS,
  ExpertWorkHistory
} from "@arbolus-technologies/models/common";
import {
  CustomerCompanyInfo,
  DiscoverExpertStats,
  DiscoverFilterOption,
  KEYWORDS_SEARCH_OPTIONS
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import {
  ArbolusAvatar,
  DangerouslySetInnerHTML
} from "@arbolus-technologies/ui/components";

import { DiscoverMatchingFiltersPanelData } from "../../../DiscoverMatchingFiltersPanel/interfaces";
import { ExpertStats } from "./ExpertStats";

import { EXPERT_PROFILE } from "@arbolus-technologies/routes";
import { YELLOW } from "@arbolus-technologies/theme";
import { highlightWords } from "@arbolus-technologies/utils";
import { Badge, Flex, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./DiscoverExpertInfo.module.scss";

interface DiscoverExpertInfoProps {
  expertId: string;
  fullName: string;
  countryName: string | null;
  countryTwoLetterCode: string | null;
  stats: DiscoverExpertStats;
  title: string | null;
  profileImageUrl: string | null;
  lastPublicCompanyDate: string | null;
  projectId: string;
  projectName: string;
  previousProjects: string[];
  isOnboarded: boolean;
  searchMatchColumns: string[];
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  recommendationMatchPercentage: number | null;
  isRecommendedExpert: boolean;
  customers: CustomerCompanyInfo[];
  linkedinUrl?: string;
  workHistories: ExpertWorkHistory[];
  industriesFilter: DiscoverFilterOption[];
}

const { Text } = Typography;

export const DiscoverExpertInfo: React.FC<DiscoverExpertInfoProps> = ({
  expertId,
  fullName,
  countryName,
  countryTwoLetterCode,
  stats,
  title,
  profileImageUrl,
  lastPublicCompanyDate,
  projectId,
  projectName,
  previousProjects,
  isOnboarded,
  searchMatchColumns,
  doNotContactStatus,
  recommendationMatchPercentage,
  isRecommendedExpert,
  customers,
  linkedinUrl,
  workHistories,
  industriesFilter
}) => {
  const { t } = useTranslation("discoverPage");
  const isHeadlineOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.USER_TITLE
    )
  );
  const keywordsList = useSelector(ProjectNxSelector.keywordsList());

  const searchParams = new URLSearchParams({ projectId, projectName });

  const discoverMatchingFiltersPanelData: DiscoverMatchingFiltersPanelData = {
    expertId,
    fullName,
    title,
    countryTwoLetterCode,
    lastPublicCompanyDate,
    profileImageUrl,
    doNotContactStatus,
    projectId,
    searchMatchColumns,
    customers,
    workHistories,
    industriesFilter
  };

  const getHighlightedTitle = (): string => {
    if (workHistories.length > 0) {
      const title =
        workHistories[0]?.title && workHistories[0].title.length > 0
          ? workHistories[0].title
          : t("positionNotAvailable");

      return highlightWords({
        text: title,
        tags: isHeadlineOptionSelected ? keywordsList : [],
        highlightColor: YELLOW,
        boldedWords: false
      });
    }

    return t("positionNotAvailable");
  };

  const composedTitle = () => {
    const firstWorkHistoryPosition = workHistories[0];
    const isMatchingIndustry =
      firstWorkHistoryPosition?.industryId !== null &&
      industriesFilter.some(
        (filter) => filter.value === firstWorkHistoryPosition.industryId
      );

    const highlightedTitle = getHighlightedTitle();

    return (
      <div className={styles.expertTitleComposed} title={highlightedTitle}>
        <DangerouslySetInnerHTML text={highlightedTitle} />
        {workHistories.length > 0 && (
          <>
            {highlightedTitle.length > 0 && (
              <Text
                style={{
                  marginLeft: "4px",
                  marginRight: isMatchingIndustry ? "0" : "4px"
                }}
              >
                ·
              </Text>
            )}
            <Tooltip title={workHistories[0].industryName} placement="right">
              {isMatchingIndustry && (
                <Badge
                  status="success"
                  style={{
                    margin: "0 4px"
                  }}
                />
              )}
              <Text>
                {workHistories[0].companyName ?? t("companyNotAvailable")}
              </Text>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Link
        to={{
          pathname: EXPERT_PROFILE(expertId).pathname,
          search: `?${searchParams.toString()}`
        }}
        target="_blank"
        className={styles.link}
      >
        <div className={styles.expertContainer}>
          <ArbolusAvatar
            firstName={fullName}
            profileImageUrl={profileImageUrl}
            countryTwoLetterCode={countryTwoLetterCode}
            lastPublicCompanyDate={lastPublicCompanyDate}
            withGreenTick={isOnboarded}
            countryName={countryName ?? ""}
            avatarType={isMobile ? "avatarGroupCircleSmall" : "mediumXSCircle"}
            composedTitle={composedTitle()}
          />
        </div>
      </Link>
      <ExpertStats
        stats={stats}
        previousProjects={previousProjects}
        discoverMatchingFiltersPanelData={discoverMatchingFiltersPanelData}
        searchMatchColumns={searchMatchColumns}
        recommendationMatchPercentage={recommendationMatchPercentage}
        isRecommendedExpert={isRecommendedExpert}
        linkedinUrl={linkedinUrl}
      />
    </div>
  );
};
