import { User, UserProfile } from "@arbolus-technologies/api";

export const GET_ADMIN_USER_PROFILE = "CACHE_GET_ADMIN_USER_PROFILE";
export const UPDATE_ADMIN_USER_PROFILE = "CACHE_UPDATE_ADMIN_USER_PROFILE";

export interface GetAdminUserProfileAction {
  type: typeof GET_ADMIN_USER_PROFILE;
  payload: {
    userProfile: UserProfile;
  };
}

export interface UpdateAdminUserProfileAction {
  type: typeof UPDATE_ADMIN_USER_PROFILE;
  payload: {
    user: User;
  };
}
