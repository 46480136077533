import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DiscoverFilterOption,
  FILTERS_SEARCH_MATCHES,
  KEYWORDS_SEARCH_OPTIONS,
  MatchedCompaniesWithIndustry
} from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { DangerouslySetInnerHTML } from "@arbolus-technologies/ui/components";

import { ExpertWorkHistory } from "@arbolus-technologies/models/common";
import styles from "./FilterMatch.module.scss";

interface FilterMatchProps {
  searchMatchColumns: string[];
  screeningQuestionsLength: number;
  customersLength: number;
  projectNotesLength: number;
  keywordsList: string[];
  industriesFilter: DiscoverFilterOption[];
  workHistories: ExpertWorkHistory[];
}

export const FilterMatch: React.FC<FilterMatchProps> = ({
  projectNotesLength,
  screeningQuestionsLength,
  customersLength,
  searchMatchColumns,
  keywordsList,
  industriesFilter,
  workHistories
}) => {
  const { t } = useTranslation("discoverMatchingFiltersPanel");

  const hasKeywords = keywordsList.length > 0;

  const isProjectNotesOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.PROJECTS_NOTES
    )
  );
  const isScreeningAnswersOptionSelected = useSelector(
    ProjectNxSelector.isKeywordSearchOptionSelected(
      KEYWORDS_SEARCH_OPTIONS.PROJECTS_ANSWERS
    )
  );

  const {
    FILTER_PROJECTS,
    FILTER_COMPANIES,
    FILTER_WORK_HISTORY_LOCATIONS,
    FILTER_EXPERT_CURRENT_LOCATIONS,
    FILTER_PROJECTS_ANSWERS,
    FILTER_PROJECTS_NOTES,
    FILTER_USER_TITLE,
    FILTER_WORK_HISTORIES_COMPANY,
    FILTER_WORK_HISTORIES_TITLE,
    FILTER_SEARCH_TERM,
    FILTER_CUSTOMERS,
    FILTER_INDUSTRIES
  } = FILTERS_SEARCH_MATCHES;

  const filterTranslations = {
    [FILTER_SEARCH_TERM]: t("filterSearchTerm"),
    [FILTER_PROJECTS]: t("filterProjects"),
    [FILTER_COMPANIES]: t("filterCompanies"),
    [FILTER_WORK_HISTORY_LOCATIONS]: t("filterWorkHistoryLocations"),
    [FILTER_EXPERT_CURRENT_LOCATIONS]: t("filterExpertCurrentLocation"),
    [FILTER_PROJECTS_ANSWERS]: t("filterScreeningAnswers"),
    [FILTER_PROJECTS_NOTES]: t("filterProjectNotes"),
    [FILTER_USER_TITLE]: t("filterHeadline"),
    [FILTER_WORK_HISTORIES_COMPANY]: t("filterCompanyName"),
    [FILTER_WORK_HISTORIES_TITLE]: t("filterWorkHistoryJobTitle"),
    [FILTER_CUSTOMERS]: "",
    [FILTER_INDUSTRIES]: ""
  };

  const companiesWithMatchedIndustries = industriesFilter.reduce(
    (result, industry) => {
      const matchedCompanies = workHistories.reduce<string[]>(
        (companies, history) => {
          if (history.industryId === industry.value && history.companyName) {
            companies.push(history.companyName);
          }
          return companies;
        },
        []
      );

      if (matchedCompanies.length > 0) {
        result.push({ industry: industry.label, companies: matchedCompanies });
      }
      return result;
    },
    [] as MatchedCompaniesWithIndustry[]
  );

  const buildFiltersText = (): string => {
    const filterTextArray = [];
    if (projectNotesLength > 0 && isProjectNotesOptionSelected && hasKeywords) {
      filterTextArray.push(
        t("projectNotesFilter", {
          count: projectNotesLength
        })
      );
    }
    if (
      screeningQuestionsLength > 0 &&
      isScreeningAnswersOptionSelected &&
      hasKeywords
    ) {
      filterTextArray.push(
        t("screeningAnswersFilter", {
          count: screeningQuestionsLength
        })
      );
    }
    if (customersLength > 0) {
      filterTextArray.push(
        t("customersFilter", {
          count: customersLength
        })
      );
    }
    companiesWithMatchedIndustries.forEach(({ industry, companies }) => {
      filterTextArray.push(
        t("industryRelatedTo", {
          industry,
          companies: companies.join(", ")
        })
      );
    });

    searchMatchColumns.forEach((searchMatch) => {
      if (
        searchMatch !== FILTER_PROJECTS_ANSWERS &&
        searchMatch !== FILTER_PROJECTS_NOTES &&
        searchMatch !== FILTER_CUSTOMERS &&
        searchMatch !== FILTER_INDUSTRIES
      ) {
        filterTextArray.push(
          filterTranslations[searchMatch as FILTERS_SEARCH_MATCHES]
        );
      }
    });
    if (filterTextArray.length === 0) {
      return t("noMatchesFound");
    }
    return filterTextArray.join(", ");
  };

  return (
    <div className={styles.filterMatchContainer}>
      <h2 className={styles.filterMatchTitle}>{t("filterMatch")}</h2>
      <DangerouslySetInnerHTML text={buildFiltersText()} />
    </div>
  );
};
