import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CacheSelector } from "@arbolus-technologies/stores/cache";

interface TimezoneSelectorProps {
  selectedTimezone?: string;
  onChange: (selectedTimezone: string) => void;
}

export const TimezoneSelector: React.FC<TimezoneSelectorProps> = ({
  selectedTimezone,
  onChange
}) => {
  const { t } = useTranslation("timezoneSelector");

  const timezones = useSelector(CacheSelector.timezones());

  return (
    <Select
      showSearch
      optionFilterProp="label"
      options={timezones}
      onChange={onChange}
      placeholder={t("placeholder")}
      value={selectedTimezone}
    />
  );
};
