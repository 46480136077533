import { Field, Form, FormikHelpers } from "formik";
import queryString from "query-string";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { FormGroup, InputGroup, Label } from "reactstrap";
import { Dispatch } from "redux";
import { Subscription, zip } from "rxjs";

import { ToasterService } from "@arbolus-technologies/api";
import {
  AuthPageBase,
  AuthPageHeader,
  MixPanelActions,
  MixPanelEventNames,
  MixpanelPages,
  addEventInitialization,
  trackEvent,
  trackPageView
} from "@arbolus-technologies/features/common";
import { APP_TRACKING_ROUTES } from "@arbolus-technologies/models/common";
import { PublicRoutes } from "@arbolus-technologies/routes";

import {
  AppConstants,
  AuthConstants,
  PublicRouteConstants
} from "../../../../constants";
import { CIQError, ErrorResponse } from "../../../../models/api";
import { FederatedLoginUrlRequest } from "../../../../models/auth";
import { MetaService, UserService } from "../../../../services";
import { AppAction } from "../../../../store/actions";
import { CIQFormInput } from "../../../app/components";
import CustomPasswordInput from "../../../app/components/CustomPasswordInput";
import { Alert, AsyncAwareSubmitButton } from "../../components";
import { LoginStoreActions } from "../../store";
import AutoLogin from "./AutoLogin";
import { LoginFormSection, LoginFormValues } from "./LoginFormSection";

const notification = new ToasterService();

interface LoginPageProps extends WithTranslation, RouteComponentProps {
  authenticateUserSuccess: (userId: string, roles: string[]) => void;
  federatedLoginUrls: (urlRequest: FederatedLoginUrlRequest) => Promise<string>;
}

interface LoginPageState {
  isLoginLoading: boolean;
  isFederateReady: boolean;
  federatedLoginUrls: Map<string, string>;
  loginError?: ErrorResponse<CIQError>;
  from?: string;
}

type LoginPageIntersectProps = LoginPageProps & WithTranslation;

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageIntersectProps) {
    super(props);

    const { from } = (props.history.location?.state || {}) as {
      from: string;
    };

    this.state = {
      isFederateReady: false,
      isLoginLoading: false,
      federatedLoginUrls: new Map(),
      from
    };
  }

  componentDidMount(): void {
    const { t, history } = this.props;
    const { from } = this.state;
    document.title = t("login");

    const queryParams = queryString.parse(window.location.search);
    const surveyExpertId = queryString.parse(
      queryParams.search as string
    ).surveyExpertId;

    const fromRegisterOrSurvey =
      from === APP_TRACKING_ROUTES.REGISTER ||
      from === APP_TRACKING_ROUTES.SURVEY_QUALIFIED ||
      from === APP_TRACKING_ROUTES.SURVEY_NOT_QUALIFIED ||
      from === APP_TRACKING_ROUTES.SURVEY_FEEDBACK;

    // Redirect automatically survey experts from Login to Register
    if (surveyExpertId && !fromRegisterOrSurvey) {
      const paymentMethodParams = queryString.stringify(queryParams);
      history.replace(`${PublicRoutes.REGISTER}?${paymentMethodParams}`);
    }

    this.federateUrlFetchSubscription = zip(
      MetaService.federatedLoginUrl({
        authenticationProvider: AuthConstants.FEDERATED_AUTH_PROVIDER.GOOGLE
      }),
      MetaService.federatedLoginUrl({
        authenticationProvider: AuthConstants.FEDERATED_AUTH_PROVIDER.MICROSOFT
      }),
      MetaService.federatedLoginUrl({
        authenticationProvider: AuthConstants.FEDERATED_AUTH_PROVIDER.APPLE
      })
    ).subscribe(
      ([google, microsoft, apple]) => {
        const { federatedLoginUrls } = this.state;
        federatedLoginUrls.set(
          AuthConstants.FEDERATED_AUTH_PROVIDER.GOOGLE,
          google.url
        );
        federatedLoginUrls.set(
          AuthConstants.FEDERATED_AUTH_PROVIDER.MICROSOFT,
          microsoft.url
        );
        federatedLoginUrls.set(
          AuthConstants.FEDERATED_AUTH_PROVIDER.APPLE,
          apple.url
        );
        this.setState({
          federatedLoginUrls,
          isFederateReady: true
        });
      },
      (error: ErrorResponse<CIQError>) => notification.showError(error.message)
    );

    trackPageView({ page: MixpanelPages.Login, from });
  }

  componentWillUnmount(): void {
    this.federateUrlFetchSubscription?.unsubscribe();
    this.authenticateUserSubscription?.unsubscribe();
  }

  private authenticateUserSubscription?: Subscription;

  private federateUrlFetchSubscription?: Subscription;

  private cookiesEnabled: boolean = navigator.cookieEnabled;

  handleLoginClicked = (
    { email, password, remember }: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>
  ): void => {
    const { from } = this.state;
    // Set time taken for logging in seconds
    addEventInitialization(MixPanelEventNames.ExpertUserLoggedIn);
    trackEvent(MixPanelEventNames.ExpertUserLoginButton, {
      action: MixPanelActions.Clicked,
      from
    });

    this.setState({ isLoginLoading: true, loginError: undefined });
    const { authenticateUserSuccess } = this.props;

    this.authenticateUserSubscription = UserService.authenticateUser({
      email,
      password,
      remember
    }).subscribe(
      ({ userId, userRoles }) => {
        this.setState(
          {
            isLoginLoading: false
          },
          () => {
            localStorage.setItem(
              AppConstants.LOCALSTORAGE.LAST_LOGIN,
              new Date().getTime().toString()
            );

            authenticateUserSuccess(userId, userRoles);
            trackEvent(MixPanelEventNames.ExpertUserLoggedIn, { userId, from });
          }
        );
      },
      (loginError: ErrorResponse<CIQError>) => {
        this.setState({
          loginError,
          isLoginLoading: false
        });

        formikHelpers.setFieldValue("password", "");
        formikHelpers.setFieldTouched("password", false);
      }
    );
  };

  handleFederatedLoginClicked = (provider: string): void => {
    const { federatedLoginUrls } = this.state;
    window.location.assign(federatedLoginUrls.get(provider)!);
  };

  autoLogin = (email: string, password: string) => {
    const formValues = {
      email,
      password,
      remember: false
    };
    const fakeFormik = {
      setFieldValue: () => {},
      setFieldTouched: () => {}
    };
    this.handleLoginClicked(formValues, fakeFormik as never);
  };

  renderLoginForm = (): JSX.Element => {
    const { isLoginLoading, loginError, from } = this.state;
    const { t } = this.props;
    const currentQueryString = window.location.search;

    return (
      <>
        <Form>
          {loginError && <Alert feedback={loginError} isSuccess={false} />}

          <div>
            <Field
              name="email"
              placeholder={t("email")}
              component={CIQFormInput}
            />
          </div>
          <InputGroup className="append">
            <Field
              name="password"
              placeholder={t("password")}
              type="password"
              component={CustomPasswordInput}
            />
          </InputGroup>

          <div className="additional-options">
            <FormGroup className="custom-checkbox">
              <Label check>
                <Field
                  name="remember"
                  type="checkbox"
                  component={CIQFormInput}
                />
                {t("remember")}
                <span className="checkmark" />
              </Label>
            </FormGroup>

            <Link
              className="forgot-password-text"
              to={{
                pathname: PublicRouteConstants.FORGOT_PASSWORD,
                search: currentQueryString,
                state: { from }
              }}
            >
              {t("forgot")}
            </Link>
          </div>

          <div className="login-page-footer">
            <div>
              <Trans
                ns="login"
                i18nKey="newMember"
                components={[
                  <Link
                    key="0"
                    to={{
                      pathname: PublicRouteConstants.REGISTER,
                      search: currentQueryString,
                      state: { from }
                    }}
                  />
                ]}
              />
            </div>
            <FormGroup>
              <AsyncAwareSubmitButton
                isLoading={isLoginLoading}
                text={t("login")}
              />
            </FormGroup>
          </div>
        </Form>

        <AutoLogin login={this.autoLogin} />
      </>
    );
  };

  renderCookiesRequired = (): JSX.Element => (
    <>
      <AuthPageHeader title={this.props.t("cookiesTitle")} />
      <h3>{this.props.t("cookiesMsg")}</h3>
    </>
  );

  render(): JSX.Element {
    return (
      <AuthPageBase>
        {this.cookiesEnabled ? (
          <LoginFormSection
            isFederateReady={this.state.isFederateReady}
            handleLoginClicked={this.handleLoginClicked}
            renderLoginForm={this.renderLoginForm}
            handleFederatedLoginClicked={this.handleFederatedLoginClicked}
          />
        ) : (
          this.renderCookiesRequired()
        )}
      </AuthPageBase>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  authenticateUserSuccess: (userId: string, roles: string[]): AppAction =>
    dispatch(LoginStoreActions.authenticateUserSuccess(userId, roles))
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation("login")(LoginPage));
