import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import { ProviderIndustry } from "@arbolus-technologies/api";
import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import {
  DiscoverFilterOption,
  DiscoverFilterType,
  FilterTimeSelectorOptions
} from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  ProjectNxSelector,
  ProjectNxStoreActions
} from "@arbolus-technologies/stores/project";
import { utilService } from "@arbolus-technologies/utils";

import { useDiscoverFilters } from "./useDiscoverFilters";

export interface UseDiscoverIndustryFilter {
  handleSelectOption: (industryIds: string[]) => void;
  urlChangeOnIndustryFilterUpdate: (industries: DiscoverFilterOption[]) => void;
  handleClearFilter: () => void;
  updateIndustriesFilterFromUrl: () => DiscoverFilterOption[];
  providerIndustries: ProviderIndustry[];
  handleSetIndustryFilterPeriod: (filterTimeSelectorOption: string) => void;
}

export const useDiscoverIndustryFilter = (): UseDiscoverIndustryFilter => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { trackFiltered } = useArbolusTracking();

  const parameters = queryString.parse(location.search);
  const { industries }: { industries?: string } = parameters;

  const { setSelectedOptions, clearFilter } = useDiscoverFilters({
    filterType: DiscoverFilterType.Industries
  });

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const projectName = useSelector(ProjectNxSelector.projectName());
  const projectClientName = useSelector(ProjectNxSelector.projectClientName());
  const providerIndustries = useSelector(
    CacheSelector.providerIndustriesSelector()
  );

  const handleSelectOption = (industryIds: string[]) => {
    const result = getIndustriesByIds(providerIndustries, industryIds);
    setSelectedOptions(result);
    urlChangeOnIndustryFilterUpdate(result);
    trackFiltered(MixPanelEventNames.InternalSearchIndustrySearch, {
      Project: projectName,
      Client: projectClientName
    });
  };

  const getIndustriesByIds = (
    industries: ProviderIndustry[],
    ids: string[]
  ) => {
    return industries.reduce((result: DiscoverFilterOption[], item) => {
      // Check if the current item matches an ID and add it to the result
      if (ids.includes(item.value)) {
        result.push({ value: item.value, label: item.title });
      }

      // If the item has children, check them recursively and concatenate the result
      if (item.children) {
        result = result.concat(getIndustriesByIds(item.children, ids));
      }

      return result;
    }, []);
  };

  const handleClearFilter = () => {
    clearFilter();
    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      delete nonEmptyParams["industries"];

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const updateIndustriesFilterFromUrl = () => {
    const selectedIndustries = industries ? industries.split(",") : [];

    if (!selectedIndustries.length) {
      return [];
    }

    return getIndustriesByIds(providerIndustries, selectedIndustries);
  };

  const urlChangeOnIndustryFilterUpdate = (options: DiscoverFilterOption[]) => {
    if (isAdmin) {
      const selectedIndustries = options
        .map((option) => option.value)
        .join(",");

      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (selectedIndustries) {
        nonEmptyParams["industries"] = selectedIndustries;
      } else {
        delete nonEmptyParams["industries"];
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  const handleSetIndustryFilterPeriod = (filterTimeSelectorOption: string) => {
    dispatch(
      ProjectNxStoreActions.setIndustryFilterPeriod(
        filterTimeSelectorOption as FilterTimeSelectorOptions
      )
    );

    if (isAdmin) {
      const nonEmptyParams = utilService.getNonEmptyQueryString(parameters);
      if (filterTimeSelectorOption === FilterTimeSelectorOptions.All) {
        delete nonEmptyParams["industryState"];
      } else {
        nonEmptyParams["industryState"] = filterTimeSelectorOption;
      }

      const params = new URLSearchParams(nonEmptyParams);
      history.replace({
        pathname: location.pathname,
        search: params.toString()
      });
    }
  };

  return {
    handleSelectOption,
    handleClearFilter,
    providerIndustries,
    updateIndustriesFilterFromUrl,
    urlChangeOnIndustryFilterUpdate,
    handleSetIndustryFilterPeriod
  };
};
