import {
  DiscoverFilterType,
  ExpertDiscoverFilters,
  FilterTimeSelectorOptions,
  KEYWORDS_SEARCH_OPTIONS
} from "@arbolus-technologies/models/project";

import {
  CLEAR_ALL_DISCOVER_FILTERS,
  CLEAR_DISCOVER_FILTER,
  CLEAR_KEYWORDS_SEARCH_OPTIONS,
  ClearAllDiscoverFiltersAction,
  ClearDiscoverFilterAction,
  SET_ALL_DISCOVER_FILTERS,
  SET_COMPANY_FILTER_PERIOD,
  SET_COMPANY_IN_PAST_MONTHS,
  SET_DISCOVER_EXPERT_ALL_FILTERS,
  SET_DISCOVER_EXPERT_CURRENT_PAGE,
  SET_DISCOVER_EXPERT_LIST,
  SET_DISCOVER_EXPERT_LIST_LOADING,
  SET_DISCOVER_EXPERT_SEARCH_TERM,
  SET_DISCOVER_FILTER,
  SET_DISCOVER_FILTER_PRE_SELECTIONS,
  SET_FILTER_QUERY,
  SET_INDUSTRY_FILTER_PERIOD,
  SET_KEYWORDS_SEARCH_OPTIONS,
  SET_WORK_HISTORY_LOCATION_FILTER_PERIOD,
  SetAllDiscoverFiltersAction,
  SetCompanyFilterPeriodAction,
  SetCompanyInPastMonthsAction,
  SetDiscoverExpertAllFiltersAction,
  SetDiscoverExpertCurrentPageAction,
  SetDiscoverExpertListAction,
  SetDiscoverExpertListLoadingAction,
  SetDiscoverExpertSearchTermAction,
  SetDiscoverFilterAction,
  SetFilterQueryAction,
  SetIndustryFilterPeriodAction,
  SetKeywordsSearchOptionsAction,
  SetWorkHistoryLocationFilterPeriodAction,
  TOGGLE_DNC_EXPERTS,
  TOGGLE_RECOMMENDATIONS,
  ToggleDncExpertsAction,
  ToggleRecommendationsAction
} from "../actions/ExpertDiscover/ExpertDiscoverActionTypes";
import { ProjectNxReducerState } from "../models/definitions";

export const ExpertDiscoverHandlers = {
  [SET_DISCOVER_FILTER]: (
    state: ProjectNxReducerState,
    action: SetDiscoverFilterAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      filters: {
        ...state.expertDiscover.filters,
        [action.payload.filterType]: action.payload.filters
      },
      isLoading: true
    }
  }),

  [SET_DISCOVER_FILTER_PRE_SELECTIONS]: (
    state: ProjectNxReducerState,
    action: SetDiscoverFilterAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      filtersPreSelection: {
        ...state.expertDiscover.filtersPreSelection,
        [action.payload.filterType]: action.payload.filters
      }
    }
  }),

  [SET_ALL_DISCOVER_FILTERS]: (
    state: ProjectNxReducerState,
    action: SetAllDiscoverFiltersAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      filters: action.payload.filters
    }
  }),

  [CLEAR_DISCOVER_FILTER]: (
    state: ProjectNxReducerState,
    action: ClearDiscoverFilterAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      filters: {
        ...state.expertDiscover.filters,
        [action.payload.filterType]: []
      },
      filtersPreSelection: {
        ...state.expertDiscover.filtersPreSelection,
        [action.payload.filterType]: []
      },
      companyFilterPeriod:
        action.payload.filterType === DiscoverFilterType.Company
          ? FilterTimeSelectorOptions.All
          : state.expertDiscover.companyFilterPeriod,
      companyInPastMonths: 0,
      workHistoryLocationFilterPeriod:
        action.payload.filterType === DiscoverFilterType.Work_History_Locations
          ? FilterTimeSelectorOptions.All
          : state.expertDiscover.workHistoryLocationFilterPeriod,
      industryFilterPeriod:
        action.payload.filterType === DiscoverFilterType.Industries
          ? FilterTimeSelectorOptions.All
          : state.expertDiscover.industryFilterPeriod,
      keywordsFields:
        action.payload.filterType === DiscoverFilterType.Keywords
          ? Object.values(KEYWORDS_SEARCH_OPTIONS)
          : state.expertDiscover.keywordsFields,
      filtersQuery: {
        ...state.expertDiscover.filtersQuery,
        [action.payload.filterType]: ""
      },
      isLoading: true
    }
  }),

  [CLEAR_ALL_DISCOVER_FILTERS]: (
    state: ProjectNxReducerState,
    action: ClearAllDiscoverFiltersAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      companyFilterPeriod: FilterTimeSelectorOptions.All,
      companyInPastMonths: 0,
      workHistoryLocationFilterPeriod: FilterTimeSelectorOptions.All,
      industryFilterPeriod: FilterTimeSelectorOptions.All,
      keywordsFields: Object.values(KEYWORDS_SEARCH_OPTIONS),
      filters: Object.fromEntries(
        Object.values(DiscoverFilterType).map((filterType) => [filterType, []])
      ) as unknown as ExpertDiscoverFilters,
      filtersPreSelection: Object.fromEntries(
        Object.values(DiscoverFilterType).map((filterType) => [filterType, []])
      ) as unknown as ExpertDiscoverFilters,
      filtersQuery: {
        expertLocations: "",
        workHistoryLocations: "",
        company: "",
        projects: "",
        keyword: "",
        customers: ""
      },
      displayRecommendations: action.payload.displayRecommendations,
      hideDncExperts: true,
      isLoading: true
    }
  }),

  [CLEAR_KEYWORDS_SEARCH_OPTIONS]: (
    state: ProjectNxReducerState
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      keywordsFields: Object.values(KEYWORDS_SEARCH_OPTIONS)
    }
  }),

  [SET_COMPANY_FILTER_PERIOD]: (
    state: ProjectNxReducerState,
    action: SetCompanyFilterPeriodAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      companyFilterPeriod: action.payload.companyFilterPeriod,
      isLoading: true
    }
  }),

  [SET_COMPANY_IN_PAST_MONTHS]: (
    state: ProjectNxReducerState,
    action: SetCompanyInPastMonthsAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      companyInPastMonths: action.payload.companyInPastMonths,
      isLoading: true
    }
  }),

  [SET_WORK_HISTORY_LOCATION_FILTER_PERIOD]: (
    state: ProjectNxReducerState,
    action: SetWorkHistoryLocationFilterPeriodAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      workHistoryLocationFilterPeriod:
        action.payload.workHistoryLocationFilterPeriod,
      isLoading: true
    }
  }),

  [SET_INDUSTRY_FILTER_PERIOD]: (
    state: ProjectNxReducerState,
    action: SetIndustryFilterPeriodAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      industryFilterPeriod: action.payload.industryFilterPeriod,
      isLoading: true
    }
  }),

  [SET_KEYWORDS_SEARCH_OPTIONS]: (
    state: ProjectNxReducerState,
    action: SetKeywordsSearchOptionsAction
  ): ProjectNxReducerState => {
    const { keywordsSearchOptions } = action.payload;

    return {
      ...state,
      expertDiscover: {
        ...state.expertDiscover,
        keywordsFields: keywordsSearchOptions,
        isLoading: true
      }
    };
  },

  [SET_FILTER_QUERY]: (
    state: ProjectNxReducerState,
    action: SetFilterQueryAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      filtersQuery: {
        ...state.expertDiscover.filtersQuery,
        [action.payload.filterType]: action.payload.query
      }
    }
  }),

  [TOGGLE_RECOMMENDATIONS]: (
    state: ProjectNxReducerState,
    action: ToggleRecommendationsAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      displayRecommendations: action.payload.displayRecommendations
    }
  }),

  [TOGGLE_DNC_EXPERTS]: (
    state: ProjectNxReducerState,
    action: ToggleDncExpertsAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      hideDncExperts: action.payload.hideDncExperts,
      isLoading: true
    }
  }),

  [SET_DISCOVER_EXPERT_SEARCH_TERM]: (
    state: ProjectNxReducerState,
    action: SetDiscoverExpertSearchTermAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      searchTerm: action.payload.searchTerm,
      isLoading: true
    }
  }),

  [SET_DISCOVER_EXPERT_ALL_FILTERS]: (
    state: ProjectNxReducerState,
    action: SetDiscoverExpertAllFiltersAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      ...action.payload.expertDiscover,
      isLoading: true
    }
  }),

  [SET_DISCOVER_EXPERT_LIST]: (
    state: ProjectNxReducerState,
    action: SetDiscoverExpertListAction
  ): ProjectNxReducerState => {
    let newCurrentPage;

    if (action.payload.expertsList === null) {
      newCurrentPage = 0;
    } else if (action.payload.expertsList.items.length === 0) {
      newCurrentPage = 1;
    } else {
      newCurrentPage = state.expertDiscover.currentPage;
    }

    return {
      ...state,
      expertDiscover: {
        ...state.expertDiscover,
        expertsList: action.payload.expertsList,
        currentPage: newCurrentPage
      }
    };
  },

  [SET_DISCOVER_EXPERT_CURRENT_PAGE]: (
    state: ProjectNxReducerState,
    action: SetDiscoverExpertCurrentPageAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      currentPage: action.payload.currentPage,
      pageSize: action.payload.pageSize,
      isLoading: true
    }
  }),

  [SET_DISCOVER_EXPERT_LIST_LOADING]: (
    state: ProjectNxReducerState,
    action: SetDiscoverExpertListLoadingAction
  ): ProjectNxReducerState => ({
    ...state,
    expertDiscover: {
      ...state.expertDiscover,
      isLoading: action.payload.isLoading
    }
  })
};
