/* spell-checker: disable */

export enum ExpertAccountCreationFlow {
  ExpertUserRegistrationCreateAccountStart = "expertuser_registration_click_createaccount_start",
  ExpertUserRegistrationAcceptTerms = "expertuser_registration_click_acceptterms",
  ExpertUserRegistrationCompleteUserForm = "expertuser_registration_click_completeuserform",
  ExpertUserRegistrationAccountCreated = "expertuser_registration_event_accountcreated",
  ExpertUserRegistrationAccountCreationFailed = "expertuser_registration_event_accountcreationFailed",
  ExpertUserRegistrationTerms = "expertuser_registration_terms",
  ExpertUserRegistrationUserForm = "expertuser_registration_userform"
}

export enum ExperLoginFlow {
  ExpertUserLoginButton = "expertuser_login_click_login",
  ExpertUserLoggedIn = "expertuser_login_click_loggedin"
}
