import { OutputSelector, createSelector } from "reselect";

import {
  Country,
  CurrentUser,
  Industry,
  ProviderIndustry,
  Region,
  UserProfile
} from "@arbolus-technologies/api";
import { SelectOption, UserRole } from "@arbolus-technologies/models/common";

import { generateActiveUserRoleF } from "./UserRolesForMenu";
import { CacheAppState, CacheReducerState } from "./models/definitions";
import { hasNotifications } from "./selectors/CacheCheckIfHasNotifications";
import { metadataCurrencies } from "./selectors/CacheCurrenciesSelector";
import {
  currentAdminSpecialPermissionsSelector,
  hasManageCompaniesPermissionSelector,
  hasManageCompliancePermissionSelector
} from "./selectors/CacheCurrentAdminSpecialPermissionsSelector";
import { isAdmin } from "./selectors/CacheIsAdminSelector";
import { loggedInUserClientId } from "./selectors/CacheLoggedInUserClientIdSelector";
import { loggedInUserExpertId } from "./selectors/CacheLoggedInUserExpertIdSelector";
import { loggedInUser } from "./selectors/CacheLoggedInUserSelector";
import {
  appGuessCurrentTimeZoneSelector,
  appTimezoneSelectOptionMapSelector,
  defaultTimezones,
  timezones
} from "./selectors/CacheTimezonesSelector";
import { userAdminProfile } from "./selectors/CacheUserAdminProfileSelector";

export const CacheStateSelector = (state: CacheAppState): CacheReducerState =>
  state.cache;

export const industriesLevel0 = (): OutputSelector<
  CacheAppState,
  SelectOption[],
  (res: CacheReducerState) => SelectOption[]
> =>
  createSelector<CacheAppState, CacheReducerState, SelectOption[]>(
    CacheStateSelector,
    (cacheReducerState) => {
      const industriesOptions: SelectOption[] =
        cacheReducerState.industriesLevel0.map(({ id, name }) => ({
          id,
          label: name,
          value: id
        }));
      return industriesOptions;
    }
  );

export const industryDefaultValue = (): OutputSelector<
  CacheAppState,
  Industry,
  (res: CacheReducerState) => Industry | undefined
> =>
  createSelector<CacheAppState, CacheReducerState, Industry>(
    CacheStateSelector,
    (cacheReducerState) => {
      const industryDefaultValue = cacheReducerState.industriesLevel0.find(
        (industry) => industry.naicsId === 81
      ) as Industry;

      return industryDefaultValue;
    }
  );

export const countriesOptions = (): OutputSelector<
  CacheAppState,
  SelectOption[],
  (res: CacheReducerState) => SelectOption[]
> =>
  createSelector<CacheAppState, CacheReducerState, SelectOption[]>(
    CacheStateSelector,
    (cacheReducerState) => {
      const countriesOptions: SelectOption[] = cacheReducerState.countries.map(
        ({ id, name }) => ({
          label: name,
          value: id
        })
      );
      return countriesOptions;
    }
  );

export const metadataCountries = (): OutputSelector<
  CacheAppState,
  Country[],
  (res: CacheReducerState) => Country[]
> =>
  createSelector<CacheAppState, CacheReducerState, Country[]>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.countries
  );

export const regions = (): OutputSelector<
  CacheAppState,
  Region[],
  (res: CacheReducerState) => Region[]
> =>
  createSelector<CacheAppState, CacheReducerState, Region[]>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.regions
  );

export const countryDefaultValue = (): OutputSelector<
  CacheAppState,
  SelectOption,
  (res: CacheReducerState) => SelectOption
> =>
  createSelector<CacheAppState, CacheReducerState, SelectOption>(
    CacheStateSelector,
    (cacheReducerState) => {
      const countryDefaultValue: SelectOption = {
        label: "",
        value: "",
        disabled: true
      };

      return countryDefaultValue;
    }
  );

export const generateActiveUserRole = (): OutputSelector<
  CacheAppState,
  UserRole,
  (res: CacheReducerState) => UserRole
> =>
  createSelector<CacheAppState, CacheReducerState, UserRole>(
    CacheStateSelector,
    (cacheReducerState) => {
      if (Object.keys(cacheReducerState.userProfile).length > 0) {
        return generateActiveUserRoleF(cacheReducerState.userProfile.userRoles);
      }
      if (Object.keys(cacheReducerState.loggedInUser).length > 0) {
        return generateActiveUserRoleF(
          cacheReducerState.loggedInUser.userRoles
        );
      }

      return UserRole.guest;
    }
  );

export const currentUserSelector = (): OutputSelector<
  CacheAppState,
  CurrentUser,
  (res: CacheReducerState) => CurrentUser
> =>
  createSelector(CacheStateSelector, (cacheReducerState) => {
    const {
      id,
      firstName = "",
      lastName = "",
      email,
      title,
      client,
      expertId,
      userRoles
    } = cacheReducerState.loggedInUser;

    // These properties are logged to Mixpanel
    const payload: CurrentUser = {
      loggedInId: id,
      accountType: UserRole.client,
      clientId: client?.id,
      firstName,
      lastName,
      email,
      title
    };

    if (userRoles?.includes(UserRole.expert)) {
      payload.loggedInId = expertId!;
      payload.accountType = UserRole.expert;
    }

    return payload;
  });

export const isCurrentUserClientSelector = (): OutputSelector<
  CacheAppState,
  boolean,
  (res: CacheReducerState) => boolean
> =>
  createSelector(
    CacheStateSelector,
    (cacheReducerState) => !!cacheReducerState.loggedInUser.client?.id
  );

export const isCurrentUserExpertSelector = (): OutputSelector<
  CacheAppState,
  boolean,
  (res: CacheReducerState) => boolean
> =>
  createSelector(
    CacheStateSelector,
    (cacheReducerState) =>
      typeof cacheReducerState.loggedInUser?.expertId === "string" &&
      cacheReducerState.loggedInUser.expertId.length > 0
  );

export const userProfile = (): OutputSelector<
  CacheAppState,
  UserProfile,
  (res: CacheReducerState) => UserProfile
> =>
  createSelector<CacheAppState, CacheReducerState, UserProfile>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.userProfile
  );

export const providerIndustriesSelector = (): OutputSelector<
  CacheAppState,
  ProviderIndustry[],
  (res: CacheReducerState) => ProviderIndustry[]
> =>
  createSelector<CacheAppState, CacheReducerState, ProviderIndustry[]>(
    CacheStateSelector,
    (cacheReducerState) => cacheReducerState.providerIndustries
  );

export const CacheSelector = {
  CacheStateSelector,
  timezones,
  defaultTimezones,
  loggedInUser,
  loggedInUserClientId,
  isAdmin,
  userAdminProfile,
  appTimezoneSelectOptionMapSelector,
  appGuessCurrentTimeZoneSelector,
  loggedInUserExpertId,
  metadataCountries,
  metadataCurrencies,
  industriesLevel0,
  industryDefaultValue,
  countriesOptions,
  countryDefaultValue,
  generateActiveUserRole,
  currentUserSelector,
  hasNotifications,
  isCurrentUserClientSelector,
  isCurrentUserExpertSelector,
  currentAdminSpecialPermissionsSelector,
  hasManageCompaniesPermissionSelector,
  hasManageCompliancePermissionSelector,
  userProfile,
  regions,
  providerIndustriesSelector
};
