import { FilterModel } from "ag-grid-community";

export function filterToQueryParams(
  filterModel: FilterModel,
  filterName: string,
  paramName?: string
) {
  const targetFilter = filterModel[filterName];
  const targetParam = paramName ?? filterName;
  if (targetFilter?.conditions) {
    return {
      [targetParam]: targetFilter.conditions.map(
        ({ filter }: { filter: string }) => filter
      )
    };
  } else if (targetFilter?.filter) {
    return { [targetParam]: targetFilter.filter };
  }
  return {};
}

/*
 * Use this when a filter is a multi-choice enum.
 */
export function filterValueArrayToQueryParams(
  filterModel: FilterModel,
  filterName: string,
  paramName?: string
) {
  if (filterModel[filterName]?.values) {
    return { [paramName ?? filterName]: filterModel[filterName].values };
  }
  return {};
}
