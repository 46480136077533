import { Avatar, Flex, Image, Tooltip, Typography } from "antd";
import React, { ReactNode } from "react";

import { AngleModel, DateString } from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS, PALETTE, linkedin } from "@arbolus-technologies/theme";
import { PublicCompanyIcon } from "@arbolus-technologies/ui/components";
import { twoLetterName } from "@arbolus-technologies/utils";

import { AntDAngleTag } from "../AngleTag/AntDAngleTag";
import { AntDIcon } from "../Icon/AntDIcon";

import styles from "./AntDAvatar.module.scss";

const { Text } = Typography;

export interface AntDAvatarProps {
  profileImageUrl?: string;
  firstName?: string;
  lastName?: string;
  onlyAvatar?: boolean;
  size?: number | "large" | "small" | "default";
  title?: string;
  color?: PALETTE;
  isVerified?: boolean;
  lastPublicCompanyExpDate?: DateString | null;
  linkedInProfile?: string;
  countryTwoLetterCode?: string | null;
  countryName?: string;
  angle?: AngleModel;
  icon?: ReactNode;
  mainGap?: number;
}

export const AntDAvatar: React.FC<AntDAvatarProps> = ({
  profileImageUrl,
  firstName,
  lastName,
  title,
  color,
  isVerified = false,
  lastPublicCompanyExpDate,
  linkedInProfile,
  countryTwoLetterCode,
  countryName,
  size = "default",
  angle,
  onlyAvatar,
  icon,
  mainGap = 8
}) => {
  return (
    <Flex gap={mainGap} align="center">
      <div className={styles.img}>
        {profileImageUrl ? (
          <Avatar
            src={profileImageUrl}
            size={size}
            style={{ backgroundColor: color }}
          />
        ) : (
          <Avatar size={size}>{twoLetterName(firstName, lastName)}</Avatar>
        )}
        {icon}
      </div>
      <Flex gap={8} vertical className={styles.details}>
        <Flex vertical>
          <Flex gap={4} align="center">
            {!onlyAvatar && (
              <Text
                ellipsis
                strong={!!title}
                style={{ fontSize: title ? "16px" : "14px", color }}
              >
                {`${firstName} ${lastName}`}
              </Text>
            )}
            {isVerified && (
              <AntDIcon
                name="verified_user"
                fontSize="20px"
                color={ARBOLUS_COLORS.bColorAccentGreenDark}
              />
            )}
            {lastPublicCompanyExpDate && (
              <PublicCompanyIcon
                date={lastPublicCompanyExpDate}
                customFontSize="20px"
              />
            )}
            {countryTwoLetterCode && (
              <span
                className={`flag-icon flag-icon-${countryTwoLetterCode.toLowerCase()}`}
                id="countryFlag"
                title={countryName}
              />
            )}
            {linkedInProfile && (
              <Image
                src={linkedin}
                alt="linkedin"
                className={styles.img}
                width={20}
                onClick={() => window.open(linkedInProfile, "_blank")}
                preview={false}
              />
            )}
          </Flex>
          {title && (
            <Tooltip title={title} placement="topLeft">
              <Text ellipsis>{title}</Text>
            </Tooltip>
          )}
        </Flex>
        {angle && <AntDAngleTag angle={angle} />}
      </Flex>
    </Flex>
  );
};
