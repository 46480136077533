import { Tooltip } from "antd";
import clsx from "clsx";
import React from "react";

import { IconName, icons } from "./IconDictionary";

import styles from "./AntDIcon.module.scss";

export interface AntDIconProps {
  name: IconName;
  fontSize?: string;
  color?: string;
  filled?: boolean;
  cursor?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  tooltip?: string;
  id?: string;
  justifyContent?: string;
  inline?: boolean;
  className?: string;
}

export const AntDIcon: React.FC<AntDIconProps> = ({
  name,
  fontSize = "16px",
  color,
  filled = false,
  cursor = false,
  onClick,
  disabled = false,
  tooltip,
  id,
  justifyContent = "flex-start",
  inline = false,
  className = ""
}) => {
  const IconComponent = icons[name];

  const Component = (
    <div
      onClick={onClick}
      className={clsx(styles.icon, className, {
        [styles.cursor]: !!onClick || cursor,
        [styles.filled]: filled,
        [styles.disabled]: disabled,
        [styles.inline]: inline
      })}
      id={id}
      style={{ color, height: fontSize, justifyContent }}
      data-testid={id}
    >
      <IconComponent fontSize={fontSize} style={{ fontSize: fontSize }} />
    </div>
  );

  return tooltip ? <Tooltip title={tooltip}>{Component}</Tooltip> : Component;
};

export type { IconName };
