import { SURVEY_PROVIDERS } from "@arbolus-technologies/models/survey";
import { FormInstance } from "antd";
import React from "react";

export const tooltipHelper = (form: FormInstance<any>): JSX.Element => {
  const helperContent: Record<SURVEY_PROVIDERS, JSX.Element> = {
    [SURVEY_PROVIDERS.INC_QUERY]: (
      <span>
        Example:
        https://iq-dist-2.com/s/start/en-us/rBwpTa4fR72rqOXLgbaObA/IDuuK0lfRyaZi212zvs9FA
        <span style={{ color: "red" }}>?id=id</span>
      </span>
    ),
    [SURVEY_PROVIDERS.INTELLI_SURVEY]: (
      <span>
        Example: https://s4.intellisurvey.com/pub/arbolusrdtest?pan=327
        <span style={{ color: "red" }}>&trans_id=[%id%]</span>
      </span>
    ),
    [SURVEY_PROVIDERS.ANTARES_ADVISOR_GROUP]: (
      <span>
        Example:
        https://selfserve.decipherinc.com/survey/selfserve/21a7/g1011/250114
        <span style={{ color: "red" }}>?list=1&id=XXXX</span>
      </span>
    ),
    [SURVEY_PROVIDERS.WALR]: (
      <span>
        Example: https://survey.walr.com/eINMzC
        <span style={{ color: "red" }}>?P1=surveyExpertIdValue</span>
      </span>
    ),
    [SURVEY_PROVIDERS.QUALTRICS]: <span>TBD</span>,
    [SURVEY_PROVIDERS.GOOGLE_FORMS]: <span>TBD</span>,
    [SURVEY_PROVIDERS.OTHER]: <span>TBD</span>
  };

  const provider = form.getFieldValue("provider") as SURVEY_PROVIDERS;

  return helperContent[provider];
};
