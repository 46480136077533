import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  Row
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExpertCompany, ExpertCountry } from "@arbolus-technologies/api";
import { EmployedStatus } from "@arbolus-technologies/models/common";
import {
  ExpertWorkConstraints,
  INPUT_DEBOUNCE,
  Loader,
  UserConstraints
} from "@arbolus-technologies/ui/components";
import { getLastMillisecondOfCurrentMonth } from "@arbolus-technologies/utils";

import { WorkHistoryItemModel } from "../../../interfaces";
import { EMPLOYED_STATUS_OPTIONS } from "../helpers/constants";
import { CompanySelector } from "./Components/CompanySelector";
import { CountrySelector } from "./Components/CountrySelector";
import { EmploymentTypeSelector } from "./Components/EmploymentTypeSelector";

import styles from "./WorkHistoryForm.module.scss";

const { TextArea } = Input;

interface ExpertCountryForm extends ExpertCountry {
  threeLetterCode: string;
}

export type IWorkHistoryForm = Omit<
  WorkHistoryItemModel,
  "employedStatus" | "country" | "company" | "id"
> & {
  country: ExpertCountryForm;
  company: ExpertCompany;
  employedStatus: EmployedStatus;
  id: string | null;
};

interface WorkHistoryFormProps {
  workHistoryItem: WorkHistoryItemModel;
  isSaving: boolean;
  isAddingNew: boolean;
  onDiscard: () => void;
  onDelete?: () => void;
  onFormEdit: () => void;
  onSubmit: (item: WorkHistoryItemModel) => void;
}

export const WorkHistoryForm: React.FC<WorkHistoryFormProps> = ({
  workHistoryItem,
  isSaving,
  isAddingNew,
  onDiscard,
  onDelete,
  onFormEdit,
  onSubmit
}) => {
  const { t } = useTranslation("workHistoryEditForm");
  const [form] = Form.useForm();
  const touchedFields = form.getFieldsValue(true, ({ touched }) => touched);
  const isAnyFieldTouched = Object.keys(touchedFields).length > 0;

  const { employedStatus, isCurrentWork, from } = Form.useWatch([], form) ?? {};

  const [displayCancelModal, setDisplayCancelModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);

  const currentDate = new Date();

  useEffect(() => {
    if (isCurrentWork) {
      form.setFieldValue("to", null);
    }
  }, [isCurrentWork, form]);

  useEffect(() => {
    isAnyFieldTouched && onFormEdit();
  }, [isAnyFieldTouched]);

  const handleCancel = () => {
    if (isAnyFieldTouched) {
      setDisplayCancelModal(true);
    } else {
      onDiscard();
    }
  };

  const handleDiscard = useCallback(() => {
    form.resetFields();
    setDisplayCancelModal(false);
    onDiscard();
  }, [form, onDiscard]);

  const handleDeletePosition = useCallback(() => {
    setDisplayDeleteModal(false);
    onDelete?.();
  }, [form, onDiscard]);

  const handleFinish = (values: WorkHistoryItemModel) => {
    onSubmit({ ...values, id: workHistoryItem.id });

    // Get all field names from the form
    const allFields = form.getFieldsValue(true);

    // Reset touched status by setting fields with no errors
    const fields = Object.keys(allFields).map((name) => ({
      name,
      touched: false,
      errors: []
    }));

    // Set fields to be untouched
    form.setFields(fields);
  };

  return (
    <>
      {isSaving && <Loader isFull />}
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        disabled={isAddingNew}
        className={styles.form}
        initialValues={{
          ...workHistoryItem,
          from: dayjs(workHistoryItem.from),
          to: workHistoryItem.to ? dayjs(workHistoryItem.to) : "",
          company: workHistoryItem?.company
            ? { ...workHistoryItem.company }
            : null,
          country: workHistoryItem?.country
            ? { ...workHistoryItem.country }
            : null
        }}
        onFinish={handleFinish}
      >
        {/* Employment type, job title */}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("employmentType")}
              name="employedStatus"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: t("employedStatus")
                }
              ]}
            >
              <EmploymentTypeSelector />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("jobTitle")}
              name="title"
              className={styles.formItem}
              rules={[
                {
                  required: EMPLOYED_STATUS_OPTIONS.includes(employedStatus),
                  message: t("jobTitleRequired")
                },
                {
                  max: UserConstraints.MAX_TITLE_LENGTH,
                  message: t("jobTitleMaxLength", {
                    length: UserConstraints.MAX_TITLE_LENGTH
                  })
                }
              ]}
            >
              <Input
                placeholder={t("titlePlaceholder")}
                disabled={
                  !EMPLOYED_STATUS_OPTIONS.includes(employedStatus) ||
                  isAddingNew
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Company, location */}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("company")}
              name="company"
              className={styles.formItem}
              rules={[
                {
                  required: EMPLOYED_STATUS_OPTIONS.includes(employedStatus),
                  message: t("companyNameRequired")
                }
              ]}
            >
              <CompanySelector isDisabled={isAddingNew} />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("location")}
              name="country"
              className={styles.formItem}
              rules={[
                {
                  required: EMPLOYED_STATUS_OPTIONS.includes(employedStatus),
                  message: t("countryRequired")
                }
              ]}
            >
              <CountrySelector isDisabled={isAddingNew} />
            </Form.Item>
          </Col>
        </Row>

        {/* Current work */}
        <Form.Item
          validateDebounce={INPUT_DEBOUNCE}
          name="isCurrentWork"
          valuePropName="checked"
          className={styles.formItem}
        >
          <Checkbox
            disabled={employedStatus === EmployedStatus.Retired || isAddingNew}
          >
            {t("isCurrentWork")}
          </Checkbox>
        </Form.Item>

        {/* Start end date */}
        <Row gutter={24}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("fromDate")}
              name="from"
              className={styles.formItem}
              rules={[
                {
                  required: true,
                  message: t("fromDateRequired")
                },
                {
                  validator(_, value) {
                    const date = getLastMillisecondOfCurrentMonth();
                    if (value && dayjs(value).isAfter(dayjs(date), "month")) {
                      return Promise.reject(
                        new Error(t("startDateCurrentDate"))
                      );
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <DatePicker
                picker="month"
                format="YYYY-MM"
                placeholder={t("selectDate")}
                disabledDate={(date) => date.isAfter(currentDate, "month")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item
              validateDebounce={INPUT_DEBOUNCE}
              label={t("toDate")}
              name="to"
              className={styles.formItem}
              rules={[
                {
                  required: !isCurrentWork,
                  message: t("endDateRequired")
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const fromDate = getFieldValue("from");
                    if (
                      fromDate &&
                      value &&
                      dayjs(value).isBefore(dayjs(fromDate), "month")
                    ) {
                      return Promise.reject(new Error(t("endDateStartDate")));
                    }

                    const date = getLastMillisecondOfCurrentMonth();
                    if (value && dayjs(value).isAfter(dayjs(date), "month")) {
                      return Promise.reject(new Error(t("endDateCurrentDate")));
                    }

                    return Promise.resolve();
                  }
                })
              ]}
            >
              <DatePicker
                picker="month"
                format="YYYY-MM"
                placeholder={t("selectDate")}
                disabledDate={(date) =>
                  date.isBefore(from, "month") ||
                  date.isAfter(currentDate, "month")
                }
                allowClear
                disabled={isCurrentWork || isAddingNew}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Description */}
        <Form.Item
          validateDebounce={INPUT_DEBOUNCE}
          label={t("description")}
          name="jobDescription"
          className={styles.formItem}
          rules={[
            {
              max: ExpertWorkConstraints.MAX_JOB_DESCRIPTION_LENGTH,
              message: t("jobDescMaxLength", {
                length: ExpertWorkConstraints.MAX_JOB_DESCRIPTION_LENGTH
              })
            }
          ]}
        >
          <TextArea
            rows={4}
            placeholder={t("descriptionPlaceholder")}
            value=""
          />
        </Form.Item>

        {/* Save changes, cancel, delete */}
        <Form.Item noStyle>
          <Row gutter={[24, 16]} justify="space-between">
            <Col xs={24} sm={12}>
              <Flex gap={16}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isAnyFieldTouched || isAddingNew}
                >
                  {t("saveChanges")}
                </Button>
                <Button
                  onClick={handleCancel}
                  disabled={!isAnyFieldTouched || isAddingNew}
                >
                  {t("cancel")}
                </Button>
              </Flex>
            </Col>

            {onDelete && (
              <Col>
                <Button danger onClick={() => setDisplayDeleteModal(true)}>
                  {t("deletePosition")}
                </Button>
              </Col>
            )}
          </Row>
        </Form.Item>
      </Form>

      <Modal
        title={t("unsavedChanges")}
        open={displayCancelModal}
        onCancel={() => setDisplayCancelModal((prev: boolean) => !prev)}
        cancelText={t("cancel")}
        onOk={handleDiscard}
        okText={t("discard")}
      >
        {t("unsavedChangesSubtitle")}
      </Modal>

      <Modal
        title={t("confirmationRequired")}
        open={displayDeleteModal}
        onCancel={() => setDisplayDeleteModal((prev: boolean) => !prev)}
        cancelText={t("cancel")}
        onOk={handleDeletePosition}
        okText={t("removePosition")}
        okType="primary"
        okButtonProps={{ danger: true }}
      >
        {t("areYouSure")}
      </Modal>
    </>
  );
};
