import { push } from "connected-react-router";
import React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";

import { AuthPageHeader } from "@arbolus-technologies/features/common";
import { APP_TRACKING_ROUTES } from "@arbolus-technologies/models/common";

import { PublicRouteConstants } from "../../../../../constants";
import { RegisterStages } from "../../../../../constants/auth";
import { AppAction } from "../../../../../store/actions";
import SignUpOption from "./SignUpOption";

interface OnboardSelectionProps extends WithTranslation {
  from?: string;
  onOptionSelect: (newStage: RegisterStages) => void;
  navigateToLogin: () => void;
}

class OnboardSelection extends React.PureComponent<OnboardSelectionProps, {}> {
  static defaultProps = {
    navigateToLogin: (): void => {}
  };

  componentDidMount(): void {
    const { t } = this.props;
    document.title = t("register");
  }

  render(): JSX.Element {
    const { t, navigateToLogin, onOptionSelect, from } = this.props;
    const currentQueryString = window.location.search;

    return (
      <div className="sign-up-options-page">
        <AuthPageHeader title="New account" />
        <div className="sign-up-options-container">
          <div className="sign-up-options">
            <SignUpOption
              title={t("expertAccountTitle")}
              description={t("expertMessage")}
              icon="person"
              onClick={(): void => onOptionSelect(RegisterStages.TERMS)}
            />
            <SignUpOption
              title={t("companyAccountTitle")}
              description={t("companyMessage")}
              icon="work"
              onClick={(): void => onOptionSelect(RegisterStages.INQUIRY)}
            />
            <SignUpOption
              title={t("teamAccount")}
              description={t("teamMessage")}
              icon="groups"
              onClick={navigateToLogin}
            />
          </div>
          <div className="sign-up-options-footer">
            <Trans
              ns="register"
              i18nKey="alreadyHaveAccount"
              components={[
                <Link
                  key="0"
                  to={{
                    pathname: PublicRouteConstants.LOGIN,
                    search: currentQueryString,
                    state: { from: from ?? APP_TRACKING_ROUTES.REGISTER }
                  }}
                />
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Record<string, AppAction> => ({
  navigateToLogin: (): void => {
    const currentQueryString = window.location.search;
    dispatch(
      push({
        pathname: PublicRouteConstants.LOGIN,
        search: currentQueryString
      })
    );
  }
});

export default withTranslation("register")(
  // @ts-ignore
  connect(null, mapDispatchToProps)(OnboardSelection)
);
