import React from "react";
import { useLocation } from "react-router";

import {
  PROJECT_BRIEF_ROUTE,
  PROJECT_SPEND_ROUTE,
  PROJECT_TEAM_MEMBERS
} from "@arbolus-technologies/routes";
import {
  ProjectDetailsButton,
  SpendTrackerButton,
  TeamMembersButton
} from "@arbolus-technologies/ui/components";
import { GrayBox } from "@arbolus-technologies/ui/layout";

import { ProjectNotificationsSlidePanel } from "../../Components/ProjectNotificationsSlidePanel/ProjectNotificationsSlidePanel";

import styles from "./ProjectManagementMenu.module.scss";

interface BottomBarProps {
  projectId: string;
}

export const ProjectManagementMenu: React.FC<BottomBarProps> = ({
  projectId
}) => {
  const { search } = useLocation();

  return (
    <GrayBox fullWidth flexDirection="row">
      <div className={styles.container}>
        <ProjectDetailsButton to={PROJECT_BRIEF_ROUTE(projectId)} />
        <SpendTrackerButton to={PROJECT_SPEND_ROUTE(projectId)} />
        <ProjectNotificationsSlidePanel projectId={projectId} />
        <TeamMembersButton
          to={{ ...PROJECT_TEAM_MEMBERS(projectId), search }}
        />
      </div>
    </GrayBox>
  );
};
