import {
  APP_TRACKING_ROUTES,
  DNC_CATEGORY_ENUM,
  DO_NOT_CONTACT_STATUS,
  DateString,
  ExpertWorkHistory,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";

import {
  ChatUserAPIRole,
  DatabaseChatType,
  DiscoverFilterOperator
} from "./enums";

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  profileImageUrl?: string;
  isoCountryCode?: string;
  phoneNumber?: string;
}

export interface OwnerUser extends User {
  ciqManager: boolean;
}

export interface ExpertUser extends User {
  timezone?: string;
  hasMinimumCallTimeRate?: boolean;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
}

export interface Country {
  id: string;
  name: string;
  twoLetterCode: string;
  threeLetterCode: string;
  sortOrder: number;
}

export type AngleAntDColors =
  | "#722ED1"
  | "#1677FF"
  | "#13C2C2"
  | "#389E0D"
  | "#EB2F96"
  | "#FADB14"
  | "#597EF7"
  | "#BAE637"
  | "#FA541C"
  | "#FA8C16";

export interface AngleLink {
  id: string;
  invitationUrl: string;
  title: string;
}

export interface AvailableSlot {
  slotId: number;
  slotStartValue: Date;
  slotEndValue: Date;
  slotDate: string;
  slotTimeStart: string;
  slotTimeEnd: string;
  slotSelected: boolean;
  slotScheduled: boolean;
}

export interface Slot {
  startTime: string;
  endTime: string;
  eventId?: string;
  isExpired?: boolean;
  isScheduled?: boolean;
}

export interface ProjectExpertAvailability {
  expertAvailabilitySlots: Slot[];
  expertTimezone: string;
  projectName?: string;
  projectTimezone?: string;
  hasBeenRequiredMoreAvailabilitySlots: boolean;
}

export interface ScreeningQuestion {
  questionId: string;
  question: string;
}

export interface ComplianceAnswer {
  answer: string;
  textAnswer?: string;
}

export interface ComplianceQuestion {
  question: string;
  displayModel: ComplianceDisplayModel;
}

export interface ComplianceDisplayModel {
  questionType: string;
  required: boolean;
  defaultValue?: string;
  textRequiredValue?: string;
  defaultText?: string;
  expectedAnswer?: string;
  importance?: string;
}

export interface Compliance {
  questionId: string;
  answerId?: string;
  question: ComplianceQuestion;
  answer?: ComplianceAnswer;
}

export interface ProjectComplianceFormValues {
  compliances: Compliance[];
}

export interface Answer extends ScreeningQuestion {
  answerId?: string;
  answer?: string;
}

export interface ProjectQuestionsFormValues {
  questions: Answer[];
}

export type ProjectApplicationDataTypes =
  | ProjectQuestionsFormValues
  | ProjectComplianceFormValues
  | ProjectExpertAvailability
  | null;

export interface ProjectApplicationStepProps {
  stepDetails: ProjectApplicationStepDetails;
  onSaveProgress: (
    values: ProjectApplicationDataTypes,
    isManualSave: boolean,
    dirtyState?: boolean
  ) => void;
  isLoading: boolean;
  onNext?: () => void;
  onBack?: () => void;
}

export interface ProjectApplicationStepDetails {
  currentStep: number;
  totalSteps: number;
  nextStepTitle: string;
  completedSteps: number;
}

export enum REFERRAL_REJECT_REASON {
  ALREADY_ENGAGED = "AlreadyEngaged",
  WRONG_GEOGRAPHY = "WrongGeography",
  COMPANY_COVERED = "CompanyCovered",
  NOT_SENIOR = "NotSenior",
  ANGLE_COMPLETED = "AngleCompleted",
  OTHER = "Other"
}

export interface RejectDescription {
  reason: REFERRAL_REJECT_REASON;
  description?: string;
}

interface SimplifiedProjectPayloadBase {
  name: string;
  brief?: string;
  timeZone: string;
  mainContactUserId: string;
  phoneNumber?: string;
  activeProjectChatForAllUsers: boolean;
  startDate: Date;
  endDate?: Date | null;
  caseCode?: string;
  managerName?: string;
  managerEmail?: string;
  partnerName?: string;
  partnerEmail?: string;
}

export interface SimplifiedCreateProjectPayload
  extends SimplifiedProjectPayloadBase {
  clientId: string;
  minimumNumberOfExperts?: number;
}

export interface SimplifiedEditProjectPayload
  extends SimplifiedProjectPayloadBase {
  projectId: string;
}

export interface EventCreationDetailsParams {
  expert: ExpertUser;
  selectedSlot?: { startDate: Date; endDate: Date };
  workspaceId?: string;
  timezone: string;
  title?: string;
}

export interface EventCreationState {
  endDate?: Date;
  expert: ExpertUser;
  from?: APP_TRACKING_ROUTES;
  startDate?: Date;
  timezone: string;
  title: string;
  workspaceId?: string;
}

export interface ScheduleProjectData {
  id: string;
  hasCompliance: boolean;
  name: string;
  timezone: string;
  isBain: boolean;
}

export enum AvailabilityStatus {
  BOOK = "book",
  CANDIDATE = "candidate",
  PENDING_COMPLIANCE = "pendingCompliance"
}

export interface DropdownAngle {
  id: string;
  title: string;
  color: string | null;
  textColor?: string;
}

export interface ChatData {
  id: string;
  channelName: string;
  chatMembers: ChatMember[];
  title?: string;
  expertName?: string;
  expertAvatar?: string;
  expertId?: string;
  userId?: string;
  referralId?: string;
  referralApplicationStatus?: REFERRAL_SUB_STATE;
  referralComplianceStatus?: REFERRAL_COMPLIANCE_STATE;
  referralStatus?: REFERRAL_STATUS;
  chatType: DatabaseChatType;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
}

export interface AngleChatData extends ChatData {
  title: string;
}

// Not very accurate because ciq-client and ciq-admin return different types
export interface ChatClientDetails {
  name: string;
  logoUrl: string;
}

export interface UnreadMessagesCountItem {
  chatId: string;
  unreadMessagesCount: number;
  chatType: DatabaseChatType;
}

export interface UnreadMessagesCount {
  // keyed by chat ID
  [key: string]: number;
}

export interface ChannelNameByChatId {
  // keyed by chat ID, value is channel name ("presence-...")
  [key: string]: string;
}

export interface UnreadMessagesCountAdminSwitch {
  ClientChat: number;
  ExpertSupport: number;
}

export interface AngleChat {
  id: string;
  title: string;
  color: string;
  angleChatId: string;
  chats: AngleChatData[];
  referralApplicationStatus?: REFERRAL_SUB_STATE;
  referralComplianceStatus?: REFERRAL_COMPLIANCE_STATE;
  channelName: string;
  chatMembers: ChatMember[];
}

export interface ChatMember {
  id: string;
  userFullName: string;
  title: string;
  profileImageUrl: string | null;
  userType: ChatUserAPIRole;
}

export interface OnlineMembersByChat {
  [chatId: string]: OnlineMembers;
}

export interface OnlineMembers {
  members: Members;
  count: number;
  myID: string;
  me: CurrentUser;
}

export interface Members {
  [key: string]: Member;
}

export interface Member {
  userId: string;
  userName: string;
  profileImageUrl: string;
}

export interface CurrentUser {
  id: string;
  info: Info;
}

export interface Info {
  userId: string;
  userName: string;
  profileImageUrl: string;
}

export interface UpdatedMember {
  id: string;
  info: Info;
}

export interface CustomerCompanyInfo {
  companyId: string;
  companyName: string;
}

export interface DiscoverExpert {
  id: string;
  fullName: string;
  profileImageUrl: string | null;
  isoCountryCode: string | null;
  title: string | null;
  stats: DiscoverExpertStats;
  workHistories: ExpertWorkHistory[] | null;
  lastPublicCompanyDate: string | null;
  projects: ProjectResponse[];
  isRecommendedExpert: boolean;
  isOnboarded: boolean;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  doNotContactStatusDescription?: string;
  doNotContactStatusCategory?: DNC_CATEGORY_ENUM | null;
  searchMatchColumns: string[];
  recommendationMatchPercentage: number | null;
  customers: CustomerCompanyInfo[];
  linkedInProfile: string;
  workHistoryLastUpdateDate: DateString | null;
}

export interface ProjectResponse {
  id: string;
  name: string;
}

export interface SearchStatsResponse {
  expertsCount: number;
}

export interface ReferralsStatsResponse {
  shortList: number;
  candidate: number;
  scheduling: number;
  booked: number;
  rejected: number;
}

export interface DiscoverExpertStats {
  calls: number;
  canopies: number;
  projects: number;
}

export interface DiscoverFilterOption {
  value: string;
  label: string;
  searchLabel?: string; // to filter expert locations by threeLetterCode along with name(label)
  twoLetterCode?: string; // to show flags for expert locations
  operator?: DiscoverFilterOperator;
}

export interface ExpertDiscoverFilters {
  expertLocations: DiscoverFilterOption[];
  workHistoryLocations: DiscoverFilterOption[];
  company: DiscoverFilterOption[];
  projects: DiscoverFilterOption[];
  keywords: DiscoverFilterOption[];
  customers: DiscoverFilterOption[];
  industries: DiscoverFilterOption[];
}

export interface ExpertDiscoverFiltersQuery {
  expertLocations: string;
  workHistoryLocations: string;
  company: string;
  keyword: string;
  projects: string;
  customers: string;
}

export interface DataMessageGPT {
  canopyAnswerId: string | null;
  canopyId: string | null;
  transcriptHeaderId: string | null;
  text: string;
}

export interface GPTResponse {
  answer: string;
  data: DataMessageGPT[];
}

export interface MatchedCompaniesWithIndustry {
  industry: string;
  companies: string[];
}
