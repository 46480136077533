/* eslint-disable @typescript-eslint/no-explicit-any */
import { Handlers, createReducer } from "reduxsauce";

import { ACCEPT_PLATFORM_AGREEMENT } from "./actions/CacheAcceptPlatformAgreement/CacheAcceptPlatformAgreementActionTypes";
import {
  GET_ADMIN_USER_PROFILE,
  UPDATE_ADMIN_USER_PROFILE
} from "./actions/CacheAdminUserProfile/CacheAdminUserProfileActionTypes";
import {
  CACHE_GET_APP_DATA,
  CACHE_GET_APP_DATA_FAILURE,
  CACHE_GET_APP_DATA_SUCCESS
} from "./actions/CacheGetAppData/CacheGetAppDataActionTypes";
import { GET_APP_TYPE_USER } from "./actions/CacheGetAppType/CacheGetAppTypeActionTypes";
import { GET_LOGGED_IN_USER } from "./actions/CacheGetLoggedInUser/CacheGetLoggedInUserActionTypes";
import { CacheReducerState } from "./models/definitions";
import { checkIfIsAdmin } from "./reducers/CacheCheckIfIsAdminReducer";
import { handleAcceptPlatformAgreement } from "./reducers/CacheHandleAcceptPlatformAgreementReducer";
import { handleGetLoggedInUser } from "./reducers/CacheHandleGetLoggedInUserReducer";
import {
  handleGetMetaData,
  handleGetMetaDataFailure,
  handleGetMetaDataSuccess
} from "./reducers/CacheHandleGetMetadataReducer";
import {
  handleGetAdminUserProfile,
  handleUpdateAdminUserProfile
} from "./reducers/CacheHandleUserProfileReducer";
import { initialState } from "./reducers/CacheInitialState";

export const handlers: Handlers<CacheReducerState, any> = {
  [CACHE_GET_APP_DATA]: handleGetMetaData,
  [CACHE_GET_APP_DATA_SUCCESS]: handleGetMetaDataSuccess,
  [CACHE_GET_APP_DATA_FAILURE]: handleGetMetaDataFailure,
  [GET_LOGGED_IN_USER]: handleGetLoggedInUser,
  [GET_APP_TYPE_USER]: checkIfIsAdmin,
  [GET_ADMIN_USER_PROFILE]: handleGetAdminUserProfile,
  [UPDATE_ADMIN_USER_PROFILE]: handleUpdateAdminUserProfile,
  [ACCEPT_PLATFORM_AGREEMENT]: handleAcceptPlatformAgreement
};

export const CacheReducer = createReducer(initialState, handlers);
