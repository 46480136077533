import { stringify } from "query-string";

import { SimpleRestServiceError } from "./SimpleRestServiceError";

export async function call_get<T = Record<string, unknown>>(
  url: string,
  params?: any
) {
  const parsedUrl = new URL(url);
  parsedUrl.search = stringify(params);

  const response = await fetch(parsedUrl, {
    mode: "cors",
    credentials: "include"
  });

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "GET", url: parsedUrl.toString(), params },
      { status: response.status }
    );
  }

  return (await response.json()) as T;
}

export async function call_post<
  ReqT = Record<string, unknown>,
  RespT = Record<string, unknown>
>(url: string, payload?: ReqT) {
  const requestOptions: RequestInit = {
    method: "POST",
    mode: "cors",
    credentials: "include"
  };

  // new URL will test url format - we'll see the error on FE
  const response = await fetch(new URL(url), {
    ...requestOptions,
    ...getRequestOptionsPayload(payload)
  });

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "POST", url, payload },
      { status: response.status }
    );
  }

  return (await response.json()) as RespT;
}

export async function call_delete(
  url: string,
  { params = {}, payload = null } = {}
) {
  const parsedUrl = new URL(url);
  parsedUrl.search = stringify(params);

  const requestOptions: RequestInit = {
    method: "DELETE",
    mode: "cors",
    credentials: "include"
  };

  const response = await fetch(parsedUrl, {
    ...requestOptions,
    ...getRequestOptionsPayload(payload)
  });

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "DELETE", url, params, payload },
      { status: response.status }
    );
  }

  return true;
}

export async function call_put<
  ReqT = Record<string, unknown>,
  RespT = Record<string, unknown>
>(url: string, payload?: ReqT) {
  const requestOptions: RequestInit = {
    method: "PUT",
    mode: "cors",
    credentials: "include"
  };

  const response = await fetch(new URL(url), {
    ...requestOptions,
    ...getRequestOptionsPayload(payload)
  });

  if (!response.ok) {
    throw new SimpleRestServiceError(
      { method: "PUT", url, payload },
      { status: response.status }
    );
  }

  return (await response.json()) as RespT;
}

function getRequestOptionsPayload<ReqT = Record<string, unknown>>(
  payload?: ReqT
) {
  const options: RequestInit = {};
  if (payload) {
    options.headers = {
      "Content-Type": "application/json"
    };
    options.body = JSON.stringify(payload);
  }
  return options;
}
