import moment from "moment";
import { useEffect, useState } from "react";
import { enableFunctionalityBasedOnDate } from "../UtilService";

interface UseChristmas {
  isChristmasMode: boolean;
  isSnowEnabled: boolean;
  toggleSnow: () => void;
}

export const useChristmas = (): UseChristmas => {
  const [isChristmasMode, setIsChristmasMode] = useState(false);
  const [isSnowEnabled, setIsSnowEnabled] = useState(false);

  useEffect(() => {
    const enableChristmasMode = enableFunctionalityBasedOnDate(
      new Date(moment().year(), 11, 16),
      new Date(moment().year(), 11, 26)
    );
    setIsChristmasMode(enableChristmasMode);

    const storedSnowState = localStorage.getItem("isSnowEnabled");
    const snowState =
      storedSnowState !== null ? JSON.parse(storedSnowState) : true;
    setIsSnowEnabled(snowState);
  }, []);

  const toggleSnow = (): void => {
    setIsSnowEnabled((prevState) => {
      const newState = !prevState;
      localStorage.setItem("isSnowEnabled", JSON.stringify(newState));
      return newState;
    });
  };

  return {
    isChristmasMode,
    isSnowEnabled,
    toggleSnow
  };
};
