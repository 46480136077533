import {
  AdminService,
  CIQError,
  Compliance as ComplianceType,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  ProjectAnswer,
  ReferralDetail,
  ToasterService
} from "@arbolus-technologies/api";
import { REFERRAL_SUB_STATE } from "@arbolus-technologies/models/common";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { Button, Checkbox, CheckboxProps, Flex, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  REFERRAL_EXPERT_TABS,
  REFERRAL_EXPERT_TABS_TYPE,
  getReferralProfileTabs
} from "../../../utils";
import { Compliance } from "./TabContent/Compliance/Compliance";
import { Profile } from "./TabContent/Profile/Profile";
import { Scheduling } from "./TabContent/Scheduling/Scheduling";
import { Screening } from "./TabContent/Screening/Screening";

interface TabsSectionProps {
  expertInfo: ExpertDetail;
  screeningInfo: ProjectAnswer[];
  complianceAnswers: ComplianceType[];
  referral: ReferralDetail | null;
  addNewNote: boolean;
  activeTab: REFERRAL_EXPERT_TABS_TYPE;
  showProfileOnly: boolean;
  setAddNewNote: (value: boolean) => void;
  handleUpdateScreeningAnswers: (answers: ProjectAnswer[]) => void;
  handleUpdateComplianceAnswers: (answers: ComplianceType[]) => void;
  handleSaveNote: (note?: string) => void;
  handleSetActiveTab: (tab: REFERRAL_EXPERT_TABS_TYPE) => void;
  onIsVerified: (isVerified: boolean) => void;
  adminService?: typeof AdminService;
  notificationService?: ToasterService;
}
export const TabsSection: React.FC<TabsSectionProps> = ({
  expertInfo,
  screeningInfo,
  complianceAnswers,
  referral,
  addNewNote,
  activeTab,
  showProfileOnly,
  setAddNewNote,
  handleUpdateScreeningAnswers,
  handleUpdateComplianceAnswers,
  handleSaveNote,
  handleSetActiveTab,
  onIsVerified,
  adminService = AdminService,
  notificationService = DefaultToasterService
}) => {
  const [isLinkedinVerified, setIsLinkedinVerified] = useState<boolean>(false);

  const { t } = useTranslation("referralsTableExpertProfile");
  const hasCompliance = useSelector(
    ProjectNxSelector.projectHasComplianceSelector()
  );
  const expertId = expertInfo.id;

  const handleLinkedinVerification: CheckboxProps["onChange"] = (e) => {
    const isChecked = e.target.checked;
    setIsLinkedinVerified(isChecked);

    adminService.updateExpertIsVerified(expertId, isChecked).subscribe(
      () => {
        onIsVerified?.(isChecked);
        notificationService.showSuccess(t("linkedinStatusUpdated"));
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(err);
      }
    );
  };

  if (showProfileOnly) {
    return <Profile expert={expertInfo} showProfileOnly />;
  }

  if (!referral) {
    return null;
  }

  const renderTabsPage = {
    [REFERRAL_EXPERT_TABS.PROFILE]: (
      <Profile
        expert={expertInfo}
        note={referral.note}
        addNewNote={addNewNote}
        referralId={referral.id}
        showProfileOnly={false}
        onSaveNote={handleSaveNote}
        onCancelNote={() => setAddNewNote(false)}
      />
    ),
    [REFERRAL_EXPERT_TABS.SCREENING]: (
      <Screening
        screeningInfo={screeningInfo}
        expertId={expertId}
        referralStatus={referral.status}
        doNotContactStatus={expertInfo.doNotContactStatus}
        onUpdateScreeningAnswers={handleUpdateScreeningAnswers}
      />
    ),
    [REFERRAL_EXPERT_TABS.COMPLIANCE]: (
      <Compliance
        complianceInfo={complianceAnswers}
        referral={referral}
        doNotContactStatus={expertInfo.doNotContactStatus}
        expertId={expertId}
        onUpdateComplianceAnswers={handleUpdateComplianceAnswers}
      />
    ),
    [REFERRAL_EXPERT_TABS.SCHEDULING]: (
      <Scheduling referral={referral} expert={expertInfo} />
    )
  };

  const REFERRAL_EXPERT_TABS_ACTIVE = getReferralProfileTabs(
    referral.application.subStatus === REFERRAL_SUB_STATE.REJECT,
    screeningInfo.length > 0,
    hasCompliance
  );

  return (
    <>
      <Flex justify="space-between" align="center">
        <Space.Compact>
          {REFERRAL_EXPERT_TABS_ACTIVE.map((tabKey) => (
            <Button
              key={tabKey}
              type={tabKey === activeTab ? "primary" : "default"}
              onClick={() => handleSetActiveTab(tabKey)}
            >
              {t(tabKey)}
            </Button>
          ))}
        </Space.Compact>
        <Checkbox
          onChange={handleLinkedinVerification}
          checked={isLinkedinVerified}
        >
          {t("linkedinVerified")}
        </Checkbox>
      </Flex>
      {renderTabsPage[activeTab]}
    </>
  );
};
