import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Button, Card, CardBody, Collapse } from "reactstrap";

import { COMPANY_TYPE } from "@arbolus-technologies/models/common";
import {
  DangerouslySetInnerHTML,
  PublicCompanyBadge
} from "@arbolus-technologies/ui/components";
import {
  EXPERT_WORK_TIME_FORMAT,
  formatDate,
  joinStringsWithSeparator
} from "@arbolus-technologies/utils";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ExpertWorkHistory } from "../../../../../models/expert";
import { UtilsService } from "../../../../../services";
import { CIQReadMore } from "../../../../app/components";

interface CollapseHistoryProps {
  timeline: ExpertWorkHistory[];
  displayPublicCompanies: boolean;
}

interface CollapseHistoryState {
  expandedItems: string[];
}

type CollapseHistoryIntersectProps = CollapseHistoryProps & WithTranslation;

class CollapseTimeline extends React.Component<
  CollapseHistoryIntersectProps,
  CollapseHistoryState
> {
  constructor(props: CollapseHistoryIntersectProps) {
    super(props);
    this.state = {
      expandedItems: this.generateInitialExpandedItems(props)
    };
  }

  componentDidUpdate(preProps: CollapseHistoryProps): void {
    const { timeline } = this.props;
    if (timeline !== preProps.timeline) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        expandedItems: this.generateInitialExpandedItems(this.props)
      });
    }
  }

  generateInitialExpandedItems = (props: CollapseHistoryProps): string[] => {
    const { timeline } = props;

    const expandedItems: string[] = [];
    timeline.forEach((t, index: number) => {
      if (index < 3) {
        expandedItems.push(t.id!);
      }
    });
    return expandedItems;
  };

  handleCollapsibleToggleEvent = (id: string): void => {
    let { expandedItems } = this.state;
    const itemPosition = expandedItems.findIndex((i) => i === id);
    if (itemPosition === -1) {
      expandedItems.push(id);
    } else {
      expandedItems = expandedItems.filter((i) => i !== id);
    }

    this.setState({
      expandedItems
    });
  };

  renderHistoryItem = (
    workExperience: ExpertWorkHistory,
    index: number
  ): JSX.Element => {
    const { t, displayPublicCompanies } = this.props;
    const { expandedItems } = this.state;

    const {
      id,
      from,
      to,
      jobDescription,
      title,
      company,
      employedStatus,
      country
    } = workExperience;

    const startDate = formatDate(
      UtilsService.convertUTCToActiveZone(from),
      EXPERT_WORK_TIME_FORMAT
    );

    const endDate = to
      ? formatDate(
          UtilsService.convertUTCToActiveZone(to),
          EXPERT_WORK_TIME_FORMAT
        )
      : t("present");

    const isExpanded =
      expandedItems.findIndex((i) => i === workExperience.id) !== -1;

    const header = joinStringsWithSeparator([
      title ?? t(employedStatus),
      company?.name ?? null,
      country?.name ?? null
    ]);

    return (
      <div key={`${workExperience.id}_${index}`} className="accordion">
        <Button
          className={`${isExpanded && "btn-success"}`}
          onClick={(): void => this.handleCollapsibleToggleEvent(id!)}
        >
          {`${startDate} - ${endDate}`}

          <span className="ciq-icon ciq-chevron-down" />
        </Button>
        <Collapse isOpen={isExpanded}>
          <Card>
            <CardBody>
              <div className="workHistoryHeader">
                <DangerouslySetInnerHTML
                  text={header}
                  fontSize="16px"
                  fontWeight={600}
                  color={ARBOLUS_COLORS.bColorBaseDark}
                />
                {displayPublicCompanies &&
                  company?.type === COMPANY_TYPE.Public && (
                    <PublicCompanyBadge date={to} />
                  )}
              </div>
              {jobDescription && (
                <div className="rich-content">
                  <CIQReadMore>
                    {UtilsService.parseRichTextContent(jobDescription)}
                  </CIQReadMore>
                </div>
              )}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  };

  render(): JSX.Element[] {
    const { timeline } = this.props;
    return timeline.map((item: ExpertWorkHistory, index: number) =>
      this.renderHistoryItem(item, index)
    );
  }
}

export default withTranslation("collapseTimeline")(CollapseTimeline);
