import { Button, Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import { DefaultClipboardManager } from "@arbolus-technologies/features/common";
import { CUSTOMERS_SURVEYS_ANSWERS_ROUTE } from "@arbolus-technologies/routes";

interface ActionsCellRendererProps {
  data: { id: string; title: string };
}

export function ActionsCellRenderer({ data }: ActionsCellRendererProps) {
  const { t } = useTranslation("customerSurveyTable");

  function copyToClipboard() {
    DefaultClipboardManager.copy(
      `${process.env.NX_PUBLIC_BASE_URL}/customers/surveys/${data.id}`
    );
  }

  return (
    <Flex gap={16} justify="center" align="center" style={{ height: "100%" }}>
      <Button size="small" onClick={copyToClipboard}>
        {t("copyLinkButton")}
      </Button>
      <AntDButtonLink
        pathname={CUSTOMERS_SURVEYS_ANSWERS_ROUTE(data.id).pathname!}
        text={t("allAnswersButton")}
        state={{ surveyTitle: data.title, surveyId: data.id }}
      />
    </Flex>
  );
}
