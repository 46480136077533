import { useFeature } from "flagged";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { zip } from "rxjs";

import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  ProjectStats,
  ToasterService
} from "@arbolus-technologies/api";
import {
  ProjectData,
  SimpleCanopyCreationProvider,
  SlidePanel
} from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { Loader } from "@arbolus-technologies/ui/components";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { SIDE_PANEL_SIZE, useChristmas } from "@arbolus-technologies/utils";

import Snowfall from "react-snowfall";
import { HeaderButtons } from "../Components/HeaderButtons/HeaderButtons";
import { UpdateProjectStateButton } from "../Components/UpdateProjectStateButton/UpdateProjectStateButton";
import { useAngleQuery } from "../Hooks/useAngleQuery";
import { AdminActionsMenu } from "../Modules/AdminActionsMenu/AdminActionsMenu";
import { ProjectAngles } from "../Modules/ProjectAngles/ProjectAngles";
import { ProjectAvailabilities } from "../Modules/ProjectAvailabilities/ProjectAvailabilities";
import { ProjectCanopy } from "../Modules/ProjectCanopy/ProjectCanopy";
import { AdminProjectDocuments } from "../Modules/ProjectDocuments/AdminProjectDocuments";
import { ProjectEvents } from "../Modules/ProjectEvents/ProjectEvents";
import { ProjectManagementMenu } from "../Modules/ProjectManagementMenu/ProjectManagementMenu";
import { ProjectSurvey } from "../Modules/ProjectSurvey/ProjectSurvey";
import { ProjectTranscripts } from "../Modules/ProjectTranscripts/ProjectTranscripts";
import { AdminReferralsActionMenu } from "../Modules/QuickActionsMenu/AdminReferralsActionMenu";
import { getCurrentAngleId } from "../helpers";

import styles from "./ProjectDashboard.module.scss";

export interface AdminProjectDashboardProps {
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const AdminProjectDashboard: React.FC<AdminProjectDashboardProps> = ({
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("adminProjectDashboard");
  const { projectId } = useParams<{ projectId: string }>();
  const angleId = useAngleQuery();
  const { isChristmasMode, isSnowEnabled, toggleSnow } = useChristmas();
  const SurveysFF = useFeature(FEATURE_FLAGS.Surveys);

  const [projectData, setProjectData] = useState<ProjectData>();
  const [projectStats, setProjectStats] = useState<ProjectStats>();
  const [expertsCount, setExpertsCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);

  const getProjectDataAndStats = useCallback(
    (angleId?: string | null): void => {
      setIsLoading(true);
      zip(
        projectService.getProject(projectId),
        projectService.getProjectStats(projectId, angleId),
        projectService.getSearchStats(projectId)
      ).subscribe(
        ([projectData, projectStats, searchStats]) => {
          setProjectData(projectData);
          setProjectStats(projectStats);
          setExpertsCount(searchStats.expertsCount);
          setIsLoading(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      );
    },
    [notificationService, projectId, projectService]
  );

  useEffect(() => {
    if (getCurrentAngleId() === angleId) {
      getProjectDataAndStats(angleId);
    }
  }, [angleId, getProjectDataAndStats]);

  return (
    <>
      {isLoading && <Loader isFull />}
      {!isLoading && (
        <>
          {isSnowEnabled && isChristmasMode && (
            <Snowfall style={{ zIndex: 1000 }} />
          )}
          {projectData && projectStats && (
            <MainPageLayout
              title={projectData.name}
              stickyHeader
              rightButtonContainer={
                <HeaderButtons
                  projectId={projectData.id}
                  unreadMessagesCount={projectStats.unreadMessageCount}
                  toggleSnow={toggleSnow}
                  isChristmasMode={isChristmasMode}
                  isSnowEnabled={isSnowEnabled}
                />
              }
            >
              <AdminActionsMenu
                projectId={projectData.id}
                client={projectData.client}
                expertsCount={expertsCount}
                isLoading={isLoading}
              />
              <ProjectAngles projectData={projectData} isAdmin />
              <AdminReferralsActionMenu projectId={projectData.id} />
              <div className={styles.splitView}>
                <ProjectEvents
                  projectId={projectData.id}
                  timezone={projectData.timezone}
                />
                <ProjectAvailabilities
                  projectId={projectData.id}
                  projectName={projectData.name}
                  timezone={projectData.timezone}
                />
              </div>
              <ProjectTranscripts
                projectId={projectData.id}
                timezone={projectData.timezone}
              />
              <ProjectCanopy projectId={projectData.id} />
              {SurveysFF && <ProjectSurvey projectId={projectData.id} />}
              <AdminProjectDocuments projectId={projectData.id} />
              <ProjectManagementMenu projectId={projectData.id} />
              <UpdateProjectStateButton
                projectId={projectData.id}
                projectState={projectData.projectState}
                projectName={projectData.name}
                isAdmin
              />
              <SlidePanel
                title={<div>{t("newCanopy")}</div>}
                panelId={PanelId.CreateSimpleCanopy}
                closeButtonDirection="right"
                width={SIDE_PANEL_SIZE._720}
              >
                <SimpleCanopyCreationProvider projectId={projectId} />
              </SlidePanel>
            </MainPageLayout>
          )}
        </>
      )}
    </>
  );
};
