import { Button, Flex, TreeSelect } from "antd";
import { RadioButton } from "arbolus-ui-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { DiscoverFilterType } from "@arbolus-technologies/models/project";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import { useDiscoverFilters } from "../../../../Hooks/Discover/useDiscoverFilters";
import { useDiscoverIndustryFilter } from "../../../../Hooks/Discover/useDiscoverIndustryFilter";
import { FilterWrapper } from "../FilterWrapper/FilterWrapper";
import { periodFilterOptions } from "../utils";

import styles from "../FilterWrapper/FilterWrapper.module.scss";

export const IndustryFilter: React.FC = () => {
  const { t } = useTranslation("discoverPage");

  const { selectedOptions, canClearFilter } = useDiscoverFilters({
    filterType: DiscoverFilterType.Industries
  });
  const {
    handleSelectOption,
    handleClearFilter,
    handleSetIndustryFilterPeriod,
    providerIndustries
  } = useDiscoverIndustryFilter();

  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const industryFilterPeriod = useSelector(
    ProjectNxSelector.industryFilterPeriod()
  );

  useEffect(() => {
    setSelectedIndustries(selectedOptions.map((option) => option.value));
  }, [selectedOptions]);

  const handleChange = (newSelectedIndustries: string[]) => {
    // check if value is removed
    const removedValue = selectedIndustries.find(
      (value) => !newSelectedIndustries.includes(value)
    );

    // If there's a removed value and the dropdown is closed, it means a industry was removed
    if (!isDropdownOpen && removedValue) {
      handleSelectOption(newSelectedIndustries);
    }

    setSelectedIndustries(newSelectedIndustries);
  };

  const handleApplyFilter = () => {
    handleSelectOption(selectedIndustries);
    setIsDropdownOpen(false);
  };

  const handleClearIndustries = () => {
    setSelectedIndustries([]);
    handleClearFilter();
  };

  return (
    <FilterWrapper
      title={t("industryFilterTitle")}
      canClearFilter={canClearFilter}
      onClear={handleClearIndustries}
      filterCounter={selectedOptions.length}
    >
      <TreeSelect
        multiple
        showSearch
        maxTagTextLength={20}
        open={isDropdownOpen}
        treeNodeFilterProp="title"
        value={selectedIndustries}
        treeData={providerIndustries}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        placeholder={t("industriesFilterPlaceholder")}
        onChange={handleChange}
        onBlur={handleApplyFilter}
        onDropdownVisibleChange={setIsDropdownOpen}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Flex justify="flex-end" style={{ paddingTop: 12 }}>
              <Button type="primary" onClick={handleApplyFilter}>
                {t("apply")}
              </Button>
            </Flex>
          </>
        )}
      />
      <div className={styles.radioButtonsContainer}>
        {periodFilterOptions.map((option) => (
          <RadioButton
            key={option.value}
            option={option}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleSetIndustryFilterPeriod(event.target.value)
            }
            isChecked={industryFilterPeriod === option.value}
          />
        ))}
      </div>
    </FilterWrapper>
  );
};
