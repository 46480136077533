import { CUSTOMER_INSIGHT_ANSWER_STATUS } from "@arbolus-technologies/models/common";
import { ICellRendererParams } from "ag-grid-community";
import { Tag } from "antd";
import React from "react";
import { INSIGHT_ANSWER_STATUS_TO_COLOR } from "../../../../constants";
import { ANSWER_STATUS_TRANSLATION } from "../companyDataTableDefinition";

interface ICellRendererParamsWithAnswerStatus extends ICellRendererParams {
  answerStatus: CUSTOMER_INSIGHT_ANSWER_STATUS;
}

export function AnswerStatus({
  data
}: {
  data: ICellRendererParamsWithAnswerStatus;
}) {
  return (
    <Tag color={INSIGHT_ANSWER_STATUS_TO_COLOR[data.answerStatus]}>
      {ANSWER_STATUS_TRANSLATION[data.answerStatus] ?? data.answerStatus}
    </Tag>
  );
}
