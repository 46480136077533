import React from "react";
import { useTranslation } from "react-i18next";

import { WorkHistory } from "@arbolus-technologies/api";
import { COMPANY_TYPE } from "@arbolus-technologies/models/common";
import {
  DangerouslySetInnerHTML,
  PublicCompanyBadge
} from "@arbolus-technologies/ui/components";
import {
  EXPERT_WORK_TIME_FORMAT,
  formatDate
} from "@arbolus-technologies/utils";

import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import styles from "./WorkHistoryDrawer.module.scss";

interface WorkHistoryDrawerTitleProps {
  workItem: WorkHistory;
  displayPublicCompanies: boolean;
}

export const WorkHistoryDrawerTitle: React.FC<WorkHistoryDrawerTitleProps> = ({
  workItem,
  displayPublicCompanies
}) => {
  const { t } = useTranslation("expertDrawer");

  const companyName = workItem.company?.name ?? t(workItem.employedStatus);
  const country = workItem.country?.name;
  const displayPublicCompanyBadge =
    displayPublicCompanies && workItem.company?.type === COMPANY_TYPE.Public;

  const startDate = (date: Date) => formatDate(date, EXPERT_WORK_TIME_FORMAT);
  const endDate = (date: Date | null) =>
    date ? formatDate(date, EXPERT_WORK_TIME_FORMAT) : t("present");

  const showCountryTitle = () => {
    if (workItem.title && workItem.country) {
      return (
        <DangerouslySetInnerHTML
          text={`${workItem.title ?? ""} · ${country}`}
          fontSize="14px"
          fontWeight={300}
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        />
      );
    }
    if (workItem.title && !workItem.country) {
      return (
        <DangerouslySetInnerHTML
          text={workItem.title}
          fontSize="14px"
          fontWeight={300}
          color={ARBOLUS_COLORS.bColorSecondaryGreyDark}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.companyName} title={companyName}>
        {companyName}
        {displayPublicCompanyBadge && <PublicCompanyBadge date={workItem.to} />}
      </h1>
      <div className={styles.titleAndCountry}>{showCountryTitle()}</div>
      <h3 className={styles.jobTenure}>{`${startDate(
        workItem.from
      )} - ${endDate(workItem.to ?? null)}`}</h3>
    </div>
  );
};
