import { Tag } from "antd";
import React from "react";

import { AntDIcon } from "@arbolus-technologies/antDComponents";

import styles from "./CompanyBadge.module.scss";

interface CompanyBadgeProps {
  name: string;
  visible: boolean;
  onClick: () => void;
}

export function CompanyBadge({ name, visible, onClick }: CompanyBadgeProps) {
  return (
    <Tag
      onClick={onClick}
      icon={
        <AntDIcon
          name={visible ? "visibility" : "visibility_off"}
          className={styles.badgeIcon}
          fontSize="12px"
          inline
        />
      }
      style={visible ? undefined : { opacity: "50%" }}
    >
      {name}
    </Tag>
  );
}
