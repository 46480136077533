import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { GridApi } from "ag-grid-community";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

export interface OpenExpertProfileParams {
  expertId: string;
}

interface CustomerSurveyGridContext {
  openExpertProfile: (openExpertProfileParams: OpenExpertProfileParams) => void;
}

export const useCustomerSurveyGridContext = (): CustomerSurveyGridContext => {
  const dispatch = useDispatch();

  return useMemo<CustomerSurveyGridContext>(
    () => ({
      openExpertProfile: (params) => {
        dispatch(
          PanelStoreActions.openPanel(PanelId.ReferralExpertPanel, params)
        );
      }
    }),
    [dispatch]
  );
};
