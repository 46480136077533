import { User, UserProfile } from "@arbolus-technologies/api";

import {
  GET_ADMIN_USER_PROFILE,
  GetAdminUserProfileAction,
  UPDATE_ADMIN_USER_PROFILE
} from "./CacheAdminUserProfileActionTypes";

export const getAdminUserProfile = (
  userProfile: UserProfile
): GetAdminUserProfileAction => ({
  type: GET_ADMIN_USER_PROFILE,
  payload: {
    userProfile
  }
});

export const updateAdminUserProfile = (user: User) => ({
  type: UPDATE_ADMIN_USER_PROFILE,
  payload: {
    user
  }
});
