import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import { SelectOption } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

interface CountrySelectorProps {
  selectedCountry?: SelectOption;
  disabled: boolean;
  onChange: (selectedCountry: SelectOption) => void;
}

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  selectedCountry,
  disabled,
  onChange
}) => {
  const { t } = useTranslation("companySelector");

  const [countries, setCountries] = useState<SelectOption[]>([]);

  const countriesOptions = useSelector(CacheSelector.countriesOptions());

  const handleGetItems = (query: string) => {
    const filteredCountries = countriesOptions.filter((country) =>
      country.label.toLowerCase().includes(query.toLowerCase())
    );
    setCountries(filteredCountries);
  };

  const handleOnChange = (value: AntDSelectValueType) => {
    const selectedCountry = countries.find(
      (country) => country.value === value
    );
    onChange(selectedCountry!);
  };

  return (
    <AntDSelectMenu
      options={countries}
      placeholder={t("placeholder")}
      optionFilterProp="label"
      disabled={disabled}
      value={selectedCountry}
      onChange={handleOnChange}
      onGetItems={handleGetItems}
      handleClearList={() => setCountries([])}
    />
  );
};
