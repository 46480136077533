import {
  CIQError,
  CompleteValidatorErrors,
  Country,
  Currency,
  ErrorResponse,
  Industry,
  ProviderIndustry,
  Region,
  TimeZone,
  UserNotificationCounts
} from "@arbolus-technologies/api";
import { Permission } from "@arbolus-technologies/models/common";

export const CACHE_GET_APP_DATA = "CACHE_GET_APP_DATA";
export const CACHE_GET_APP_DATA_SUCCESS = "CACHE_GET_APP_DATA_SUCCESS";
export const CACHE_GET_APP_DATA_FAILURE = "CACHE_GET_APP_DATA_FAILURE";

export interface GetAppDataAction {
  type: typeof CACHE_GET_APP_DATA;
}

export interface GetAppDataSuccessAction {
  type: typeof CACHE_GET_APP_DATA_SUCCESS;
  payload: {
    countries: Country[];
    industriesLevel0: Industry[];
    industriesLevel2: Industry[];
    providerIndustries: ProviderIndustry[];
    timezones: TimeZone[];
    regions: Region[];
    currencies: Currency[];
    notificationCounts: UserNotificationCounts;
    specialPermissions?: Permission[];
  };
}

export interface GetAppDataFailureAction {
  type: typeof CACHE_GET_APP_DATA_FAILURE;
  payload: {
    error: ErrorResponse<CIQError> | CompleteValidatorErrors;
  };
}
