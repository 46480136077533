import { Card, Col, Divider, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  PROJECT_REFERRAL_STATE,
  ProjectService,
  ReferralDetail,
  ToasterService,
  UpdateReferralRequest
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import ProjectExpertEvents from "../../constants/ProjectExpertEvents";
import { AvatarExpertStatusIconInfo } from "./AvatarExpertStatusIconInfo";
import { HeaderActions } from "./HeaderActions";
import { Statuses } from "./Statuses";

interface ExpertInfoProps {
  expert: ExpertDetail;
  referral: ReferralDetail | null;
  isVerified: boolean;
  projectId: string;
  showProfileOnly: boolean;
  onAddNote: () => void;
  onReferralRemoval?: (referralId: string) => void;
  onRejectExpert: () => void;
  onMoveToAvailabilityTab: () => void;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ExpertInfo: React.FC<ExpertInfoProps> = ({
  expert,
  referral,
  isVerified,
  projectId,
  showProfileOnly,
  onAddNote,
  onReferralRemoval,
  onRejectExpert,
  onMoveToAvailabilityTab,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralsTableExpertInfo");

  const [isCandidate, setIsCandidate] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);

  const metadataCountries = useSelector(CacheSelector.metadataCountries());
  const expertCountry = metadataCountries.find(
    (country) => country.threeLetterCode === expert.user.isoCountryCode
  );

  useEffect(() => {
    setIsCandidate(referral?.status === PROJECT_REFERRAL_STATE.CANDIDATE);
    setIsApproved(referral?.review.subStatus === REFERRAL_SUB_STATE.ACCEPT);
  }, [referral]);

  const handleUpdateReferralState = (
    state: UpdateReferralRequest,
    isFastTrack?: boolean
  ): void => {
    if (referral) {
      setIsApproving(true);
      projectService
        .updateReferralState(projectId, referral.id, state)
        .subscribe(
          (response) => {
            if (isFastTrack) {
              ProjectExpertEvents.fastTrackReferral(referral.id, response);
            } else {
              ProjectExpertEvents.updateReferral(referral.id, response);
            }

            if (state.review === REFERRAL_SUB_STATE.ACCEPT) {
              setIsCandidate(false);
            }

            setIsApproving(false);
            setIsApproved(true);
            setShowApproveModal(false);
            notificationService.showSuccess(t("updateSuccess"));
          },
          (error: ErrorResponse<CIQError>) => {
            notificationService.showError(error.message);
            setIsApproving(false);
          }
        );
    }
  };

  return (
    <>
      <Card>
        <Row gutter={24}>
          <Col span={18}>
            <AntDAvatar
              firstName={expert.user.firstName}
              lastName={expert.user.lastName}
              profileImageUrl={expert.user.profileImageUrl}
              size={64}
              title={
                showProfileOnly
                  ? expert.user.title
                  : (referral?.tagline ?? expert.user.title)
              }
              isVerified={isVerified}
              lastPublicCompanyExpDate={expert.lastPublicCompanyExpDate}
              linkedInProfile={expert.linkedinProfile}
              countryTwoLetterCode={expertCountry?.twoLetterCode ?? null}
              countryName={expertCountry?.name ?? ""}
              angle={!showProfileOnly ? referral?.angle : undefined}
              icon={
                !showProfileOnly &&
                referral && (
                  <AvatarExpertStatusIconInfo
                    referralStatus={referral.status}
                  />
                )
              }
              mainGap={16}
            />
          </Col>
          <Col span={6}>
            {!showProfileOnly && referral && (
              <HeaderActions
                expert={expert}
                referral={referral}
                isCandidate={isCandidate}
                onAddNote={onAddNote}
                onRejectExpert={onRejectExpert}
                onApproveExpert={() => setShowApproveModal(true)}
                onReferralRemoval={onReferralRemoval}
                onUpdateReferralState={handleUpdateReferralState}
                onMoveToAvailabilityTab={onMoveToAvailabilityTab}
              />
            )}
          </Col>
        </Row>
        {!showProfileOnly && referral && (
          <>
            <Divider />
            <Statuses
              referral={referral}
              isDnc={expert.doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC}
              isCandidate={isCandidate}
              isApproved={isApproved}
              onRejectExpert={onRejectExpert}
              onApproveExpert={() => setShowApproveModal(true)}
            />
          </>
        )}
      </Card>
      <Modal
        open={showApproveModal}
        closable={false}
        title={t("approveExpertTitle")}
        okText={t("approveExpertConfirmText")}
        okButtonProps={{
          onClick: () =>
            handleUpdateReferralState({ review: REFERRAL_SUB_STATE.ACCEPT }),
          disabled: isApproving
        }}
        cancelText={t("cancel")}
        cancelButtonProps={{
          onClick: () => setShowApproveModal((prev) => !prev),
          disabled: isApproving
        }}
      >
        {t("approveExpertSubtitle")}
      </Modal>
    </>
  );
};
