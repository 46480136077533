import React from "react";

import { ExpertDetail, ReferralNote } from "@arbolus-technologies/api";

import { CallPrice } from "./CallPrice";
import { Endorsements } from "./Endorsements";
import { ProjectNotes } from "./ProjectNotes";
import { WorkHistories } from "./WorkHistories/WorkHistories";

interface ProfileProps {
  addNewNote?: boolean;
  expert: ExpertDetail;
  note?: ReferralNote;
  referralId?: string;
  showProfileOnly: boolean;
  onSaveNote?: (note?: string) => void;
  onCancelNote?: () => void;
}

export const Profile: React.FC<ProfileProps> = ({
  expert,
  note,
  addNewNote = false,
  referralId,
  showProfileOnly,
  onSaveNote,
  onCancelNote
}) => {
  return (
    <>
      <CallPrice expert={expert} showProfileOnly={showProfileOnly} />
      {!showProfileOnly && (note?.note || addNewNote) && referralId && (
        <ProjectNotes
          addNewNote={addNewNote}
          note={note}
          customers={expert.customers}
          expertId={expert.id}
          onSaveNote={onSaveNote}
          onCancelNote={onCancelNote}
          referralId={referralId}
          currentWorkHistoryId={
            expert.workHistories.find((wh) => wh.isCurrentWork)?.id
          }
        />
      )}
      {expert.workHistories.length > 0 && (
        <WorkHistories
          workHistories={expert.workHistories}
          lastPublicCompanyExpDate={expert.lastPublicCompanyExpDate}
        />
      )}
      {!showProfileOnly && expert.endorsements.length > 0 && (
        <Endorsements endorsements={expert.endorsements} />
      )}
    </>
  );
};
