import { useEffect, useState } from "react";

import {
  DefaultToasterService,
  ExpertTrainingModel,
  ExpertTrainingService
} from "@arbolus-technologies/api";

export function useTrainings() {
  const [trainings, setTrainings] = useState<ExpertTrainingModel[]>([]);

  useEffect(function fetchTrainings() {
    ExpertTrainingService.getExpertPendingTrainings()
      .then(({ expertPendingTrainings }) =>
        setTrainings(expertPendingTrainings)
      )
      .catch(DefaultToasterService.showApiErrors);
  }, []);

  function acceptTraining(trainingId: string) {
    return ExpertTrainingService.acknowledgeExpertTraining(trainingId).catch(
      (error) => DefaultToasterService.showApiErrors(error)
    );
  }

  return {
    trainings,
    acceptTraining
  };
}
