import React from "react";
import { useTranslation } from "react-i18next";

import { useIsChatAvailable } from "@arbolus-technologies/features/common";
import { PROJECT_CHAT_BASE_ROUTE } from "@arbolus-technologies/routes";
import {
  LinkButton,
  NotificationDot
} from "@arbolus-technologies/ui/components";

import { ExpertProjectNotificationsSlidePanel } from "../ProjectNotificationsSlidePanel/ExpertProjectNotificationsSlidePanel";

import { Switch } from "antd";
import styles from "./HeaderButtons.module.scss";

interface ExpertHeaderButtonProps {
  projectId: string;
  unreadMessagesCount?: number;
  isDnc: boolean;
  toggleSnow: () => void;
  isChristmasMode: boolean;
  isSnowEnabled: boolean;
}

export const ExpertHeaderButtons: React.FC<ExpertHeaderButtonProps> = ({
  projectId,
  unreadMessagesCount,
  isDnc,
  toggleSnow,
  isChristmasMode,
  isSnowEnabled
}) => {
  const { t } = useTranslation("projectDashboard");

  const chatUrl = PROJECT_CHAT_BASE_ROUTE(projectId);
  const displayChatButton = useIsChatAvailable();
  const hasUnreadMessages =
    typeof unreadMessagesCount !== "undefined" && unreadMessagesCount > 0;
  const showNotificationDot = displayChatButton && hasUnreadMessages;

  return (
    <div className={styles.btnContainer}>
      {isChristmasMode && (
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <span>{t("letItSnow")}</span>
          <Switch checked={isSnowEnabled} onChange={toggleSnow} />
        </div>
      )}
      {!isDnc && <ExpertProjectNotificationsSlidePanel projectId={projectId} />}
      {displayChatButton && (
        <div className={styles.chatContainer}>
          {showNotificationDot && (
            <NotificationDot offsetTop="-8px" offsetRight="-8px" />
          )}
          <LinkButton text={t("chat")} to={chatUrl} endIcon="forum" />
        </div>
      )}
    </div>
  );
};
