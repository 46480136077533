import { sample } from "lodash";

import { AngleAntDColors } from "@arbolus-technologies/models/project";

export const angleColors: AngleAntDColors[] = [
  "#722ED1",
  "#1677FF",
  "#EB2F96",
  "#13C2C2",
  "#389E0D",
  "#FADB14",
  "#597EF7",
  "#FA8C16",
  "#FA541C",
  "#BAE637"
];

export const availableAngleColors = (
  allColors: string[],
  angles: { color: string }[]
): string[] => {
  // Extract used colors
  const usedColors = angles.map((angle) => angle.color);

  // Filter out colors that are already used
  return allColors.filter((color) => !usedColors.includes(color));
};

export const getRandomAngleColorFromAvailableColors = (
  availableAngleColors: string[]
): AngleAntDColors => sample(availableAngleColors) as AngleAntDColors;

export const getRandomAngleColor = (): AngleAntDColors =>
  sample(angleColors) as AngleAntDColors;
