import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import {
  AdminService,
  CIQError,
  ErrorResponse,
  Ownership as OwnershipType,
  ToasterService
} from "@arbolus-technologies/api";
import { CLIENT_EDIT_USER_ROUTE } from "@arbolus-technologies/routes";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import {
  InfiniteScrollV2,
  Loader,
  SearchInput
} from "@arbolus-technologies/ui/components";
import { SEARCH_DEBOUNCE_TIMEOUT_COMMON } from "@arbolus-technologies/utils";

import { ClientUserSlidePanel } from "../../ClientUserSlidePanel/ClientUserSlidePanel";
import { OwnershipItem } from "./OwnershipItem";
import { INITIAL_QUERY_PARAMS_COUNT, IQueryParams } from "./types";

import styles from "./Ownership.module.scss";

const notificationService = new ToasterService();

export interface OwnershipProps {
  userId: string;
  handleCloseTeamMemberSlidePanel?: () => void;
  adminService?: typeof AdminService;
}
export const Ownership: React.FC<OwnershipProps> = ({
  userId,
  handleCloseTeamMemberSlidePanel,
  adminService = AdminService
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ownerShipList, setOwnerShipList] = useState<OwnershipType[]>([]);
  const [ownerShipSelected, setOwnerShipSelected] = useState<OwnershipType>();
  const [queryParams, setQueryParams] = useState<IQueryParams>({
    limit: INITIAL_QUERY_PARAMS_COUNT,
    offset: 0,
    count: 0,
    searchTerm: ""
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("teamMemberSlidePanel");

  const fetchOwnerShipList = () => {
    adminService
      .getOwnerships(
        userId,
        queryParams.searchTerm,
        queryParams.searchTerm ? 0 : queryParams.offset,
        queryParams.limit
      )
      .subscribe({
        next: ({ items, pagination }) => {
          if (queryParams.searchTerm) {
            setOwnerShipList(items);
          } else {
            setOwnerShipList((prevState) => [...prevState, ...items]);
          }
          setQueryParams((prevState) => ({
            ...prevState,
            limit: pagination.limit,
            offset: queryParams.searchTerm
              ? 0
              : pagination.offset + INITIAL_QUERY_PARAMS_COUNT,
            count: pagination.count
          }));
          setIsLoading(false);
        },
        error: (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchOwnerShipList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams.searchTerm]);

  const onQueryChange = (query: string) => {
    setIsLoading(true);
    setQueryParams((prevState) => ({
      ...prevState,
      searchTerm: query
    }));
    if (query === "") {
      setOwnerShipList([]);
    }
  };

  const handleBottomReached = () => {
    if (queryParams.count > queryParams.offset) {
      return fetchOwnerShipList();
    }
    return null;
  };

  const renderOwnership = (item: OwnershipType): JSX.Element => (
    <OwnershipItem
      ownership={item}
      key={item.id}
      onClick={() => {
        setOwnerShipSelected(item);
        dispatch(PanelStoreActions.openPanel(PanelId.ClientProfile));
      }}
    />
  );

  return (
    <>
      <div>
        <SearchInput
          containerClassnames={styles.searchInput}
          isDebounced
          debouncingTime={SEARCH_DEBOUNCE_TIMEOUT_COMMON}
          placeholder={t("searchInputPlaceholder")}
          onQueryChange={onQueryChange}
          hasSearchIcon
        />
        {isLoading && <Loader />}
        {ownerShipList.length > 0 && (
          <div className={styles.infiniteScrollContainer}>
            <InfiniteScrollV2
              isLoading={isLoading}
              onBottomReached={handleBottomReached}
              items={ownerShipList}
              renderer={renderOwnership}
              customGap={[0, 0]}
            />
          </div>
        )}
        {!isLoading && ownerShipList.length === 0 && (
          <span>{t("noOwnershipFound")}</span>
        )}
      </div>

      <ClientUserSlidePanel
        goToClientEditPage={() =>
          history.push(
            CLIENT_EDIT_USER_ROUTE(
              ownerShipSelected?.clientId!,
              ownerShipSelected?.id!
            )
          )
        }
        user={ownerShipSelected!}
        clientName={ownerShipSelected?.clientName}
        clientId={ownerShipSelected?.clientId}
        onClose={handleCloseTeamMemberSlidePanel}
        showBackTitle
      />
    </>
  );
};
