import {
  FilterModel,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

import {
  AnswerDetails,
  CustomersService,
  DefaultToasterService,
  GetSurveyAnswersDetailsQueryParams
} from "@arbolus-technologies/api";
import {
  filterToQueryParams,
  filterValueArrayToQueryParams
} from "@arbolus-technologies/utils";

import { FIELD_NAMES } from "./companyDataTableDefinition";

export class SurveyAnswersDataSource implements IServerSideDatasource {
  private static readonly ARRAY_FILTERS = [
    { filterName: FIELD_NAMES.PERSONA, paramName: "personas" },
    { filterName: FIELD_NAMES.RENEWAL_INTENT, paramName: "renewalIntents" },
    {
      filterName: FIELD_NAMES.IMPLEMENTATION_STAGE,
      paramName: "implementationStages"
    },
    { filterName: FIELD_NAMES.ANSWER_STATUS, paramName: "answerStatuses" }
  ];

  private static readonly TEXT_FILTERS = [
    {
      filterName: FIELD_NAMES.VENDOR_COMPANY_NAME,
      paramName: "vendorCompanyNames"
    },
    { filterName: FIELD_NAMES.EXPERT_CURRENT_COMPANY_NAME },
    { filterName: FIELD_NAMES.EXPERT_NAME },
    { filterName: FIELD_NAMES.NPS },
    { filterName: FIELD_NAMES.COMPETITORS_NAMES, paramName: "competitorName" }
  ];

  private readonly _surveyId?: string;

  constructor(surveyId?: string) {
    this._surveyId = surveyId;
  }

  getRows(params: IServerSideGetRowsParams<AnswerDetails, never>): void {
    const { success, fail, request } = params;

    CustomersService.getSurveyAnswersDetails(this.getQueryParams(request))
      .then((response) => {
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      })
      .catch((error) => {
        DefaultToasterService.showError(
          JSON.stringify(error?.response ?? error)
        );
        fail();
      });
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): GetSurveyAnswersDetailsQueryParams {
    const { startRow, endRow } = request;
    const filterModel = request.filterModel as FilterModel;

    let queryParams: GetSurveyAnswersDetailsQueryParams = {
      limit: endRow! - startRow!,
      offset: startRow!
    };

    if (this._surveyId) {
      queryParams.surveyIds = [this._surveyId];
    }

    queryParams = {
      ...queryParams,
      ...SurveyAnswersDataSource.createTextFilterParams(filterModel),
      ...SurveyAnswersDataSource.createArrayFilterParams(filterModel)
    };

    // Date filters
    const filterModified = filterModel["modified"];
    if (filterModified?.type === "lessThan") {
      // before
      queryParams.modifiedEnd = filterModel["modified"].dateFrom;
    } else if (filterModified?.type === "greaterThan") {
      // after
      queryParams.modifiedStart = filterModel["modified"].dateFrom;
    } else if (filterModified?.type === "inRange") {
      queryParams.modifiedStart = filterModel["modified"].dateFrom;
      queryParams.modifiedEnd = filterModel["modified"].dateTo;
    }

    return queryParams;
  }

  private static createArrayFilterParams(filterModel: FilterModel) {
    let queryParams = {};
    for (const filter of SurveyAnswersDataSource.ARRAY_FILTERS) {
      queryParams = {
        ...queryParams,
        ...filterValueArrayToQueryParams(
          filterModel,
          filter.filterName,
          filter.paramName
        )
      };
    }
    return queryParams;
  }

  private static createTextFilterParams(filterModel: FilterModel) {
    let filterParams = {};
    for (const textFilter of SurveyAnswersDataSource.TEXT_FILTERS) {
      filterParams = {
        ...filterParams,
        ...filterToQueryParams(
          filterModel,
          textFilter.filterName,
          textFilter.paramName
        )
      };
    }
    return filterParams;
  }
}
