import { Flex, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AntDSelectMenu,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  ApiErrorResponse,
  ApiPaginatedRequest,
  CIQError,
  CityResponse,
  DefaultToasterService,
  MetaService,
  SORT_DIRECTION,
  ToasterService
} from "@arbolus-technologies/api";

const { Text } = Typography;

interface CitySelectorProps {
  countryId: string;
  selectedCity?: CityResponse;
  disabled: boolean;
  onChange: (selectedCity: CityResponse) => void;
  metaService?: typeof MetaService;
  notificationService?: ToasterService;
}

export const CitySelector: React.FC<CitySelectorProps> = ({
  countryId,
  selectedCity,
  disabled,
  onChange,
  metaService = MetaService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("citySelector");

  const [citiesList, setCitiesList] = useState<CityResponse[]>([]);

  const handleGetItems = (query: string, offset = 0) => {
    const pagination: ApiPaginatedRequest = {
      offset,
      orderBy: "firstName",
      searchTerm: query,
      orderDirection: SORT_DIRECTION.DESCENDING,
      limit: 3
    };

    metaService
      .getCities({
        countryId,
        ...pagination
      })
      .subscribe(
        ({ items }) => {
          const newList = items.map((item) => ({ ...item, value: item.id }));
          setCitiesList((prevState) =>
            (offset === 0 ? [] : prevState).concat(newList)
          );
        },
        (error: ApiErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  const handleOnChange = (value: AntDSelectValueType) => {
    const selectedCity = citiesList.find((city) => city.id === value);
    onChange(selectedCity!);
  };

  return (
    <AntDSelectMenu
      options={citiesList}
      placeholder={t("placeholder")}
      optionFilterProp="children"
      disabled={disabled}
      value={
        selectedCity
          ? {
              label: selectedCity.name,
              value: selectedCity.id
            }
          : null
      }
      showLoadMoreButton
      optionRenderer={(option) => (
        <Flex>
          <Text>{option.data.name}</Text>
          {option.data.stateCode && <Text> ({option.data.stateCode})</Text>}
        </Flex>
      )}
      onChange={handleOnChange}
      onGetItems={handleGetItems}
      handleClearList={() => setCitiesList([])}
    />
  );
};
