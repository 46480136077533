import { Card, Carousel, Flex, Typography } from "antd";
import moment from "moment";
import React from "react";

import { AntDAvatar } from "@arbolus-technologies/antDComponents";
import { Endorsement } from "@arbolus-technologies/api";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

const { Text } = Typography;

interface EndorsementsProps {
  endorsements: Endorsement[];
}

export const Endorsements: React.FC<EndorsementsProps> = ({ endorsements }) => {
  return (
    <Carousel
      autoplay
      draggable
      autoplaySpeed={5000}
      style={{ paddingBottom: 24 }}
    >
      {endorsements.map((endorsement) => (
        <Card
          key={endorsement.endorserId}
          styles={{ title: { fontWeight: 400 } }}
          title={
            <AntDAvatar
              profileImageUrl={endorsement.endorser.profileImageUrl}
              firstName={endorsement.endorser.firstName}
              lastName={endorsement.endorser.lastName}
              title={endorsement.endorser.title}
              size="large"
              mainGap={16}
            />
          }
        >
          <Flex vertical gap={16}>
            <Text>{`"${endorsement.quote}"`}</Text>
            <Text code>
              {moment(endorsement.created).format(APP_DATE_FORMAT)}
            </Text>
          </Flex>
        </Card>
      ))}
    </Carousel>
  );
};
