import { LocationDescriptorObject } from "history";

export const CUSTOMERS_BASE = "/customers";

export const CUSTOMERS_ROUTE = (): LocationDescriptorObject => ({
  pathname: CUSTOMERS_BASE
});

export const EXPERT_SURVEY = `${CUSTOMERS_BASE}/surveys/:surveyId`;
export const EXPERT_SURVEY_ROUTE = (
  surveyId: string
): LocationDescriptorObject => ({
  pathname: `${CUSTOMERS_BASE}/surveys/${surveyId}`
});

export const CUSTOMERS_SURVEYS_ANSWERS = `${CUSTOMERS_BASE}/surveys/:surveyId/answers`;
export const CUSTOMERS_SURVEYS_ANSWERS_ROUTE = (
  surveyId: string
): LocationDescriptorObject => ({
  pathname: `${CUSTOMERS_BASE}/surveys/${surveyId}/answers`
});

export const CUSTOMERS_ADMIN_ROUTES = [
  CUSTOMERS_BASE,
  CUSTOMERS_SURVEYS_ANSWERS
];
export const CUSTOMERS_CLIENT_ROUTES = [EXPERT_SURVEY];
