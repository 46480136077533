import React, { ReactNode } from "react";

import {
  DO_NOT_CONTACT_STATUS,
  DateString
} from "@arbolus-technologies/models/common";
import { linkedin } from "@arbolus-technologies/theme";
import { displayUserName } from "@arbolus-technologies/utils";

import { Avatar, AvatarType } from "../Avatar/Avatar";
import { DNCTag } from "../DNCTag/DNCTag";
import { DangerouslySetInnerHTML } from "../DangerouslySetInnerHTML/DangerouslySetInnerHTML";
import { IconWithText } from "../IconWithText/IconWithText";
import { PublicCompanyIcon } from "../SquareWithIcon/Wrappers/PublicCompanyIcon";

import styles from "./ArbolusAvatar.module.scss";

interface ArbolusAvatarProps {
  firstName: string;
  lastName?: string;
  avatarType?: AvatarType;
  profileImageUrl?: string | null;
  withGreenTick?: boolean;
  title?: string | null;
  composedTitle?: ReactNode;
  countryTwoLetterCode?: string | null;
  lastPublicCompanyDate?: DateString | null;
  countryName?: string;
  linkedInProfile?: string;
  doNotContactStatus?: DO_NOT_CONTACT_STATUS;
  isAdmin?: boolean;
}

export const ArbolusAvatar: React.FC<ArbolusAvatarProps> = ({
  firstName,
  lastName,
  avatarType = "smallCircle",
  profileImageUrl,
  withGreenTick = false,
  title,
  composedTitle,
  countryTwoLetterCode,
  lastPublicCompanyDate,
  countryName,
  linkedInProfile,
  doNotContactStatus,
  isAdmin = false
}) => {
  const expertFullName = displayUserName({ firstName, lastName });

  return (
    <div className={styles.container}>
      <Avatar
        avatar={{
          name: expertFullName,
          imageUrl: profileImageUrl ?? ""
        }}
        type={avatarType}
        withGreenTick={withGreenTick}
      />
      <div className={styles.expertDataContainer}>
        <div className={styles.expertNameAndCountry}>
          <p className={styles.expertName} title={expertFullName}>
            {expertFullName}
          </p>
          <div className={styles.flagAndCompanyContainer}>
            {linkedInProfile && (
              <IconWithText
                img={linkedin}
                onClick={() => window.open(linkedInProfile, "_blank")}
                customClasses={styles.linkedInIcon}
              />
            )}
            {lastPublicCompanyDate && (
              <PublicCompanyIcon
                date={lastPublicCompanyDate}
                customFontSize="24px"
              />
            )}
            {countryTwoLetterCode && (
              <span
                className={`flag-icon flag-icon-${countryTwoLetterCode.toLowerCase()}`}
                id="countryFlag"
                title={countryName}
              />
            )}
            {doNotContactStatus && (
              <DNCTag
                dncStatus={doNotContactStatus}
                showTooltip
                isAdmin={isAdmin}
              />
            )}
          </div>
        </div>
        {!title && composedTitle}
        {title && !composedTitle && <DangerouslySetInnerHTML text={title} />}
      </div>
    </div>
  );
};
