import { GridApi } from "ag-grid-community";
import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export function ExportStatusBar({ api }: { api: GridApi }) {
  const { t } = useTranslation("companyDataTable");

  const [selectedRowsCount, setSelectedRowsCount] = useState(0);

  // biome-ignore lint/correctness/useExhaustiveDependencies: run this only once!
  useEffect(() => {
    api.setGridOption("onSelectionChanged", () => {
      setSelectedRowsCount(api.getSelectedRows().length);
    });
  }, []);

  const exportText =
    selectedRowsCount > 0
      ? t("exportButtonSelected", { selectedRowsCount })
      : t("exportButtonAll");

  function exportData() {
    const userHadSelectedRows = selectedRowsCount > 0;
    api.exportDataAsCsv({
      onlySelectedAllPages: userHadSelectedRows
    });
  }

  return (
    <Button onClick={exportData} size="small">
      {exportText}
    </Button>
  );
}
