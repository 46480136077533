import { Card, Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  Compliance as ComplianceType,
  ReferralDetail
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";

import { ComplianceAnswers } from "./ComplianceAnswers";
import { ComplianceForm } from "./ComplianceForm";

const { Text } = Typography;

type ReferralComplianceProps = Pick<
  ReferralDetail,
  "application" | "compliance" | "complianceNote" | "chaperoneCall"
>;

interface ComplianceProps {
  complianceInfo: ComplianceType[];
  referral: ReferralComplianceProps;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  expertId: string;
  onUpdateComplianceAnswers: (answers: ComplianceType[]) => void;
}

export const Compliance: React.FC<ComplianceProps> = ({
  complianceInfo,
  referral,
  doNotContactStatus,
  expertId,
  onUpdateComplianceAnswers
}) => {
  const {
    application,
    compliance,
    chaperoneCall = false,
    complianceNote
  } = referral;
  const { t } = useTranslation("referralCompliance");

  const isApplicationPending =
    application.subStatus === REFERRAL_SUB_STATE.PENDING;

  return (
    <Card>
      <Flex vertical gap={16}>
        <Text strong>{t("complianceQuestions")}</Text>
        {!isApplicationPending ||
        doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC ? (
          <ComplianceAnswers
            complianceInfo={complianceInfo}
            complianceStatus={compliance.subStatus}
            chaperoneCall={chaperoneCall}
            complianceNote={complianceNote}
            complianceUpdatedBy={compliance.updateUser}
            complianceUpdateDate={compliance.updateDate}
          />
        ) : (
          <ComplianceForm
            complianceInfo={complianceInfo}
            expertId={expertId}
            onUpdateComplianceAnswers={onUpdateComplianceAnswers}
          />
        )}
      </Flex>
    </Card>
  );
};
