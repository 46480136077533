export interface CompanyBase {
  id: string;
  name: string;
  logoUrl?: string;
}

export enum COMPANY_TYPE {
  Public = "Public",
  Private = "Private",
  Unknown = "Unknown"
}

export interface CompanyFormData {
  name: string;
  type: COMPANY_TYPE;
  linkedinUrl?: string;
  linkedinUrn?: string;
  website?: string;
  isDnc: boolean;
  doNotContactKeywords: string[];
  industryId?: string;
  industry?: string;
}
