import {
  AngleModel,
  EventModel,
  ExpertTaglineModel,
  TranscriptModel,
  TranscriptSpeaker
} from "@arbolus-technologies/models/common";

import { MultiSortPaginatedRequest } from "./api";
import { Monologue } from "./common";

export interface Transcript extends TranscriptMeta {
  id: string;
  transcriptionData: TranscriptData;
}

export interface TranscriptMeta {
  title: string;
  created: Date;
  durationSeconds: number;
  clientName: string;
  eventId: string;
  clientLogoUrl?: string;
  recordingUrl?: string;
  humanTranscriptRequestSent?: boolean;
  expiresInDays: number;
}

export interface TranscriptData {
  speakers: TranscriptSpeaker[];
  monologues: Monologue[];
  highlightCount: number;
}

export interface UpdateSpeakerResponse {
  speakers: TranscriptSpeaker[];
}

export interface TranscriptHighLight {
  id: string;
  userId: string;
  createdDate: Date;
  startIndex: number;
  endIndex: number;
}

export interface CreateTranscriptHighlightRequest {
  monologueId: string;
  startIndex: number;
  endIndex: number;
  text: string;
}

export enum LIST_TRANSCRIPTS_ORDER_BY {
  CREATED = "Created",
  NAME = "Name",
  ANGLE_TITLE = "AngleTitle",
  DURATION = "DurationInSeconds",
  STATUS = "Status"
}

export interface ListTranscriptsParams
  extends MultiSortPaginatedRequest<LIST_TRANSCRIPTS_ORDER_BY> {
  angleId?: string | null;
}

export interface ListTranscriptModel extends TranscriptModel {
  event: EventModel;
  expert?: ExpertTaglineModel;
  angle?: AngleModel;
}
