import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { AntDButtonLink } from "@arbolus-technologies/antDComponents";
import {
  BaseClientMember,
  CIQError,
  ClientService,
  CreateUserResponse,
  DefaultToasterService,
  DocumentService,
  ErrorResponse,
  ToasterService
} from "@arbolus-technologies/api";
import { USERS } from "@arbolus-technologies/routes";
import {
  CacheSelector,
  CacheStoreActions
} from "@arbolus-technologies/stores/cache";
import { Loader } from "@arbolus-technologies/ui/components";
import { AntDHeader } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { ADD_ON_BEFORE, AddEditUserForm, UserForm } from "./AddEditUserForm";

import styles from "./AddEditUser.module.scss";

interface AddEditAdminUserProps {
  clientService?: typeof ClientService;
  documentService?: typeof DocumentService;
  notificationService?: ToasterService;
}

export const AddEditAdminUser: React.FC<AddEditAdminUserProps> = ({
  clientService = ClientService,
  documentService = DocumentService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("addEditAdminUser");
  const history = useHistory();
  const dispatch = useDispatch();
  const { userId } = useParams<{ userId?: string }>();
  useDocumentTitle("addEditAdminUser", t(userId ? "updateUser" : "newUser"));

  const [isSaving, setIsSaving] = useState(false);
  const [userDetails, setUserDetails] = useState<BaseClientMember>();

  const loggedInUser = useSelector(CacheSelector.userProfile());
  const loggedInUserClientId = loggedInUser.clientId;
  const loggedInUserId = loggedInUser.id;

  useEffect(() => {
    if (userId) {
      clientService.getUser(loggedInUserClientId, userId).subscribe(
        (user: BaseClientMember) => {
          setUserDetails(user);
        },
        (err: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(err);
          history.push(USERS);
        }
      );
    }
  }, [userId]);

  const getUserData = (data: UserForm) => {
    const {
      country,
      city,
      email,
      linkedinProfile,
      phoneNumber,
      profileImageUrl,
      ...restData
    } = data;

    return {
      ...restData,
      email: email.toLowerCase(),
      phoneNumber: phoneNumber ? `+${phoneNumber}` : "",
      linkedinProfile: linkedinProfile
        ? `${ADD_ON_BEFORE}${linkedinProfile}`
        : "",
      countryId: country.value,
      cityId: city?.id,
      clientUserProfile: {
        relationshipOwnerId: null,
        priorities: "",
        contactingPreferences: "",
        general: "",
        isRealNameEnabled: true,
        displayName: null,
        industryId: "",
        repositoryUrl: ""
      }
    };
  };

  const handleCreateUser = (data: UserForm) => {
    setIsSaving(true);

    clientService
      .createUser(loggedInUserClientId, getUserData(data))
      .pipe(
        switchMap(({ id }: CreateUserResponse) => {
          if (typeof data.profileImageUrl === "object") {
            const formData = new FormData();
            formData.append("file", data.profileImageUrl);

            return documentService.uploadUserProfileImage(id, formData);
          }
          return of(null);
        })
      )
      .subscribe(
        () => {
          setIsSaving(false);
          history.push(USERS);
        },
        (error: ErrorResponse<CIQError>) => {
          setIsSaving(false);
          notificationService.showApiErrors(error);
        }
      );
  };

  const handleEditUser = (data: UserForm) => {
    if (userId) {
      setIsSaving(true);

      const updatedData = getUserData(data);

      clientService
        .updateUser(loggedInUserClientId, userId, updatedData)
        .pipe(
          switchMap(() => {
            if (
              data.profileImageUrl &&
              typeof data.profileImageUrl === "object"
            ) {
              const formData = new FormData();
              formData.append("file", data.profileImageUrl);

              return documentService.uploadUserProfileImage(userId, formData);
            }
            return of(null);
          })
        )
        .subscribe(
          () => {
            if (userId === loggedInUserId) {
              dispatch(
                CacheStoreActions.updateAdminUserProfile({
                  ...updatedData,
                  id: userId
                })
              );
            }
            setIsSaving(false);
            history.push(USERS);
          },
          (error: ErrorResponse<CIQError>) => {
            setIsSaving(false);
            notificationService.showApiErrors(error);
          }
        );
    }
  };

  if (userId && !userDetails) {
    return <Loader isFull isCentered />;
  }

  return (
    <div className={styles.mainContainer}>
      <AntDHeader
        title={t(userId ? "updateUser" : "newUser")}
        backLink={USERS}
        rightContainer={
          <AntDButtonLink
            pathname={USERS}
            text={t("cancel")}
            disabled={isSaving}
          />
        }
      />
      <AddEditUserForm
        userDetails={userDetails}
        isSaving={isSaving}
        onSubmit={userId ? handleEditUser : handleCreateUser}
      />
    </div>
  );
};
