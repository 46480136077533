import { Icon } from "arbolus-ui-components";
import clsx from "clsx";
import dompurify from "dompurify";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  ProjectData,
  withProjectData
} from "@arbolus-technologies/features/common";
import {
  PROJECT_EDIT_ROUTE,
  PROJECT_ROUTE
} from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import {
  Avatar,
  Chip,
  Drawer,
  HR,
  LinkButton,
  NotSet
} from "@arbolus-technologies/ui/components";
import {
  APP_DATE_FORMAT,
  displayUserName,
  formatDate
} from "@arbolus-technologies/utils";

import { BackToProjectLink } from "../../components/SimplifiedCreateProject/BackToProjectLink/BackToProjectLink";
import { DrawerTitle } from "../../components/SimplifiedCreateProject/DrawerTitle/DrawerTitle";

import styles from "./SimplifiedProjectBriefPage.module.scss";

export const SimplifiedProjectBriefPage: React.FC<{
  projectData: ProjectData;
}> = withProjectData(({ projectData }) => {
  const { t } = useTranslation("projectBrief");
  const isExpert = useSelector(CacheSelector.isCurrentUserExpertSelector());
  const briefDescription = isExpert
    ? (projectData?.description ?? "")
    : (projectData?.internalDescription ?? "");

  const startDate = projectData.startDate ? (
    formatDate(projectData.startDate, APP_DATE_FORMAT)
  ) : (
    <NotSet>{t("noStartDate")}</NotSet>
  );
  const endDate = projectData.endDate
    ? formatDate(projectData.endDate, APP_DATE_FORMAT)
    : t("ongoing");

  const mainContactName = displayUserName(
    isExpert ? projectData.owner! : projectData.mainContactUser!,
    true
  );

  const mainContactNumber: string | undefined = isExpert
    ? projectData.owner?.phoneNumber
    : projectData?.adminContactNo;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.pageContainer}>
        <div className={styles.header}>
          <BackToProjectLink
            projectId={projectData.id}
            projectName={projectData.name}
          />
          <div className={styles.projectName}>
            <Link
              to={PROJECT_ROUTE(projectData.id)}
              className={styles.headerLink}
            >
              <Icon
                name="folder"
                fontSize="24px"
                color={ARBOLUS_COLORS.bColorBasePurple}
              />
              <h1>{t("brief")}</h1>
            </Link>
            {!isExpert && (
              <LinkButton
                text={t("edit")}
                to={PROJECT_EDIT_ROUTE(projectData.id)}
                endIcon="edit"
              />
            )}
          </div>
        </div>

        <HR margin={{ top: 3, bottom: 5 }} />

        <div className={styles.details}>
          <Drawer
            titleChildren={() => <h2>{t("projectDetails")}</h2>}
            openByDefault
            disableCollapse
          />
          {/* Details */}
          <Drawer openByDefault disableCollapse>
            <h3>{t("details")}</h3>
            <div className={styles.group}>
              <p className={styles.category}>{t("name")}</p>
              <Value>{projectData.name}</Value>
            </div>
            <p className={styles.category}>{t("brief")}</p>
            {briefDescription.length ? (
              <div
                className={clsx(styles.value, styles.brief)}
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(briefDescription)
                }}
              />
            ) : (
              <NotSet />
            )}
            <HR margin={{ top: 3, bottom: 3 }} />
            <div className={styles.group}>
              <p className={styles.category}>{t("projectTimezone")}</p>
              <Value>
                {projectData?.timezone ? (
                  projectData?.timezone.replaceAll("_", " ")
                ) : (
                  <NotSet />
                )}
              </Value>
            </div>
            <div className={styles.twoColumnGrid}>
              <div>
                <p className={styles.category}>{t("mainContact")}</p>
                <Chip>
                  <Avatar
                    avatar={{
                      name: mainContactName,
                      imageUrl: isExpert
                        ? projectData.owner?.profileImageUrl
                        : projectData.mainContactUser?.profileImageUrl
                    }}
                    fallBackImageClass={styles.avatar}
                  />
                  <span>{mainContactName}</span>
                </Chip>
              </div>
              <div>
                <p className={styles.category}>{t("phoneNumber")}</p>
                <Chip>{mainContactNumber ?? <NotSet />}</Chip>
              </div>
            </div>
          </Drawer>
          {/* Additional details */}
          <Drawer
            titleChildren={() => (
              <DrawerTitle title={t("additionalDetailsFormTitle")} />
            )}
          >
            <div className={styles.twoColumnGrid}>
              <div>
                <p className={styles.category}>{t("timeline")}</p>
                <Value>
                  <Chip>
                    {startDate} - {endDate}
                  </Chip>
                </Value>
              </div>
              <div>
                <p className={styles.category}>{t("caseCode")}</p>
                <Value>{projectData.caseCode ?? <NotSet />}</Value>
              </div>
            </div>
          </Drawer>
          {/* Ownership */}
          <Drawer
            titleChildren={() => (
              <DrawerTitle title={t("ownershipFormTitle")} />
            )}
          >
            <div className={clsx(styles.twoColumnGrid, styles.group)}>
              <div>
                <p className={styles.category}>{t("managerName")}</p>
                <Chip>
                  {projectData.managerName && (
                    <>
                      <Avatar
                        avatar={{
                          name: projectData.managerName
                        }}
                        fallBackImageClass={styles.avatar}
                      />
                      <span>{projectData.managerName}</span>
                    </>
                  )}
                </Chip>
              </div>
              <div>
                <p className={styles.category}>{t("managerEmail")}</p>
                <Chip>{projectData.managerEmail}</Chip>
              </div>
            </div>
            <div className={styles.twoColumnGrid}>
              <div>
                <p className={styles.category}>{t("partnerName")}</p>
                <Chip>
                  {projectData.partnerName && (
                    <>
                      <Avatar
                        avatar={{
                          name: projectData.partnerName
                        }}
                        fallBackImageClass={styles.avatar}
                      />
                      <span>{projectData.partnerName}</span>
                    </>
                  )}
                </Chip>
              </div>
              <div>
                <p className={styles.category}>{t("partnerEmail")}</p>
                <Chip>{projectData.partnerEmail}</Chip>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
});

const Value = ({ children }: { children: ReactNode }) => (
  <span className={styles.value}>{children}</span>
);
