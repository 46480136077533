import { CUSTOMERS_BASE } from "@arbolus-technologies/routes";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { CustomerSurveyAnswerTable } from "../../modules/CustomerSurveyAnswerTable/CustomerSurveyAnswerTable";

interface LocationState {
  surveyId: string;
  surveyTitle: string;
}

export const CustomerSurveysAnswerPage: React.FC = () => {
  const { t } = useTranslation("customerSurveyAnswerTable");

  const location = useLocation<LocationState>();
  const { surveyId, surveyTitle } = location.state || {};

  useDocumentTitle("customerSurveyAnswerTable", "pageTitle");

  return (
    <MainPageLayout title={surveyTitle} stickyHeader backLink={CUSTOMERS_BASE}>
      <CustomerSurveyAnswerTable surveyId={surveyId} />
    </MainPageLayout>
  );
};
