import { call_get, call_post } from "../SimpleRestService";
import { API_URL_V1 } from "../constants/api";
import { EXPERTS_API } from "../endpoints/experts";
import { ExpertTrainingModel } from "../models/expert";

export function getExpertPendingTrainings() {
  return call_get<{
    expertPendingTrainings: ExpertTrainingModel[];
  }>(API_URL_V1 + EXPERTS_API.GET_EXPERT_PENDING_TRAINING());
}

export function acknowledgeExpertTraining(expertTrainingId: string) {
  return call_post(
    API_URL_V1 + EXPERTS_API.ACKNOWLEDGE_EXPERT_TRAINING(expertTrainingId),
    {}
  );
}
