import { Observable } from "rxjs";

import { COMPANY_TYPE } from "@arbolus-technologies/models/common";

import { COMPANIES_API } from "../endpoints/company";
import { RELATIONSHIP_TYPE } from "../enums/companiesEnums";
import { getMultiSortParams } from "../helpers";
import {
  CompaniesPaginatedRequest,
  CreatedResponse,
  ObjectResponse,
  SuccessResponse
} from "../models/api";
import {
  Company,
  GetCompaniesResponse,
  UngroupCompanyResponse
} from "../models/company";
import { PatchValue } from "../restInterfaces";
import { restService } from "../restService";

export interface CompanyPOSTDetails {
  name: string;
  type?: COMPANY_TYPE;
  linkedinUrl?: string;
  website?: string;
  isDnc?: boolean;
  linkedinUrn?: string;
  doNotContactKeywords?: string[];
  industryId?: string;
}

interface CompanyPATCHDetails {
  website: PatchValue<string> | null;
  linkedinUrl: PatchValue<string> | null;
}

interface ChildCompanyItem {
  companyId: string;
  relationshipType: RELATIONSHIP_TYPE;
}

export interface MergeCompaniesBody {
  id: string;
  mergedCompanyIds: string[];
}

export interface GroupCompaniesBody {
  parentCompanyId: string;
  childCompanies: ChildCompanyItem[];
}

export const CompanyService = {
  listCompanies: (
    apiParams: CompaniesPaginatedRequest
  ): Observable<GetCompaniesResponse> =>
    restService.get(
      COMPANIES_API.GET_COMPANIES(),
      getMultiSortParams(apiParams)
    ),
  getCompany: (companyId: string): Observable<Company> =>
    restService.get(COMPANIES_API.GET_COMPANY(companyId)),
  addNewCompany: (body: CompanyPOSTDetails): Observable<CreatedResponse> =>
    restService.post(COMPANIES_API.ADD_COMPANY(), body),
  updateCompany: (
    companyId: string,
    body: CompanyPOSTDetails
  ): Observable<SuccessResponse> =>
    restService.put(COMPANIES_API.UPDATE_COMPANY(companyId), body),
  mergeCompanies: (body: MergeCompaniesBody): Observable<SuccessResponse> =>
    restService.post(COMPANIES_API.MERGE_COMPANIES(), body),
  groupCompanies: (body: GroupCompaniesBody): Observable<SuccessResponse> =>
    restService.put(COMPANIES_API.GROUP_COMPANIES(), body),
  ungroupCompany: (
    companyId: string
  ): Observable<ObjectResponse<UngroupCompanyResponse>> =>
    restService.put(COMPANIES_API.UNGROUP_COMPANY(companyId), {}),
  patchCompany: (
    companyId: string,
    body: CompanyPATCHDetails
  ): Observable<ObjectResponse<SuccessResponse>> =>
    restService.patch(COMPANIES_API.PATCH_COMPANY(companyId), body)
};
