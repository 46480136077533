import { Radio } from "antd";
import { useFeature } from "flagged";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ReferralsTableExpertProfileSidePanel } from "@arbolus-technologies/features/common";
import { FEATURE_FLAGS } from "@arbolus-technologies/models/common";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";

import { CompanyDataTable } from "./CompanyDataTable/CompanyDataTable";
import { CustomerSurveyTable } from "./CustomerSurveyTable/CustomerSurveyTable";

const TABS = [
  {
    key: "surveys",
    label: i18next.t("customerSurveyTable:surveysTab"),
    ChildComponent: CustomerSurveyTable
  },
  {
    key: "companyData",
    label: i18next.t("customerSurveyTable:companyDataTab"),
    ChildComponent: CompanyDataTable
  }
];

export const CustomerSurveysPage: React.FC = () => {
  const { t } = useTranslation("customerSurveyTable");

  useDocumentTitle("customerSurveyTable", "pageTitle");

  const [currentTab, setCurrentTab] = useState(0);

  const newReferralTableSidePanel = useFeature(
    FEATURE_FLAGS.NewReferralTableSidePanel
  );

  const { ChildComponent } = TABS[currentTab];

  return (
    <MainPageLayout title={t("pageTitle")} stickyHeader>
      <Radio.Group
        defaultValue={0}
        buttonStyle="solid"
        onChange={({ target: { value } }) => setCurrentTab(value)}
      >
        {TABS.map((tab, index) => (
          <Radio.Button key={tab.key} value={index}>
            {tab.label}
          </Radio.Button>
        ))}
      </Radio.Group>

      <ChildComponent />

      {newReferralTableSidePanel && (
        <ReferralsTableExpertProfileSidePanel showProfileOnly />
      )}
    </MainPageLayout>
  );
};
