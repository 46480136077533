import { Button, Card, Flex, Modal, Tag, Typography } from "antd";
import { LabeledValue } from "antd/es/select";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  AntDAvatar,
  AntDIcon,
  AntDSelectValueType
} from "@arbolus-technologies/antDComponents";
import {
  CIQError,
  Company,
  CustomersService,
  DefaultToasterService,
  ErrorResponse,
  ProjectService,
  RELATIONSHIP_TYPE,
  ReferralNote,
  ToasterService
} from "@arbolus-technologies/api";
import { CustomerCompanyInfo } from "@arbolus-technologies/models/project";
import {
  DangerouslySetInnerHTML,
  TiptapEditorMemoized
} from "@arbolus-technologies/ui/components";
import { APP_DATE_FORMAT } from "@arbolus-technologies/utils";

import { CompanySelector } from "../../../../../../../Selectors/CompanySelector/CompanySelector";
import ProjectExpertEvents from "../../../../../constants/ProjectExpertEvents";

const { Text } = Typography;

interface ProjectNotesProps {
  addNewNote: boolean;
  note?: ReferralNote;
  customers: CustomerCompanyInfo[];
  expertId: string;
  onSaveNote?: (note?: string) => void;
  onCancelNote?: () => void;
  referralId: string;
  currentWorkHistoryId?: string;
  projectService?: typeof ProjectService;
  customersService?: typeof CustomersService;
  notificationService?: ToasterService;
}

export const ProjectNotes: React.FC<ProjectNotesProps> = ({
  addNewNote,
  note,
  customers,
  expertId,
  onSaveNote,
  onCancelNote,
  referralId,
  currentWorkHistoryId,
  projectService = ProjectService,
  customersService = CustomersService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralProjectNotes");
  const { projectId } = useParams<{ projectId: string }>();

  const [isEditing, setIsEditing] = useState(addNewNote);
  const [isSaving, setIsSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>(
    customers.map((customer) => ({
      id: customer.companyId,
      name: customer.companyName,
      relationship: { type: RELATIONSHIP_TYPE.None }
    }))
  );

  const handleChangeCompaniesRelationships = (
    values: AntDSelectValueType,
    searchedCompanies: Company[]
  ) => {
    const companies = getSelectedCompanies(
      values,
      selectedCompanies,
      searchedCompanies
    );

    setSelectedCompanies(companies);
  };

  const getSelectedCompanies = (
    values: AntDSelectValueType,
    selectedCompanies: Company[],
    searchedCompanies: Company[]
  ) => {
    const updatedCompanies: Company[] = [];
    (values as LabeledValue[]).forEach((company) => {
      const selectedCompany =
        selectedCompanies.find((item) => item.id === company.value) ??
        searchedCompanies.find((item) => item.id === company.value);
      if (selectedCompany) {
        updatedCompanies.push(selectedCompany);
      }
    });

    return updatedCompanies;
  };

  const handleSave = () => {
    handleSaveNote(newNote);
    handleSaveCustomers(selectedCompanies);
  };

  const handleSaveNote = (note?: string) => {
    setIsSaving(true);
    projectService.addReferralNote(projectId, referralId, note).subscribe(
      () => {
        setIsSaving(false);
        setIsEditing(false);

        if (note) {
          ProjectExpertEvents.addNote(referralId);
        } else {
          ProjectExpertEvents.removeNote(referralId);
        }

        notificationService.showSuccess(
          note ? t("noteAddedSuccess") : t("noteRemoveSuccess")
        );
        onSaveNote?.(note);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsSaving(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  const handleSaveCustomers = (companies: Company[]) => {
    setIsSaving(true);
    const vendorCompanyIds = companies.map((company) => company.id);
    customersService
      .createInsight({
        expertId,
        vendorCompanyIds,
        sourceType: "Admin",
        workHistoryId: currentWorkHistoryId
      })
      .subscribe(
        () => {
          setIsSaving(false);
        },
        (error) => {
          setIsSaving(false);
          notificationService.showApiErrors(error);
        }
      );
  };

  const handleDeleteNote = () => {
    handleSaveNote();
    handleSaveCustomers([]);
    setShowDeleteModal(false);
  };

  return (
    <>
      <Card>
        <Flex vertical gap={16}>
          <Flex gap={16} justify="space-between" align="center">
            <Flex gap={8}>
              <Text strong>{t("projectNote")}</Text>
              <Text type="secondary">{t("visibleToClients")}</Text>
            </Flex>
            {!isEditing && (
              <Flex gap={16}>
                <Button
                  icon={<AntDIcon name="edit" fontSize="14px" />}
                  onClick={() => setIsEditing(true)}
                />
                <Button
                  icon={<AntDIcon name="delete" fontSize="14px" />}
                  onClick={() => setShowDeleteModal(true)}
                />
              </Flex>
            )}
          </Flex>
          {isEditing && (
            <>
              <TiptapEditorMemoized
                placeholder={t("projectNote")}
                onChange={(content: string) => {
                  setNewNote(content);
                }}
                hasError={false}
                initialEditorState={note?.note ?? ""}
              />
              <Flex vertical gap={4}>
                <Text>{t("companyRelationship")}</Text>
                <CompanySelector
                  mode="multiple"
                  onChange={handleChangeCompaniesRelationships}
                  initialValue={selectedCompanies.map((company: Company) => ({
                    label: company.name,
                    value: company.id
                  }))}
                />
              </Flex>
              <Flex gap={16} justify="flex-end">
                <Button
                  onClick={() => {
                    setIsEditing(false);
                    onCancelNote?.();
                  }}
                  disabled={isSaving}
                >
                  {t("cancel")}
                </Button>
                <Button type="primary" onClick={handleSave} disabled={isSaving}>
                  {t("save")}
                </Button>
              </Flex>
            </>
          )}
          {!isEditing && (
            <>
              {note?.note && <DangerouslySetInnerHTML text={note?.note} />}
              {customers.length > 0 && (
                <Flex align="center" wrap style={{ rowGap: 8 }}>
                  <Text strong style={{ marginRight: "8px" }}>
                    {t("customerOf")}
                  </Text>
                  {customers.map((company) => (
                    <Tag key={company.companyId}>{company.companyName}</Tag>
                  ))}
                </Flex>
              )}
              {note?.noteUser && (
                <Flex gap={16} justify="space-between">
                  <Text code>
                    {moment(note.noteDate).format(APP_DATE_FORMAT)}
                  </Text>
                  <AntDAvatar
                    profileImageUrl={note.noteUser.profileImageUrl}
                    firstName={note.noteUser.firstName}
                    lastName={note.noteUser.lastName}
                    size="small"
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>
      </Card>
      <Modal
        title={t("deleteProjectNote")}
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal((prev) => !prev)}
        cancelText={t("cancel")}
        onOk={handleDeleteNote}
        okText={t("deleteNote")}
        okButtonProps={{ type: "primary", danger: true }}
      >
        {t("deleteProjectNoteDescription")}
      </Modal>
    </>
  );
};
