import { ArbolusFeatureFlags } from "@arbolus-technologies/models/common";

export interface Country {
  id: string;
  name: string;
  twoLetterCode: string;
  threeLetterCode: string;
  sortOrder: number;
  regionId?: string;
}

export interface Region {
  id: string;
  name: string;
  sortOrder: number;
}

export interface CityResponse {
  id: string;
  name: string;
  stateCode: string;
}

export interface CompanyResponse {
  id: string;
  name: string;
  logoUrl: string | null;
}

export interface Industry {
  id: string;
  name: string;
  naicsId: number;
  parentId?: string;
}
export interface IndustriesRequest {
  level: number;
}

export interface Currency {
  name: string;
  isoCurrencyCode: string;
  symbol: string;
}

export interface UserPermissions {
  featureFlags: ArbolusFeatureFlags;
  featureFlagsDefaults: ArbolusFeatureFlags;
}

export interface ExchangeRate {
  effectiveDate: string;
  isoCurrencyCode: string;
  rate: number;
}

export enum AgreementType {
  CLIENT = "ClientAgreement",
  EXPERT = "ExpertAgreement"
}

export interface AgreementTerm {
  termTitle: string;
  termText: string;
}

export interface EngagementAgreement {
  id: string;
  headerTitle: string;
  headerText: string;
  footerTitle: string;
  footerText: string;
  agreementTerms: AgreementTerm[];
  agreementUrl: string;
}

export interface BusinessEntitiesItem {
  id: string;
  identifier: string;
  address: string;
}

export interface BusinessEntities {
  items: BusinessEntitiesItem[];
}

export interface ProviderIndustry {
  value: string;
  title: string;
  children?: any[];
}
