export const normalize = (text: string): string =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

// Convert an array of numbers into a string of CSS units. Example: [1, 2, 3] => '8px 16px 24px'
export const formatUnit = (value: number): string => `${value * 8}px`;
export const formatUnits = (arr?: number[]): string => {
  const output = (arr ?? [0]).map(formatUnit);
  return output.join(" ");
};

export function valueOrFallback<T>(value: T, fallback: T): T {
  if (typeof value === "undefined" || value === null) {
    return fallback;
  } else {
    return value;
  }
}

export function isString(candidate: unknown) {
  return typeof candidate === "string" || candidate instanceof String;
}

export const twoLetterName = (
  firstName?: string,
  lastName?: string
): string => {
  if (!firstName && !lastName) {
    return "JD";
  }

  const combinedNames = [
    ...(firstName?.split(/\s/) ?? []),
    ...(lastName?.split(/\s/) ?? [])
  ];

  return (
    combinedNames
      .filter((part) => part)
      .map((part) => part[0].toUpperCase())
      .slice(0, 2)
      .join("") || "JD"
  ); // Default to "JohnDoe" if the result is an empty string
};

export const formatNumber = (
  data: number | null | undefined
): string | number => {
  if (data === null || data === undefined || Number.isNaN(data) || data === 0) {
    return "-";
  }

  return data;
};

export const formatLinkedInUrl = (url: string): string => {
  if (!url) return "";

  const match = url.match(
    /https:\/\/([a-z]{2,3}\.)?linkedin\.com\/in\/([^/?#]+)/i
  );

  if (match) {
    const username = encodeURIComponent(match[2]);
    return `https://www.linkedin.com/in/${username}`;
  }

  return url;
};
