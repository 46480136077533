import React from "react";
import { useSelector } from "react-redux";

import { SlidePanel } from "@arbolus-technologies/features/common";
import { PanelId, PanelSelector } from "@arbolus-technologies/stores/panels";
import { Loader } from "@arbolus-technologies/ui/components";
import { SIDE_PANEL_SIZE } from "@arbolus-technologies/utils";

import { Content } from "./Sections/Content/Content";
import { Header } from "./Sections/Header/Header";
import { DiscoverMatchingFiltersPanelData } from "./interfaces";

import styles from "./DiscoverMatchingFiltersPanel.module.scss";

export const DiscoverMatchingFiltersPanel: React.FC = () => {
  const panelData: DiscoverMatchingFiltersPanelData = useSelector(
    PanelSelector.panelData(PanelId.DiscoverMatchingFilters)
  );

  return (
    <SlidePanel
      panelId={PanelId.DiscoverMatchingFilters}
      width={SIDE_PANEL_SIZE._720}
      hideHeader
      noPadding
    >
      {panelData ? (
        <div className={styles.container}>
          <Header expertProfile={panelData} />
          <Content
            expertId={panelData.expertId}
            searchMatchColumns={panelData.searchMatchColumns}
            customers={panelData.customers ?? []}
            industriesFilter={panelData.industriesFilter}
            workHistories={panelData.workHistories}
          />
        </div>
      ) : (
        <div className={styles.loaderContainer}>
          <Loader isCentered />
        </div>
      )}
    </SlidePanel>
  );
};
