import { Button, Flex, Typography, Upload } from "antd";
import { RcFile } from "antd/es/upload/interface";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  DefaultToasterService,
  ToasterService
} from "@arbolus-technologies/api";

import { AntDAvatar } from "../Avatar/AntDAvatar";

export const MAXIMUM_IMAGE_UPLOAD_SIZE = 5 * 1024;

const { Text } = Typography;

interface UploadProfileImageProps {
  handleUploadSelectedImage: (file: RcFile) => void;
  defaultImageUrl?: string;
  expert: {
    firstName: string;
    lastName: string;
    profileImageUrl?: string;
  };
  notificationService?: ToasterService;
}

export const UploadProfileImage: React.FC<UploadProfileImageProps> = ({
  handleUploadSelectedImage,
  expert,
  defaultImageUrl,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("uploadProfileImage");

  const beforeUpload = (file: RcFile) => {
    const imageSize = Math.round(file.size / 1024);
    if (imageSize >= MAXIMUM_IMAGE_UPLOAD_SIZE) {
      notificationService.showError(t("maximum5MB"));
      return;
    }
    handleUploadSelectedImage(file);
  };

  return (
    <Flex gap={16} style={{ marginBottom: "16px" }}>
      <AntDAvatar
        firstName={expert.firstName}
        lastName={expert.lastName}
        profileImageUrl={defaultImageUrl ?? expert.profileImageUrl}
        onlyAvatar
        size={64}
      />
      <Flex vertical gap={8}>
        <Upload
          accept=".jpg,.jpeg,.png,.gif"
          beforeUpload={beforeUpload}
          showUploadList={false}
        >
          <Button>{t("changeProfileImage")}</Button>
        </Upload>
        <Text type="secondary">{t("recommendedImageSize")}</Text>
      </Flex>
    </Flex>
  );
};
